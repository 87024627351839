import { combineReducers } from "redux";
import authReducer from "./auth"
import generalReducer from "./general"
import emailBoxReducer from "./email-box";
import channelReducer from "./channels";

export const rootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    emailBox: emailBoxReducer,
    channels: channelReducer
});

export type RootState = ReturnType<typeof rootReducer>