import React, { HTMLAttributes } from 'react'

interface IProps extends HTMLAttributes<HTMLInputElement> {
    id: string
    isActiveEditable: boolean
    onChangeInput: (inputValue: string) => void
}

const BodyItem = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {

    const { children, isActiveEditable, id, onChangeInput } = props

    const renderItemValue = () => {
        let result = children && String(children) ? String(children) : ''
        if (typeof children !== 'object') {
            const dateFormat = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/
            if (typeof children === 'string' && dateFormat.test(children)) {
                result = new Date(children).toLocaleString()
            } else if (typeof children === 'number') {
                result = String(children)
            }
        }

        return result !== 'null' ? result : ''
    }

    return (
        isActiveEditable ?
            (
                <input
                    onChange={(e) => onChangeInput(e.target.value)}
                    data-id={id}
                    ref={ref}
                    defaultValue={renderItemValue()}
                />
            ) :
            (
                <span>
                    {renderItemValue()}
                </span>
            )

    )
})

export default BodyItem