import React from 'react'
import { Edit, Delete, Save, Cancel } from '../../Icons'
import styles from '../DynamicTable.module.css'

type Props = {
    isNewRecord: boolean
    showSaveAndCancel: boolean
    handleSave: () => void
    handleCancel: () => void
    handleEdit: () => void
    handleDelete: () => void
}

const BodyActions: React.FC<Props> = (props) => {
    const {
        showSaveAndCancel, handleEdit, handleDelete,
        handleCancel, handleSave, isNewRecord
    } = props

    return (
        <div className={styles.actions}>
            {
                !isNewRecord && <button
                    onClick={handleEdit}
                    className={styles.edit}
                >
                    <Edit />
                </button>
            }
            {
                !isNewRecord && <button
                    onClick={handleDelete}
                    className={styles.delete}
                >
                    <Delete />
                </button>
            }

            {
                showSaveAndCancel && <button
                    onClick={handleSave}
                    className={styles.save}
                >
                    <Save />
                </button>
            }
            {
                showSaveAndCancel && <button
                    onClick={handleCancel}
                    className={styles.cancel}
                >
                    <Cancel />
                </button>
            }
        </div>
    )
}

export default BodyActions