import axiosInstance from "./interceptor";
import { IPagination, IParams } from "../utils/model_types";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/simulated-orders`;

export const createSimulatedOrder = (data: Object) => {
    return axiosInstance.post(resource, data, {
        headers: {'Content-Type': 'application/json'}
    })
};

export const getSimulatedOrdersList = (params: IParams[], pagination: IPagination, sortFilter: any|undefined) => {
    const deletedParameter = `deleted.equals=false`;
    const parameters = params?.map(param => `${param.key}.${param.operator}=${param.value}`).join('&');
    const sortParameter = `sort=${sortFilter.name || 'orderDate'},${sortFilter.value || 'desc'}`;
    const paginationParameter = `page=${pagination.page}&size=${pagination.pageSize}`;
    return axiosInstance.get(`${resource}?${deletedParameter}&${paginationParameter}&${sortParameter}&${parameters}`);
};

export const getSimulatedOrderById = (id: number) => {
    const deletedParameter = `deleted.equals=false`;
    return axiosInstance.get(`${resource}/${id}?${deletedParameter}`);
};

export const modifySimulatedOrder = (data: Object, step: Number) => {
    return axiosInstance.put(`${resource}/step/${step}`, data);
};

export const getSimulatedOrderBySimulatedOrderName = (params: IParams[]) => {
    const deletedParameter = `deleted.equals=false`;
    const parameters = params?.map(param => `${param.key}.${param.operator}=${param.value}`);
    return axiosInstance.get(`${resource}?${deletedParameter}&${parameters}`);
};

export const downloadExcelOrdineSimulato = (id: number | undefined) => {
    return axiosInstance.get(`${resource}/download-excel/${id}`, {
        responseType: 'blob'
    })
};

export const deleteSimulatedOrder = (id: number) => {
    return axiosInstance.put(`${resource}/elimina-ordine-simulato/${id}`);
};

export const uploadSimulatedOrders = (data: object) => {
    return axiosInstance.post(`${REACT_APP_BASENAME}/quote/load`, data, {
        headers: {
            'content-type': 'multipart/form-data',
        }
    })
}