import React from "react"
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";


export default function TableDettaglioOrdine({children, tableHeaders, checkAllProducts, checkedAll}: any) {
    return (
        <div style={{overflowX: 'scroll'}}>
            <Table style={{minWidth: '1600px'}}>
                <TableBody>
                    <TableRow>
                        {
                            React.Children.toArray(tableHeaders.map((header: any) => {
                                return (
                                    <TableCell style={{fontWeight: 'bold'}}>{header === 'Checkbox' ?
                                        <input checked={checkedAll} onChange={() => checkAllProducts()}
                                               type='checkbox'/> : header}</TableCell>
                                )
                            }))
                        }
                    </TableRow>
                    {children}
                </TableBody>
            </Table>
        </div>
    )

}
