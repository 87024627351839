import axiosInstance from "./interceptor";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/commitments`;

export const getCommitments = async () => {
  return await axiosInstance.get(`${resource}`);
};

export const getCommitmentById = async (id:number) => {
  return await axiosInstance.get(`${resource}/${id}`);
};
