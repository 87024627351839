import {ResponsiveBar} from "@nivo/bar";
import React from "react"
import {IExpense} from "../../utils/model_types";
// import Tooltip from "@material-ui/core/Tooltip";

const SpesaPerFornitoreChart = (props: { spese: IExpense[] }) => {

    const formatValue = (value: number) => {
        return value.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2
        });
    };

    const spesePerFornitore = props.spese.map(spesa => {
        return {
            ...spesa,
            vendorName: spesa.vendorName,
            /*"Budget speso": spesa.accumulated,*/
            "Budget": spesa.budget,
            /*"Budget pending": spesa.totalPending*/
        }
    });

    return (
        <div style={{width: "100%", height: 750}}>
            <ResponsiveBar
                data={spesePerFornitore}
                keys={[/*"Budget speso", "Budget pending", */"Budget"]}
                indexBy="vendorName"
                margin={{top: 50, right: 50, bottom: 50, left: 90}}
                padding={0.3}
                groupMode="grouped"
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -20,
                        itemsSpacing: 20,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                layout="vertical"
                colors={[/*'#ff6961', '#f8c471', */'#82e0aa']}
                borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
/*                    // @ts-ignore
                    format: (val) => val && val.toString().length > 14 ? (
                            <Tooltip title={val.toString()}>
                                <span>
                                {val.toString().substring(0, 14)}...
                                </span>
                            </Tooltip>
                        ) :
                        val.toString()
                        */
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: (val) => formatValue(Number(val))
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                // @ts-ignore
                label={({value}) => <tspan y={ -10 }>{formatValue(value)}</tspan>}
                tooltip={({id, /*color,*/ value, indexValue}) => {
                    const _value = formatValue(value);
                    return (<>
                        {/* Fixme, non viene mostrata l'icona */}
                        {/*<i className="fas fa-square"*/}
                        {/*    style={{*/}
                        {/*    backgroundColor: `${color}`,*/}
                        {/*    width: '10px',*/}
                        {/*    height: '10px'*/}
                        {/*}}/>*/}
                        {id} - {indexValue}: {_value}
                    </>)
                }}
            />
        </div>
    )
};

export default SpesaPerFornitoreChart;
