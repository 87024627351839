import { useState, useCallback, useEffect } from 'react';
import { IPaginatonProps, PageItems } from '..';

const ROWS_PER_PAGE = [5, 10, 15]

const usePagination = () => {
    const [dtPagination, setDtPagination] = useState<IPaginatonProps>({} as IPaginatonProps)
    const [dtRowsPerPage, setDtRowsPerPage] = useState(ROWS_PER_PAGE[0])
    const [dtPageNumber, setDtPageNumber] = useState(0)

    const handleChangeRowsPerPage = useCallback((rowsNumber: number) => {
        setDtRowsPerPage(rowsNumber)
    }, [])

    const handleClickNextPage = useCallback(() => {
        setDtPageNumber(oldState => oldState + 1)
    }, [])

    const handleClickPrevPage = useCallback(() => {
        setDtPageNumber(oldState => oldState - 1)
    }, [])

    const dtUpdatePagination = useCallback((pageItems: PageItems) => {
        setDtPagination(oldState => ({
            ...oldState,
            ...pageItems
        }))
    }, [])

    // set page to 0 when updated the table and is not contains a element
    // TODO-newton: find a better solution, because calls the service twice
    useEffect(() => {
        if (dtPagination.number > 0 && dtPagination.numberOfElements === 0) {
            handleClickPrevPage()
        }
    }, [handleClickPrevPage, dtPagination.number, dtPagination.numberOfElements])

    useEffect(() => {
        setDtPagination(oldState => ({
            ...oldState,
            rowsPerPageOptions: ROWS_PER_PAGE,
            handleChangeRowsPerPage,
            handleClickNextPage,
            handleClickPrevPage
        }))
    }, [handleChangeRowsPerPage, handleClickNextPage, handleClickPrevPage])

    return {
        dtPagination, dtUpdatePagination, dtRowsPerPage, dtPageNumber
    }
}

export default usePagination