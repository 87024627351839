import {AuthAction, ChannelAction} from "../actions/types";
import {SET_CHANNEL} from "../actions/channels";
import {IChannel} from "../../utils/model_types";
import axiosInstance from "../../api/interceptor";
import { Dispatch } from "react";

export const setChannels = (channels : IChannel[]): ChannelAction => {
    return {
        type: SET_CHANNEL,
        payload: channels
    };
};

const {REACT_APP_BASENAME} = process.env;
const resource = `${REACT_APP_BASENAME}/channels`;

export const getChannelList = () => {
    return (dispatch: Dispatch<ChannelAction>) => {
        axiosInstance.get(resource).then(
            (response) => {
                dispatch(setChannels(response.data))
            }
        ).catch(err => {
            console.log(err)
        })
    }    
}
