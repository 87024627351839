import DynamicTable from './DynamicTable'

export { default as DynamicTableBody } from './components/DynamicTableBody'
export { default as DynamicTableHeader } from './components/DynamicTableHeader'
export { default as DynamicTableFooter } from './components/DynamicTableFooter'
export { default as BodyActions } from './components/BodyActions'
export { default as BodyItem } from './components/BodyItem'
export { default as CommandBar } from './components/CommandBar'
export { default as useDynamicTable } from './hooks/useDynamicTable'
export { default as usePagination } from './hooks/usePagination'
export { default as useCommandBar } from './hooks/useCommandBar'

export * from './types'

export const dtNanoid = (size = 21) => {
    let id = ''
    let bytes = crypto.getRandomValues(new Uint8Array(size))
    while (size--) {
        let byte = bytes[size] & 63;
        if (byte < 36) {
            // `0-9a-z`
            id += byte.toString(36);
        } else if (byte < 62) {
            // `A-Z`
            id += (byte - 26).toString(36).toUpperCase();
        } else if (byte < 63) {
            id += '_'
        } else {
            id += '-'
        }
    }
    return id;
}

export default DynamicTable