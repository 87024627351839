import React, {Fragment, useState} from "react"
import {
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles
} from "@material-ui/core"
import {IExpense} from "../../utils/model_types"
import {convertEuro} from "../../routing/utils";
import {findCategoriesPerVendor, findChannelsPerVendor} from "../../api/orders";
import {AxiosResponse} from "axios";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {useDispatch} from "react-redux";


const useStyle = makeStyles({
    subTitle: {
        marginBottom: 20
    },
    table: {
        minWidth: 650,
    },
    row: {
        "&:hover": {
            background: "lightblue",
            cursor: "pointer"
        }
    },
    rowClicked: {
        background: "lightblue",
    },
});

const SpesaPerFornitore = (props: { spese: IExpense[], typeExpense: string }) => {

    const classes = useStyle();
    const dispatch = useDispatch();
    const [channels, setChannels] = useState([]);
    const [categories, setCategories] = useState([]);
    const [vendorClicked, setVendorClicked] = useState("");

    const getChannelsByVendor = (vendorName: string) => {
        dispatch(toggleSpinner());
        findChannelsPerVendor(vendorName).then((response: AxiosResponse) => {
            setChannels(response.data);
            dispatch(toggleSpinner());
        }).catch((e: any) => {
            dispatch(toggleSpinner());
            toggleToast('error', e.messaggio)
        })
    }

    const getCategoriesByVendor = (vendorName: string) => {
        dispatch(toggleSpinner());
        findCategoriesPerVendor(vendorName).then((response: AxiosResponse) => {
            setCategories(response.data);
            dispatch(toggleSpinner());
        }).catch((e: any) => {
            dispatch(toggleSpinner());
            toggleToast('error', e.messaggio)
        })
    }

    return (
        <Fragment>
            <Typography variant="h4" className={classes.subTitle}>Spesa per fornitore ({props.typeExpense})</Typography>
            <TableContainer component={Paper} style={{width: '100%', marginBottom: "30px"}}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome Fornitore</TableCell>
                            <TableCell>{props.typeExpense}</TableCell>
                            <TableCell align="right"> Totale Budget a disposizione </TableCell>
                            <TableCell align="right">Totale Budget Speso </TableCell>
                            <TableCell align="right">Totale Budget Residuo</TableCell>
                            <TableCell align="right">Totale Budget Fatturato</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.spese.map((s, index) => {
                            return (
                                <Fragment key={index}>
                                    <TableRow
                                        className={`${classes.row} ${vendorClicked === s.vendorName ? classes.rowClicked : ""}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (vendorClicked !== s.vendorName) {
                                                props.typeExpense === "Canale" ?
                                                    getChannelsByVendor(s.vendorName)
                                                    :
                                                    getCategoriesByVendor(s.vendorName)

                                            }
                                            setVendorClicked(vendorClicked === s.vendorName ? "" : s.vendorName)
                                        }}
                                    >
                                        <TableCell component="th" scope="row">{s.vendorName}</TableCell>
                                        <TableCell component="th" scope="row"/>
                                        <TableCell align="right"> {convertEuro(s.budget)}</TableCell>
                                        <TableCell align="right">{convertEuro(s.accumulated)}</TableCell>
                                        <TableCell align="right"> {convertEuro(s.budget - s.accumulated)}</TableCell>
                                        <TableCell align="right"> {convertEuro(s.billed)}</TableCell>
                                    </TableRow>
                                    {vendorClicked === s.vendorName && (
                                        props.typeExpense === "Canale" ?
                                            channels.length > 0 ? channels.map((channel: any) => {
                                                    return <TableRow key={channel.channel}>
                                                        <TableCell align="right"/>
                                                        <TableCell component="th" scope="row">{channel.channel}</TableCell>
                                                        <TableCell align="right"/>
                                                        <TableCell align="right"> {convertEuro(channel.budget)}</TableCell>
                                                        <TableCell align="right"/>
                                                        <TableCell align="right"> {convertEuro(channel.total)}</TableCell>
                                                    </TableRow>
                                                })
                                                : <TableRow>
                                                    <TableCell align="right"/>
                                                    <TableCell component="th" scope="row">
                                                        Nessun canale
                                                    </TableCell>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                </TableRow>
                                            : categories.length > 0 ? categories.map((category: any) => {
                                                    return <TableRow key={category.types}>
                                                        <TableCell align="right"/>
                                                        <TableCell component="th" scope="row">{category.types}</TableCell>
                                                        <TableCell align="right"/>
                                                        <TableCell align="right"> {convertEuro(category.budget)}</TableCell>
                                                        <TableCell align="right"/>
                                                        <TableCell align="right"> {convertEuro(category.total)}</TableCell>
                                                    </TableRow>
                                                })
                                                : <TableRow>
                                                    <TableCell align="right"/>
                                                    <TableCell component="th" scope="row">
                                                        Nessuna categoria
                                                    </TableCell>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                    <TableCell align="right"/>
                                                </TableRow>
                                    )}
                                </Fragment>
                            )
                        })}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                TOTALI
                            </TableCell>
                            <TableCell/>
                            <TableCell component="th" scope="row" align={"right"}>
                                {convertEuro(props.spese.reduce((acc: number, el: IExpense) => acc + el.budget, 0.0))}
                            </TableCell>
                            <TableCell component="th" scope="row" align={"right"}>
                                {convertEuro(props.spese.reduce((acc: number, el: IExpense) => acc + el.accumulated, 0.0))}
                            </TableCell>
                            <TableCell component="th" scope="row" align={"right"}>
                                {convertEuro(props.spese.reduce((acc: number, el: IExpense) => acc + (el.budget - el.accumulated), 0.0))}
                            </TableCell>
                            <TableCell component="th" scope="row" align={"right"}>
                                {convertEuro(props.spese.reduce((acc: number, el: IExpense) => acc + el.billed, 0.0))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
};

export default SpesaPerFornitore;
