import React from 'react';
import {Button, makeStyles, Modal} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

type Props = {
    show: boolean
    onConfirm: () => void
    onCancel: () => void
}

const ModalAssetsTemplateInfo: React.FC<Props> = (props) => {
    const {show, onCancel, onConfirm} = props
    const classes = useStyle();

    return (
        <Modal
            open={show}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                Si sta per scaricare il <b>Template</b> relativo agli Asset in formato .xlsx .
                <br/>
                <br/>
                Cliccare su OK per continuare.
                <div className="mt-2">
                    <Button
                        color="secondary"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Annulla
                    </Button>
                    <Button
                        style={{float: 'right'}} color="primary"
                        onClick={() => {
                            onConfirm()
                        }}
                    >
                        OK
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalAssetsTemplateInfo;