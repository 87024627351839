import {setSelectedPage, toggleSpinner, toggleToast} from "./general";

import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT
} from '../actions/auth';
import { IUser } from "../../routing/types";
import {Action, AuthAction, GeneralAction} from "../actions/types";
import { Dispatch } from "react";
import axiosInstance from "../../api/interceptor";
import {stringify} from "querystring";
import {USP, UTBO, UTBOP} from "../actions/general";
import {getRoles} from "../../routing/utils";

export const authStart = (): AuthAction => {
    return {
        type: AUTH_START,
        payload: {
            isLoading: true
        }
    };
};

/**
 *
 * @param user
 * @returns {{type: string, error: null, user: *}}
 */
export const authSuccess = (user: IUser): AuthAction => {
    return {
        type: AUTH_SUCCESS,
        payload: {
            user: user
        }
    };
};

export const authFail = (error: boolean): AuthAction => {
    return {
        type: AUTH_FAIL,
        payload: {
            error: error
        }
    };
};

export const logout = (): AuthAction => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    localStorage.removeItem('path');
    return {
        type: AUTH_LOGOUT,
        payload: {
            authorities: [],
            authCheckEnd: false,
            error: false,
            isLoading: false,
            token: undefined,
            user: undefined
        }
    };
};

export const checkAuthTimeout = (dispatch: Dispatch<AuthAction>, expirationTime: number) => {
    setTimeout(() => {
        dispatch(logout());
    }, expirationTime * 1000);
};

// TODO: da cancellare quando ci saranno le chiamate BE
// export const auth = (dispatch: Dispatch<Action>, user: IUser) => {
//     dispatch(authStart());
//     dispatch(toggleSpinner());
//
//     const oreExpiration = 12;
//     const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
//     localStorage.setItem('user', JSON.stringify(user));
//     localStorage.setItem('expiration', expirationDate.toString());
//     localStorage.setItem('token', 'token');
//     dispatch(toggleSpinner());
//     dispatch(authSuccess(user));
// };

// TODO: da tenere commentata finché non ci saranno le chiamate BE
export const auth = (dispatch: Dispatch<GeneralAction | AuthAction>, { username, password, role }: IUser) => {
    const { REACT_APP_KEYCLOAK_URL, REACT_APP_KEYCLOAK_REALM } = process.env;
    return (dispatch: Dispatch<GeneralAction | AuthAction>) => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const bodyFormData = stringify({
               client_id: "backend",
               grant_type: "password",
               username: username,
               password: password,
           });

        if (REACT_APP_KEYCLOAK_URL != null) {
            /*axiosInstance.defaults.headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            };*/
            axiosInstance.post(REACT_APP_KEYCLOAK_URL+ '/realms/' + REACT_APP_KEYCLOAK_REALM + '/protocol/openid-connect/token', bodyFormData)
                .then(({data}) => {
                    const expirationDate = new Date();
                    expirationDate.setHours(expirationDate.getHours() + (data.expires_in / (60 * 60)));

                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('username', username);
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('expiration', expirationDate.toISOString());

                    const roles = getRoles(data);
                    const to = [USP, UTBO, UTBOP].some((role: string) => roles?.includes(role)) ? 'Monitoraggio' : 'Lista ordini';
                    localStorage.setItem('selected-page', to);
                    dispatch(setSelectedPage(to));
                    dispatch(toggleSpinner());
                    dispatch(authSuccess(data));
                })
                .catch(e => {
                    const {messaggio} = e;
                    dispatch(authFail( messaggio || 'Se hai già attivato il tuo account controlla le credenziali'));
                    dispatch(toggleSpinner());
                    /**
                    * toggleToast: mostra un toast con un determinato messaggio.
                    * Per veder come funziona guardate la funzione toggleToast prensente in questo file src/store/actionCreators/general.js
                    * @param tipo: @string definisce il tipo di toast da lanciare: 'e' = errore => toast rosso, 's' = successo => toast verde
                    * @param messaggio: @string definisce il messaggio mostrato nel toast
                    */
                    dispatch(toggleToast('error', messaggio || 'Se hai già attivato il tuo account controlla le credenziali'));
                });
        }
    };
};

export const authCheckState = (dispatch: Dispatch<Action>) => {
    authStart();

    const token = localStorage.getItem('token');
    if (!token) {
        dispatch(logout());
    } else {
        const expirationString = localStorage.getItem("expiration");
        if (!expirationString) return;

        const expirationDate = new Date(expirationString);
        if (expirationDate.getTime() <= new Date().getTime()) {
            dispatch(logout());
        } else {
            const user = localStorage.getItem('user');
            const selectedPage = localStorage.getItem('selected-page');
            if(!user) return;
            dispatch(setSelectedPage(selectedPage || ''));
            dispatch(authSuccess(JSON.parse(user)));
            checkAuthTimeout(dispatch, (expirationDate.getTime() - new Date().getTime()) / 1000);
        }
    }

};
