import React, { memo } from 'react'
import styles from '../DynamicTable.module.css'

type Props = {
    handleCreate: () => void
    labelCreate?: string
}

const CommandBar: React.FC<Props> = memo((props) => {
    const { handleCreate, labelCreate } = props

    return (
        <div className={styles.commandBar}>
            <button
                onClick={() => handleCreate()}
            >
                {labelCreate || 'Create'}
            </button>
        </div>
    )
})

export default CommandBar