import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { Button, Card, Container, FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';

import { auth } from "../../store/actionCreators/auth";
import { useStyles } from './style';
import loginImage from '../../assets/img/logo-sogei.jpeg';

const Login = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    /**
     * Da commentare quando si committa il codice
     */
    // const users = ["user_sogei", "2nd_user_sogei", "user_sogei_privilegi", "user_tim_back_office", "user_tim_back_office_privilegi", "progettista"];
    // const [username, setUsername] = useState(users[5]);
    // const [password, setPassword] = useState(users[5]);

    /**
     * Da ripristinare quando si committa il codice
     */
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const authHandler = () => {
       const f = auth(dispatch, { username, password, role: ["SUPERADMIN"], access_token: '' });
       if ( f ) {
           f(dispatch);
       }
    };

    return (
        <Container className={classes.loginContainer}>
            <Card elevation={3} className={classes.loginForm}>
                <img alt="loginLogo" className={classes.formLogo} src={loginImage} />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    authHandler();}}
                      className={classes.loginForm}>
                    <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="username">Username</InputLabel>
                    <Input id="username" aria-describedby="username-text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <FormHelperText id="username-text">Enter your username</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input id="password" aria-describedby="password-text" type="password"  value={password} onChange={(e) => setPassword(e.target.value)} />
                    <FormHelperText id="password-text">Enter your password</FormHelperText>
                </FormControl>
                <Button type={"submit"} title="Login" variant="contained">Login</Button>
                </form>
            </Card>
        </Container>
    );
};


export default Login;
