import React from 'react'
import DynamicTable, { ToastMessages } from '../../components/DynamicTable'

const errorMessages: ToastMessages = { 
    delete: 'Ops.. non è possibile eliminare questo destinatario',
    create: 'Ops.. non è possibile creare questo destinatario',
    update: 'Ops.. non è possibile aggiornare questo destinatario'
}

const successMessages: ToastMessages = {
    delete: 'Destinatario eliminato con successo',
    create: 'Destinatario creato con successo',
    update: 'Destinatario aggiornato con successo'
}

const GestioneDestinazioni: React.FC = () => {
    return (
        <>
            <DynamicTable
                labelCreate={'Nuova destinazione'}
                path='destinations'
                primaryKey='id'
                errorMessages={errorMessages}
                successMessages={successMessages}
            />
        </>
    )
}

export default GestioneDestinazioni