import React from 'react'
import { dtNanoid } from '..'
import styles from '../DynamicTable.module.css'

type Props = {
    columns: string[]
}

// TODO-newton: split subheader obj

const DynamicTableHeader: React.FC<Props> = (props) => {
    const { columns } = props

    return (
        <div className={styles.header}>
            {
                columns.map(column => (
                    <div
                        key={dtNanoid()}
                        className={`${styles.column}${column === 'actions' ? ' ' + styles.actionColumn : ''}`}
                    >
                        <span>
                            {column}
                        </span>
                    </div>
                ))
            }
        </div>
    )
}

export default DynamicTableHeader