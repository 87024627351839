import axiosInstance from "./interceptor";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/destinations`;

export const getDestinations = async () => {
  return await axiosInstance.get(`${resource}`);
};
export const getDestinationById = async (id: string | number) => {
  return await axiosInstance.get(`${resource}/${id}`);
};
