import React, { useCallback, useEffect, useState } from 'react'
import styles from '../DynamicTable.module.css'
import { IObjectKeyValue, dtNanoid, BodyActions, BodyItem, DtColumns, DtPrimaryKey } from '../'

type Props = {
    columns: DtColumns
    editableColumns: DtColumns
    elRefs: IObjectKeyValue[]
    dtPrimaryKey: DtPrimaryKey
    rows: IObjectKeyValue[]
    handleEdit: (row: IObjectKeyValue) => void
    handleSaveEdit: (rowIndex: number, row: IObjectKeyValue) => void
    handleCancel: (rowIndex: number, row: IObjectKeyValue) => void
    handleDelete: (row: IObjectKeyValue) => void
}

const DynamicTableBody: React.FC<Props> = (props) => {
    const {
        columns, rows, elRefs, dtPrimaryKey,
        handleDelete, handleEdit, handleSaveEdit,
        handleCancel
    } = props

    const [currentEditable, setCurrentEditable] = useState<number[]>([])

    // automatic update if is a new record
    useEffect(() => {
        setCurrentEditable([])
        rows.forEach((row, i) => {
            if (row.id === 0) {
                setCurrentEditable(oldState => [...oldState, i])
            }
        })
    }, [rows])

    const removeItemFromCurrentEditableList = useCallback((rowIndex: number) => {
        setCurrentEditable(currentEditable.filter(i => i !== rowIndex))
    }, [currentEditable])

    const midHandleEdit = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        if (currentEditable.includes(rowIndex))
            removeItemFromCurrentEditableList(rowIndex)
        else
            setCurrentEditable([...currentEditable, rowIndex])

        handleEdit(row)
    }, [removeItemFromCurrentEditableList, handleEdit, currentEditable])

    const isActiveEditable = useCallback((rowIndex: number) => (
        currentEditable.includes(rowIndex)
    ), [currentEditable])

    const midHandleCancel = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        removeItemFromCurrentEditableList(rowIndex)
        handleCancel(rowIndex, row)
    }, [removeItemFromCurrentEditableList, handleCancel])

    const midHandleDelete = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        removeItemFromCurrentEditableList(rowIndex)
        const askDelete = window.confirm('Sei sicuro di voler cancellare?')
        if (askDelete) handleDelete(row)
    }, [handleDelete, removeItemFromCurrentEditableList])

    // TODO-newton: add save all
    const midHandleSaveEdit = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        handleSaveEdit(rowIndex, row)
    }, [handleSaveEdit])

    const midChangeInput = useCallback(
        (rowIndex: number, rowKey: string, inputValue: string) => {
            if (elRefs) elRefs[rowIndex][rowKey] = inputValue
        }, [elRefs])

    return (
        <div className={styles.body}>
            {
                // TODO-newton: use empty from service
                rows.length === 0 ? (
                    <div style={{ padding: 10 }}>
                        Nessun record trovato
                    </div>
                ) :
                    rows.map((row, rowIndex) => {
                        return (
                            <div
                                key={dtNanoid()}
                                className={styles.row}
                            >
                                {
                                    columns.map((rowKey, rowColumn) => {
                                        return (
                                            <div
                                                key={dtNanoid()}
                                                className={`${styles.column}${rowKey === 'actions' ? ' ' + styles.actionColumn : ''}`}
                                            >
                                                {
                                                    rowKey === 'actions' ? (
                                                        <BodyActions
                                                            // TODO-newton: primaryKey
                                                            isNewRecord={row.id === 0}
                                                            showSaveAndCancel={currentEditable.includes(rowIndex)}
                                                            handleCancel={() => midHandleCancel(rowIndex, row)}
                                                            handleSave={() => midHandleSaveEdit(rowIndex, row)}
                                                            handleEdit={() => midHandleEdit(rowIndex, row)}
                                                            handleDelete={() => midHandleDelete(rowIndex, row)}
                                                        />
                                                    ) : (
                                                        <BodyItem
                                                            id={rowKey}
                                                            onChangeInput={(inputValue) => midChangeInput(rowIndex, rowKey, inputValue)}
                                                            isActiveEditable={rowKey !== dtPrimaryKey && isActiveEditable(rowIndex)}
                                                            ref={elRefs[rowIndex][rowColumn]}
                                                        >
                                                            {row[rowKey]}
                                                        </BodyItem>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>)
                    })
            }
        </div>
    )
}

export default DynamicTableBody