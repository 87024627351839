import React from 'react';
import {Route, Switch, Redirect, useHistory} from "react-router-dom";
import {routes} from './routes';
import {IRoute, Roles, User} from './types';
import {USP, UTBO, UTBOP} from "../store/actions/general";
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

function CustomComponent(Component: any, pageTitle: string) {
    const history = useHistory();
    return function (props: any) {
        return (
            <>
                {pageTitle && <>
                    <span className="back-icon cursor-pointer" onClick={() => history.goBack()}><KeyboardBackspaceIcon/>Indietro</span>
                    <h1 className="title-centered">{pageTitle}</h1>
                </>}
                <Component {...props}/>
            </>
        );
    }
}

export const getRoles = (user: User) => {
    return parseJwt(user?.access_token || '')?.resource_access?.backend?.roles;
};

const getVisibleToAllRoutes = () => routes.filter(r => r.access.includes(0));
const getPublicRoutes = () => routes.filter(r => r.access.includes(1));
const getPrivateRoutes = () => routes.filter(r => r.access.includes(2));
const getPrivateRoutesWithRoles = (authorities: Roles) => routes.filter(r => r.access.includes(3) &&
    authorities &&
    r.roles.some(r => authorities.indexOf(r) >= 0));

const getRouteComponent = (routes: IRoute[]) => routes.map((r, i) => <Route path={r.path} exact key={i}>{CustomComponent(r.component, r.name)}</Route>);

export const getRouteItems = (user: User) =>
    user ?
        getVisibleToAllRoutes().concat(getPrivateRoutesWithRoles(getRoles(user)), getPrivateRoutes()) :
        getVisibleToAllRoutes().concat(getPublicRoutes());

export const getRoutes = (user: User) => {

    const roles = getRoles(user);
    const to = [USP, UTBO, UTBOP].some((role: string) => roles?.includes(role)) ? '/dashboard' : 'listino';

    return (
        // <Suspense fallback={< div > Loading...</div>}>
        <Switch>
            {getRouteComponent(getRouteItems(user))}
            <Redirect to={user ? localStorage.getItem('path') || to : '/login'}/>
        </Switch>
        // </Suspense>
    )
};

/*
    Scroll automatico al cambio pagina
 */
export const scrollTop = () => {
    setTimeout(() => {
        const root = document.getElementsByTagName('main')[0];
        root.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, 200);
};

export const convertEuro = (num: number) => {
    return num >= 0 ? new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(num) : 0
};
