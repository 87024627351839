import {makeStyles} from "@material-ui/core";
import {amber, red} from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({

    stepDate2: {
        textAlign: 'center'
    },
    vendorItem: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        position: 'relative',
        fontSize: '16px'
    },
    warningIcon: {
        color: amber[500],
        position: 'absolute',
        left: '-30px'
    },
    alertBudget: {
        color: red[400],
        fontWeight: 'bold',
        textAlign: 'center'
    },
    stepDate3: {
        textAlign: 'right'
    },
    exportOrdine: {
        float: 'right'
    },
    itemSpedizione: {
        fontSize: '16px',
        paddingLeft: '40px'
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: 1,
        marginRight: 1,
        width: 140,
    },
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    }
}));
