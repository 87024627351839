import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    FormControl,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {ColDef, ValueFormatterParams} from "@material-ui/data-grid";
import {PRDOUCT_LIST_ERROR, PROGETTISTA, ROWS_PER_PAGE, updatePagination} from "../../store/actions/general";
import {IPagination, IProduct, IVendors, sortI} from "../../utils/model_types";
import {useDispatch, useSelector} from "react-redux";
import {convertEuro, getRoles} from "../../routing/utils";
import {useHistory} from "react-router-dom";
import {RootState} from "../../store/reducers";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {findAllCategories, findAllProducts} from "../../api/products";
import {findAllVendors} from "../../api/vendors";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyle = makeStyles((theme) => (
    {
        filterContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: '15px 0',
            backgroundColor: 'transparent',
            '& > div > div': {
                marginRight: 10
            }
        },
        table: {
            minWidth: 700
        },
        addButton: {
            float: 'right'
        },
        tableContainer: {
            marginTop: 15
        },
        filterInput: {
            width: 420
        },
        root: {},
        formControl: {
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }
));

export default function Listino() {

    const classes = useStyle();
    const [rowData, setRowData] = useState<IProduct[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});
    const [vendors, setVendors] = useState<IVendors[]>([]);
    const [categories, setCategories] = useState<any[]>([]);

    // Filter values
    const [brandFilter, setBrandFilter] = useState<string | null>(null);
    const [categoryFilter, setCategoryFilter] = useState<string>('');
    const [skuFilter, setSkuFilter] = useState<string>("");
    const [descriptionFilter, setDescriptionFilter] = useState<string>("");

    const [sortField, setSortField] = useState<sortI>({name: '', value: null});

    const dispatch = useDispatch();

    const columns: ColDef[] = [
        {field: 'sku', headerName: 'SKU', width: 170, sortable: true},
        {field: 'family', headerName: 'Famiglia Prodotto', width: 200, sortable: true},
        {field: 'description', headerName: 'Descrizione', width: 405, sortable: true},
        {field: 'productType', headerName: 'Categoria', width: 200, sortable: true},
        {field: 'brand', headerName: 'Brand', width: 130},
        {
            field: 'price',
            headerName: 'Prezzo',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 123,
            sortable: true
        },
        {
            field: 'discountPrice',
            headerName: 'Prezzo Scontato',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 140
        }
    ];

    const history = useHistory();

    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = (event: any) => {
        const pageSize = parseInt(event.target.value, 10);
        setPagination({...pagination, page: 0, pageSize})
    };

    const buildProductFilter = () => {
        return {
            brandFilter: vendors.find(v => v.name === brandFilter),
            categoryFilter,
            skuFilter,
            descriptionFilter
        }
    };

    useEffect(() => {
        dispatch(toggleSpinner());
        findAllProducts(pagination, buildProductFilter(), sortField).then((response) => {
            const paginationHeaderLink = response.headers.link.split(',');
            const totalCount = response.headers["x-total-count"];
            setRowData(response.data.map((d: any) => ({...d, productType: d.productType.type})));
            setPagination(updatePagination(pagination, paginationHeaderLink, totalCount));
        }).catch(e => {
            toggleToast('error', PRDOUCT_LIST_ERROR + '. ' + (e.messaggio || ''))
        }).finally(() => dispatch(toggleSpinner()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize, brandFilter, categoryFilter, skuFilter, descriptionFilter, sortField]);

    useEffect(() => {
        dispatch(toggleSpinner());
        const categoriesPromise = findAllCategories();
        const vendorsPromise = findAllVendors();
        Promise.all([categoriesPromise, vendorsPromise]).then(response => {
            const categoriesResponse = response[0];
            const vendorsResponse = response[1];
            if (categoriesResponse.status === 200) setCategories(categoriesResponse.data);
            if (vendorsResponse.status === 200) setVendors(vendorsResponse.data);
            dispatch(toggleSpinner());
        }).catch(e => {
            dispatch(toggleSpinner());
            toggleToast('error', (e.messaggio || ''));
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVendorsList = () => vendors.map((vendor) => vendor.name);

    // const getCategoryList = () => {
    //     // @ts-ignore
    //     return [...new Set(categories)]
    // };

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const role = getRoles(storeUser);

    const sortTable = (param: string) => {
        setSortField(field => field.name !== param ? {name: param, value: 'desc'} : field.value === 'asc' ? {...field, value: null} : {...field, value: 'asc'})
    }

    return (
        <Fragment>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <div>
                        <FormControl>
                            <Autocomplete
                                id="brand-filter"
                                style={{width: 200}}
                                options={getVendorsList()}
                                renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
                                value={brandFilter}
                                onChange={(e: any, newValue: string | null) => {
                                    setBrandFilter(newValue);
                                    setPagination({...pagination, page: 0});
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="description-filter"
                                variant="outlined"
                                label="Descrizione"
                                style={{width: 250}}
                                value={descriptionFilter}
                                onChange={(e: any) => {
                                    setDescriptionFilter(e.target.value);
                                    setPagination({...pagination, page: 0});
                                }}/>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                label="Categoria"
                                id="demo-simple-select"
                                value={categoryFilter}
                                onChange={(e: any) => {
                                    setCategoryFilter(e.target.value);
                                    setPagination({...pagination, page: 0})
                                }}
                            >
                                <MenuItem value="">Nessuna</MenuItem>
                                {React.Children.toArray(categories.map(c => <MenuItem value={c.id}>{c.type}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="sku-filter"
                                variant="outlined"
                                label="SKU"
                                value={skuFilter}
                                onChange={(e: any) => {
                                    setSkuFilter(e.target.value);
                                    setPagination({...pagination, page: 0});
                                }}/>
                        </FormControl>
                    </div>
                    {(role.includes('US') || role.includes('USP') ) && <Button variant="contained" color="primary" style={{minWidth: '150px'}} onClick={() => history.push('/creazione-ordine')}>Nuovo ordine</Button>}
                    {role.includes(PROGETTISTA) && <Button variant="contained" color="primary"  style={{minWidth: '150px'}} onClick={() => history.push('/creazione-preventivo')}>Nuovo preventivo</Button>}
                </Paper>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {React.Children.toArray(columns.map((col: ColDef) => (
                                    col.sortable ?
                                        <TableCell style={{width: col.width, cursor: 'pointer'}}
                                                   onClick={() => sortTable(col?.field || '')}>
                                            {col.headerName}
                                            {
                                                sortField.value &&
                                                sortField.name === col.field &&
                                                <i className={`ml-1 text-secondary fas ${sortField.value === 'desc' ? 'fa-long-arrow-alt-down' : 'fa-long-arrow-alt-up'}`}/>
                                            }
                                        </TableCell> :
                                        <TableCell style={{width: col.width}}>
                                            {col.headerName}
                                        </TableCell>
                                )))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {React.Children.toArray(rowData.map((row: IProduct) => (
                                <TableRow className={classes.root} key={row.id}>
                                    {
                                        React.Children.toArray(columns.map((col) => (
                                            <TableCell component="th" scope="row">
                                                {col.valueFormatter ? col.valueFormatter(row[col.field]) : row[col.field]}
                                            </TableCell>
                                        )))
                                    }
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={pagination.rowCount || 20}
                        page={pagination.page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={pagination.pageSize}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                    />
                </TableContainer>
            </div>
        </Fragment>
    )
}
