import React, { useEffect, useState } from "react"
import { Button, Card, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import { createUser, findUserWithRoles, getAllRoles, updateUser } from "../../api/users";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router";
import { IUser } from "../../utils/model_types";
import { useDispatch } from "react-redux";

const useStyle = makeStyles({
    formControl: {
        width: "50%",
        marginBottom: 20,
        padding: 20
    },

    dataGrid: {
        height: 650,
        flex: "1 1"
    },

    formCard: {
        padding: 25
    },

    label: {
        left: 20
    },

    formContainer: {
        paddingRight: 25,
        paddingLeft: 25,
        width: '800px',
        margin: '0 auto'

    }
})

export type FullName = {
    firstName: string
    lastName: string
}

export default function NuovoUtente(props: any) {

    const [user, setUser] = useState<IUser>({id: ""});
    const [fullName, setFullName] = useState<FullName>({firstName: '', lastName: ''})
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [ruolo, setRuolo] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [onModify, setOnModify] = useState<boolean>(false);
    const [roles, setRoles] = useState<string[]>([
        "US", "USP", "UTBO", "UTBOP"
    ]);
    const dispatch = useDispatch();

    const history = useHistory();

    const classes = useStyle();

    useEffect(() => {
        dispatch(toggleSpinner());
        if (props.match.params.id) {
            setOnModify(true);
            findUserWithRoles(props.match.params.id).then(async (usersResponse: AxiosResponse) => {
                const rolesResponse = await getAllRoles();
                const roles = rolesResponse?.data.map((role: any) => role.name);
                const user = usersResponse.data;
                // Si presuppone che ogni user abbia al più un ruolo
                if (user.clientRoles) {
                    user.role = user.clientRoles[user.id][0];
                }
                setUser(user);
                setUsername(user.username);
                setEmail(user.email);
                setRuolo(user.role);
                setRoles(roles);
                setFullName({firstName: user.firstName, lastName: user.lastName})
            }).catch(e => toggleToast('error', e.messaggio)).finally(() => dispatch(toggleSpinner()))
        } else {
            getAllRoles().then(roles => {
                setRoles(roles.data.map((r: any) => r.name));
                dispatch(toggleSpinner());
            }).catch(e => {
                dispatch(toggleSpinner());
                toggleToast('error', 'Errore nel caricamento dei ruoli')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    const handleSubmit = () => {
        const utente = {...user};
        if (onModify) {
            if(email === '' || ruolo === ''){
                toggleToast('error', 'Verificare che tutti i campi siano inseriti');
                return;
            } else {
                dispatch(toggleSpinner());
                utente.email = email;
                utente.role = ruolo;
                utente.password = password;
                utente.firstName = fullName.firstName
                utente.lastName = fullName.lastName

                updateUser(props.match.params.id, utente).then(res => {
                    if (res.status === 200)
                        toggleToast('success', 'Utente aggiornato correttamente')
                }).catch(e => toggleToast('error', e.messaggio)).finally(() => {
                    history.push('/gestione-utenti')
                    dispatch(toggleSpinner())
                })
            }
        } else {
            if (email === '' || ruolo === '' || password === '' || username === '' || (fullName.firstName === '' && fullName.lastName === '')) {
                toggleToast('error', 'Verificare che tutti i campi siano inseriti');
                return;
            }
            dispatch(toggleSpinner());
            utente.username = username;
            utente.email = email;
            utente.role = ruolo;
            utente.password = password;
            utente.firstName = fullName.firstName
            utente.lastName = fullName.lastName
            createUser(utente).then(res => {
                if (res.status === 200)
                    toggleToast('success', 'Utente creato correttamente')
            }).catch(e => toggleToast('error', e.messaggio)).finally(() => {
                history.push('/gestione-utenti')
                dispatch(toggleSpinner())
            })
        }
    };

    const resetAll = () => {
        setUsername('');
        setEmail('');
        setRuolo('');
        setPassword('');
        setFullName({firstName: '', lastName: ''})
    };

    return (
        <div className={classes.formContainer}>
            <Card elevation={3} className={classes.formCard}>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="firstName">Nome</InputLabel>
                    <Input
                        id="firstName"
                        aria-describedby="firstName-text"
                        value={fullName.firstName}
                        onChange={(e: any) => {
                        setFullName({...fullName, firstName: e.target.value})
                    }}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="lastName">Cognome</InputLabel>
                    <Input
                        id="lastName"
                        aria-describedby="lastName-text"
                        value={fullName.lastName}
                        onChange={(e: any) => {
                            setFullName({...fullName, lastName: e.target.value})
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="username">Username</InputLabel>
                    <Input id="username" aria-describedby="username-text" value={username} disabled={onModify} onChange={(e: any) => {
                        setUsername(e.target.value)
                    }}/>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="email">E-mail</InputLabel>
                    <Input id="email" aria-describedby="email-text" value={email} onChange={(e: any) => {
                        setEmail(e.target.value)
                    }}/>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="password">Password</InputLabel>
                    <Input id="password" type="password" aria-describedby="password-text" value={password} onChange={(e: any) => {
                        setPassword(e.target.value)
                    }}/>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="ruolo">Ruolo</InputLabel>
                    <Select
                        labelId="ruolo"
                        id="ruolo"
                        value={ruolo}
                        onChange={(e: any) => {
                            setRuolo(e.target.value)
                        }}
                        label="Ruolo"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {roles?.map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
                    </Select>
                </FormControl>
                <div className="btn-container">
                    <Button startIcon={<Add/>} title="Crea Utente" variant="contained" color="primary" onClick={handleSubmit}>
                        {onModify ? 'Modifica' : 'Crea'}
                    </Button>
                    <Button startIcon={<Clear/>} title="Reset" variant="contained" onClick={resetAll}>Reset</Button>
                </div>
            </Card>
        </div>
    )
};
