import {Button, IconButton, makeStyles} from "@material-ui/core";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import {ColDef, DataGrid, ValueFormatterParams} from "@material-ui/data-grid";
import {Delete, Edit} from "@material-ui/icons";
import moment from "moment";
import BasicDialog from "../../components/Dialogs/BasicDialog";
import {getRoles} from "../../routing/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {ROWS_PER_PAGE, SELECTED_PAGE, USP, USS, UTBOP} from "../../store/actions/general";
import {useHistory} from "react-router";
import {IPagination, IUser} from "../../utils/model_types";
import {deleteUser, findUsersWithRoles} from "../../api/users";
import {AxiosResponse} from "axios";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";

const useStyle = makeStyles({
    formControl: {
        width: "100%",
        marginBottom: 20,
    },

    dataGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 1252,
        justifyContent: 'flex-end',
        margin: 'auto'
    },

    table: {},

    formCard: {
        padding: 25
    },

    formContainer: {
        paddingRight: 25,
        paddingLeft: 25,
        width: 500
    },
    addButton: {
        float: 'right',
        marginBottom: '20px'
    },

})

const ActionCell = (props: { params: ValueFormatterParams, getAllUsers: any }) => {
    const [, setOpenView] = React.useState(false);
    const [, setOpenModify] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);

    const openMapping: { [index: string]: Dispatch<SetStateAction<boolean>> } = {
        "v": setOpenView,
        "m": setOpenModify,
        "d": setOpenDelete
    }

    const handleOpenSearch = (modalType: string) => {
        openMapping[modalType](true)
    };
    const handleCloseSearch = (modalType: string) => {
        openMapping[modalType](false)
    };

    const modifyUser = () => {
        const id = props.params.data.id;
        dispatch({type: SELECTED_PAGE, payload: {message: 'Modifica utente'}});
        localStorage.setItem('selected-page', 'Modifica utente');
        history.push(`/gestione-utenti/modifica-utente/${id}`);
    };

    const confirmDeleteUser = () => {
        deleteUser(props.params.data.id).then(r => {
            toggleToast('success', 'Utente cancellato correttamente')
            props.getAllUsers();
        }).catch(e => toggleToast('error', e.messaggio));
        openMapping["d"](false)
        //TODO refresh users list
    };

    return (
        <div className="centerd-content">
            {(! auth.includes(USS) && (<IconButton size="small" onClick={modifyUser}><Edit/></IconButton>))}
            {(! auth.includes(USS) && (<IconButton size="small" onClick={() => handleOpenSearch("d")}><Delete/></IconButton>))}
            {(auth.includes(USS) && ('-'))}

            {/* <BasicDialog open={openView} title="Detail" type="v" handleClose={() => handleCloseSearch("v")}
                         selectedObject={props.params}/>*/}
            {/*<BasicDialog open={openModify} title="Modify" type="m" handleClose={() => handleCloseSearch("m")}
                         selectedObject={props.params}/>*/}
            <BasicDialog open={openDelete} title="Elimina Utente" type="d" handleClose={() => handleCloseSearch("d")}
                         selectedObject={props.params} textModal="Sei sicuro di voler cancellare l'utente?"
                         confirmOperation={confirmDeleteUser}/>
        </div>
    )

}

const GestioneUtenti = () => {
    const classes = useStyle();
    const [rowData, setRowData] = useState<IUser[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 1, pageSize: ROWS_PER_PAGE[0]});

    const history = useHistory();
    const dispatch = useDispatch();
    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);


    const columns: ColDef[] = [
        {
            field: 'firstName', headerName: 'Nominativo', width: 300,
            valueFormatter: (param: any) => {
                return `${param?.data?.firstName || ''} ${param?.data?.lastName || ''}`
            }
        },
        {field: 'username', headerName: 'Username', width: 300},
        {field: 'email', headerName: 'E-mail', width: 300},
        {field: 'role', headerName: 'Ruolo', width: 300},
        {
            field: 'createdTimestamp',
            headerName: 'Data creazione',
            width: 300,
            valueFormatter: (params: ValueFormatterParams) => moment(params.value as number).format("DD/MM/YYYY HH:mm")
        },
        {
            field: '',
            headerAlign: "center",
            align: "center",
            headerName: 'Azioni',
            width: 200,
            renderCell: (params: ValueFormatterParams) => <ActionCell params={params} getAllUsers={getAllUser}/>
        },
    ];

    useEffect(() => {
        getAllUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize]);

    const getAllUser = () => {
        dispatch(toggleSpinner());
        findUsersWithRoles(pagination).then((usersResponse: AxiosResponse) => {
            const users: IUser[] = usersResponse.data.map((user: IUser) => {
                // Si presuppone che ogni user abbia al più un ruolo
                if (user.clientRoles) {
                    user.role = user.clientRoles[user.id || ''][0];
                }

                return user
            });

            setRowData(users);
        }).catch(e => toggleToast('error', e.messaggio)).finally(() => dispatch(toggleSpinner()))
    };

    const goToNewUser = () => {
        dispatch({type: SELECTED_PAGE, payload: {message: 'Nuovo utente'}});
        localStorage.setItem('selected-page', 'Nuovo utente');
        history.push('/gestione-utenti/nuovo-utente')
    };

    const handlePaginationChange = (params: any) => {
        const paginationUpdated = {...pagination};
        paginationUpdated.page = params.page;
        paginationUpdated.pageSize = params.pageSize;
        setPagination(paginationUpdated);
    };

    return (
      <>
            {(auth.includes(UTBOP) || auth.includes(USP)) && (
                <Button className={classes.addButton} variant="contained" color="primary"
                        onClick={goToNewUser}> nuovo utente</Button>
            )}
            <DataGrid autoHeight
                      className={classes.table}
                      rows={rowData} columns={columns}
                      pagination
                      {...pagination}
                      onPageChange={handlePaginationChange}
                      onPageSizeChange={handlePaginationChange}
                      rowsPerPageOptions={ROWS_PER_PAGE}
                      showColumnRightBorder
            />
        </>
    );
}

export default GestioneUtenti;
