import axiosInstance from "./interceptor";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/products-ordereds`;

export const createProductsOrdereds = async (data: Object) => {
    return await axiosInstance.post(resource, data, {
        headers: {'Content-Type': 'application/json'}
    })
};

export const getProductsOrderedByOrderId = (orderId: number) => {
  return axiosInstance.get(`${resource}/order`, {
      params: { orderId }
  })
};

export const modifyStartEndDateProduct = (productId: number, serviceStart: any, serviceEnd: any) => {
  return axiosInstance.put(`${resource}/updateServiceDate/${productId}?serviceStart=${serviceStart}&serviceEnd=${serviceEnd}`)
}
