import {IconButton, makeStyles} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react"
import {ColDef, DataGrid} from "@material-ui/data-grid";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {useDispatch} from "react-redux";
import {DOCUMENT_UPLOAD_ERROR, ROWS_PER_PAGE} from "../../store/actions/general";
import {IPagination} from "../../utils/model_types";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {downloadListingById, getListings, IListing} from "../../api/listings";
import {HttpStatusCode} from "../../utils/protocols/http";
import {downloadFile} from "../../utils/utils";

const useStyle = makeStyles({
    formControl: {
        width: "100%",
        marginBottom: 20,
    },
    dataGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 1252,
        justifyContent: 'flex-end',
        margin: 'auto'
    },
    table: {},
    formCard: {
        padding: 25
    },
    formContainer: {
        paddingRight: 25,
        paddingLeft: 25,
        width: 500
    },
    addButton: {
        float: 'right',
        marginBottom: '20px'
    }
})

const Listings = () => {
    const classes = useStyle();
    const [rowData, setRowData] = useState<IListing[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 1, pageSize: ROWS_PER_PAGE[0]});

    const dispatch = useDispatch();

    const columns: ColDef[] = [
        {field: 'vendor', headerName: 'Venditore', width: 200},
        {field: 'name', headerName: 'Nome', width: 300},
        {field: 'hash', headerName: 'Hash', width: 400},
        {
            field: '',
            headerAlign: "center",
            align: "center",
            headerName: 'Azioni',
            width: 100,
            renderCell: p => {
                return (
                    <div className='centerd-content'>
                        <IconButton
                            size={'small'}
                            onClick={async _ => {
                                const obj = p.data as IListing
                                dispatch(toggleSpinner())
                                const resp = await downloadListingById(obj.id)
                                if (resp.status === HttpStatusCode.ok) {
                                    const data = resp.data
                                    downloadFile(data,obj.name)
                                } else {
                                    toggleToast('error', DOCUMENT_UPLOAD_ERROR.replace('caricamento', 'download'))
                                }
                                dispatch(toggleSpinner())
                            }}
                        >
                            <CloudDownloadIcon/>
                        </IconButton>
                    </div>
                )
            }
        }
    ]

    const getAllListings = useCallback(() => {
        dispatch(toggleSpinner())
        getListings()
            .then(res => {
                if (res.status === HttpStatusCode.ok) {
                    setRowData(res.data)
                }
            })
            .catch(e => toggleToast('error', e.messaggio))
            .finally(() => dispatch(toggleSpinner()))
    }, [dispatch])

    useEffect(() => {
        getAllListings()
    }, [getAllListings])

    const handlePaginationChange = (params: any) => {
        const paginationUpdated = {...pagination};
        paginationUpdated.page = params.page;
        paginationUpdated.pageSize = params.pageSize;
        setPagination(paginationUpdated);
    }

    /*const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files?.length) {
            Array.from(files).forEach(async f => {
                const formData = new FormData();
                formData.append('file', f)
                const resp = await uploadListing(formData)
                if (resp.status === HttpStatusCode.created) {
                    toggleToast('success', DOCUMENT_UPLOAD_SUCCESS)
                } else {
                    toggleToast('error', DOCUMENT_UPLOAD_ERROR)
                }
                getAllListings()
            })
        }
    }*/

    return (
        <>
            {/*{(auth.includes(UTBOP) || auth.includes(USP)) && (
                <Button
                    component="label"
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                >
                    Carica file
                    <input
                        type="file"
                        hidden
                        onChange={uploadFile}
                    />
                </Button>
            )}*/}
            <DataGrid
                autoHeight
                className={classes.table}
                rows={rowData}
                columns={columns}
                pagination
                {...pagination}
                onPageChange={handlePaginationChange}
                onPageSizeChange={handlePaginationChange}
                rowsPerPageOptions={ROWS_PER_PAGE}
                showColumnRightBorder
            />
        </>
    );
}

export default Listings