import axiosInstance from "./interceptor";
import {IPagination, IUser} from "../utils/model_types";

const {
    REACT_APP_BASENAME,
} = process.env;

const usersUrl = `${REACT_APP_BASENAME}/users`;

// export const findUsersWithRoles = (role?: string) => axiosInstance.get(`${usersUrl}${role ? "?role=" + role : ""}`);
export const findUsersWithRoles = (pagination: IPagination) => {
    const _pagination = `page=${pagination.page - 1}&size=${pagination.pageSize}`;
    return axiosInstance.get(`${usersUrl}?${_pagination}`)
};

export const findUserWithRoles = (id: string) => axiosInstance.get(`${usersUrl}/${id}`);

export const getUserByUsername = async (username: string) => axiosInstance.get(`${usersUrl}?username=${username}`)

/**
 * @param id: user ID
 * @param user {
        "username": "",
        "email": "",
        "lastName": "",
        "firstName": "",
        "password": "",
        "role": ""
    }
 */
export const updateUser = (id: string, user: IUser) => axiosInstance.put(`${usersUrl}/${id}`, user, {
    headers: {'Content-Type': 'application/json'}
});

/**
 * @param user {
        "username": "",
        "email": "",
        "password": "",
        "role": ""
    }
 */
export const createUser = (user: any) => axiosInstance.post(usersUrl, user);

export const deleteUser = (id: string | number) => axiosInstance.delete(`${usersUrl}/${id}`);

export const getAllRoles = () => axiosInstance.get(`${usersUrl}/roles`);

export const updateUserLogged = (data: object) => axiosInstance.put(`${usersUrl}/me`, data, {
    headers: {'Content-Type': 'application/json'}
});

