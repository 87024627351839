import { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    DynamicTableData,
    DynamicTableProps,
    IObjectKeyValue,
    DtColumns,
    usePagination,
} from '..'
import { toggleSpinner, toggleToast } from '../../../store/actionCreators/general'
import { mockData } from '../mock.data'
import {
    getDynamicTableData,
    updateDynamicTableRow,
    createDynamicTableRow,
    deleteDynamicTableRow,
} from '../services'

type Props = DynamicTableProps

const useDynamicTable = (props: Props) => {
    const {
        rows, path, columns, primaryKey,
        errorMessages, successMessages,
        handleDeleteRow, handleEditRow, handleSaveEditRow
    } = props

    const [dtColumns, setDtColumns] = useState<DtColumns>([])
    const [dtPrimaryKey, setDtPrimaryKey] = useState<string>('')
    const [dtRows, setDtRows] = useState<IObjectKeyValue[]>([])

    const dispatch = useDispatch()

    const {
        dtPagination, dtUpdatePagination, dtRowsPerPage, dtPageNumber
    } = usePagination()

    const elRefs = useRef<IObjectKeyValue[]>([])
    elRefs.current = Object.keys(dtRows).map((_, i) => {
        elRefs.current[i] = {}
        dtColumns.forEach(k => elRefs.current[i][k] = createRef())
        return elRefs.current[i]
    })

    const dtUpdateColumns = useCallback((pDtColumns: DtColumns = []) => {
        let currentColumns: string[] = columns || !path ? mockData.showColumnsList : pDtColumns
        if (currentColumns.length && !currentColumns.includes('actions')) {
            currentColumns = [...currentColumns, 'actions']
        }
        setDtColumns(currentColumns)
    }, [path, columns])

    const dtAddRow = useCallback((row: IObjectKeyValue) => {
        setDtRows(oldState => [...oldState.filter(r => (
            r[primaryKey] && r[primaryKey] !== row[primaryKey]
        )), row])
    }, [primaryKey])

    const dtUpdateRows = useCallback((pDtRows: IObjectKeyValue[] = []) => {
        let currentRows: IObjectKeyValue[] = rows || !path ? mockData.pageItems.content : pDtRows
        if (currentRows.length && !currentRows.find(row => row.actions)) {
            currentRows = currentRows.map(row => {
                let cloneRow = { ...row }
                Object.keys(cloneRow)
                    .forEach(rowKey => {
                        if (cloneRow[rowKey] && typeof cloneRow[rowKey] === 'object') {
                            Object.keys(cloneRow[rowKey])
                                .forEach(subRowKey => {
                                    cloneRow[`${rowKey}.${subRowKey}`] = cloneRow[rowKey][subRowKey]
                                })
                        }
                    })
                return cloneRow
            })

            currentRows = currentRows.map(row => ({ ...row, actions: null }))
        }
        setDtRows(currentRows)
    }, [path, rows])

    const dtHandleEditRow = useCallback((row: IObjectKeyValue) => {
        const cloneRow = { ...row }
        delete cloneRow.actions
        if (handleEditRow) handleEditRow(cloneRow)
    }, [handleEditRow])

    const getData = useCallback(() => {
        dispatch(toggleSpinner())
        const requestData = getDynamicTableData<DynamicTableData>(path)
        requestData(undefined, { pageSize: dtRowsPerPage, page: dtPageNumber })
            .then(data => {
                if (data) {
                    const { labels, pageItems } = data

                    if (pageItems) {
                        const { content: list } = pageItems
                        if (list) dtUpdateRows(list)

                        dtUpdatePagination(pageItems)
                    }
                    if (labels) dtUpdateColumns(labels)
                }
            }).finally(() => dispatch(toggleSpinner()))
    }, [
        dispatch, path, dtUpdateRows, dtUpdatePagination,
        dtUpdateColumns, dtRowsPerPage, dtPageNumber
    ])

    const dtHandleDeleteRow = useCallback((row: IObjectKeyValue) => {
        const cloneRow = { ...row }
        delete cloneRow.actions

        if (handleDeleteRow) handleDeleteRow(cloneRow)

        const requestDelete = deleteDynamicTableRow(path)
        requestDelete(cloneRow[primaryKey], successMessages.delete, errorMessages.delete)
            .then(_ => getData())
    }, [getData, path, primaryKey, handleDeleteRow, successMessages, errorMessages])

    // TODO-newton: update a nested object
    const dtHandleSaveEditRow = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        const findRow = dtRows.find(r => r[primaryKey] === row[primaryKey])
        if (findRow) {
            delete findRow.actions

            if (handleSaveEditRow) handleSaveEditRow(findRow)


            const filteredRef = elRefs.current.map(el => {
                const newEl = {}
                Object.keys(el)
                    .forEach(key => {
                        if (typeof el[key] !== 'object')
                            newEl[key] = el[key]
                    })
                return newEl
            })

            // when adding a new row, 
            // delete row on save if fields are blank
            if (elRefs.current) {
                const objValues = Object.values(elRefs.current[rowIndex])
                if (Array.isArray(objValues)) {
                    const checkEveryValues = objValues.every(o => {
                        if (row.id === 0) {
                            if (typeof o === 'string' && o === '') {
                                return true
                            } else if (typeof o === 'object' && !o.current) {
                                return true
                            }
                        }

                        return false
                    })

                    // check all inputs are not blank
                    const checkFindRow = Object.keys(findRow).filter(k => k !== primaryKey).length
                    const checkFilteredRef = Object.keys(filteredRef[rowIndex]).length
                    if ((checkFindRow !== checkFilteredRef) || checkEveryValues) {
                        toggleToast('error', 'Campo obbligatorio')
                        // setDtRows(oldState => oldState.filter(r => r[primaryKey] !== row[primaryKey]))
                        return
                    }
                }
            }

            // TODO-newton: send only dirty fields?
            const data = { ...findRow, ...filteredRef[rowIndex] }
            if (path && Object.keys(data).length) {
                // if is a new record call create
                if (findRow[primaryKey] === 0) {
                    const requestData = createDynamicTableRow<IObjectKeyValue>(path)
                    requestData({ ...data, [primaryKey]: undefined },
                        successMessages.create, errorMessages.create)
                        .then(_ => getData())
                } else {
                    const requestData = updateDynamicTableRow<IObjectKeyValue>(`${path}`)
                    requestData(data, successMessages.update, errorMessages.update)
                        .then(_ => getData())
                }
            }
        }
    }, [
        getData, primaryKey, elRefs, dtRows,
        path, handleSaveEditRow, errorMessages,
        successMessages
    ])

    // when adding a new row, 
    // delete row on cancel
    const dtHandleCancel = useCallback((rowIndex: number, row: IObjectKeyValue) => {
        if (elRefs.current) {
            if (row.id === 0) {
                setDtRows(oldState => oldState.filter(r => r[primaryKey] !== row[primaryKey]))
            }
        }
    }, [elRefs, primaryKey])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        dtUpdateColumns()
        dtUpdateRows()

        setDtPrimaryKey(primaryKey || mockData.primaryKey)

    }, [dtUpdateColumns, dtUpdateRows, primaryKey])

    return {
        dtColumns, dtRows, dtPrimaryKey, dtPagination, elRefs,
        dtHandleEditRow, dtHandleDeleteRow, dtHandleSaveEditRow,
        dtUpdateRows, dtAddRow, dtHandleCancel
    }
}

export default useDynamicTable