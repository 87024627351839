import React, { memo } from 'react'
import styles from './Icons.module.css'

const Next: React.FC = memo(() => {
    return (
        <svg
            className={styles.iconRoot}
            focusable="false"
            viewBox="0 0 24 24"
        >
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
        </svg>
    )
})

export default Next