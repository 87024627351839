import axiosInstance from "./interceptor";
import { IParams } from "../utils/model_types";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/simulated-products-ordereds`;

export const createSimulatedProductsOrdereds = async (data: Object) => {
    return await axiosInstance.post(resource, data, {
        headers: {'Content-Type': 'application/json'}
    })
};

export const getAllSimulatedProductsOrdereds = (params: IParams[]) => {
    const parameters = params?.map(param => `${param.key}.${param.operator}=${param.value}`);
    return axiosInstance.get(`${resource}?${parameters}`);
};

export const getSimulatedProductsOrderedBySimulatedOrderId = (orderId: number) => {
    return axiosInstance.get(`${resource}/order`, {
        params: { orderId }
    })
};