import React, {Fragment, useEffect, useMemo, useReducer, useState} from "react"
import {useHistory} from "react-router-dom";
import {ColDef, ColParams, ValueFormatterParams} from '@material-ui/data-grid';
import SelectedProducts from "../NuovoOrdine/SelectedProducts";
import {
    Button,
    Checkbox,
    FormControl,
    makeStyles,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {IPagination, IProduct, ISelectedProduct, IVendors, sortI} from "../../utils/model_types";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {
    ORDER_SAVED_ERROR,
    ORDER_SAVED_SUCCESS,
    PRDOUCT_LIST_ERROR,
    PRICE_DESCRIPTION,
    ROWS_PER_PAGE,
    updatePagination,
} from "../../store/actions/general";
import {findAllVendors} from "../../api/vendors";
import {findAllCategories, findAllProducts} from "../../api/products";
import {convertEuro} from "../../routing/utils";
import {useDispatch} from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {createSimulatedOrder, getSimulatedOrderById} from "../../api/simulatedOrders";
import {getSimulatedProductsOrderedBySimulatedOrderId} from "../../api/simulatedProductsOrdereds";
import {quantityChangeHandler} from "../../utils/utils";

const useStyle = makeStyles((theme) => ({
    filterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: '15px 0',
        backgroundColor: 'transparent',
        fontSize: '18px',
        '& > div': {
            marginRight: 10
        }
    },
    table: {
        minWidth: 700
    },
    addButton: {
        float: 'right'
    },
    tableContainer: {
        marginTop: 15
    },
    filterInput: {
        width: 420
    },
    version: {
        display: "flex",
        alignItems: "center"
    },
    root: {},
    formControl: {
        minWidth: 120,
    },
    formControlNome: {
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

const initialState = {
    simulatedOrderName: '',
    parentOrderName: null,
    version: '1',
    editable: true,
    note: ''
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'NOME_PREVENTIVO': {
            return {
                ...state,
                simulatedOrderName: action.payload
            }
        }
        case 'NOTE_PREVENTIVO': {
            return {
                ...state,
                note: action.payload
            }
        }
        case 'CLONA_PREVENTIVO': {
            return {
                ...state,
                simulatedOrderName: action.payload.simulatedOrderName,
                parentOrderName: action.payload.parentOrderName,
                version: action.payload.version,
                editable: false,
                note: action.payload.note
            }
        }
    }
};

type SureChangeBrand = {
    showModal: boolean
    currentBrand: null | string
}

const NuovoOrdineSimulato = React.memo(() => {
    const classes = useStyle();
    const history = useHistory();
    const [rowData, setRowData] = useState<IProduct[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});
    const [vendors, setVendors] = useState<IVendors[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<ISelectedProduct[]>([]);
    const [state, dispatchState] = useReducer(reducer, initialState)
    // @ts-ignore
    const [orderLoaded, setOrderLoaded] = useState(false);

    // Filter values
    const [brandFilter, setBrandFilter] = useState<string | null>(null);
    const [categoryFilter, setCategoryFilter] = useState<string>('');
    const [skuFilter, setSkuFilter] = useState<string>("");
    const [descriptionFilter, setDescriptionFilter] = useState<string>("");

    const [sortField, setSortField] = useState<sortI>({name: '', value: null});

    const [sureChangeBrand, setSureChangeBrand] = useState<SureChangeBrand>({showModal: false, currentBrand: null})

    const dispatch = useDispatch();

    const columns: ColDef[] = useMemo(() => [
        {
            field: 'check',
            renderCell: (params: ValueFormatterParams) => {
                return <Checkbox
                    checked={params.data.checked}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    onClick={() => {
                        const rowDataUpdated = rowData.map(row => {
                            if (row.id === params.data.id) {
                                row.checked = !row.checked
                            }
                            return row;
                        });
                        const isAlreadySelected = selectedRows.findIndex(elSelected => params.data.id === elSelected.id);
                        if (isAlreadySelected !== -1) {
                            setSelectedRows(selectedRows => selectedRows.filter(row => row.id !== params.data.id))
                        } else {
                            const selectedRow = {...params.data as IProduct, quantity: 1};
                            setSelectedRows([...selectedRows, selectedRow]);
                        }
                        setRowData(rowDataUpdated);
                    }}
                />
            },
            renderHeader: (params: ColParams) => {
                return <Checkbox
                    checked={selectedRows.length > 0}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    indeterminate={selectedRows.length > 0}
                />
            },
            sortable: false,
            headerName: 'check', width: 45
        },
        /*{ field: 'id', headerName: 'ID', width: 80 },*/
        {field: 'sku', headerName: 'SKU', width: 170},
        {field: 'family', headerName: 'Famiglia Prodotto', width: 210},
        {field: 'description', headerName: 'Descrizione', width: 405},
        {field: 'productType', headerName: 'Categoria', width: 150},
        {field: 'brand', headerName: 'Brand', width: 130},
        {
            field: 'price',
            headerName: 'Prezzo',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 123
        },
        {
            field: 'totalPrice',
            headerName: 'Totale Prezzo',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 123
        },
        {
            field: 'discountPrice',
            headerName: 'Prezzo Scontato',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 140
        },
        {
            field: 'totalDiscountPrice',
            headerName: 'Totale Scontato',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 140
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [rowData]);

    const routeTo = (routeName: string) => {
        history.push(routeName)
    };

    const buildProductFilter = () => {
        return {
            brandFilter: vendors.find(v => v.name === brandFilter),
            categoryFilter,
            skuFilter,
            descriptionFilter
        }
    };

    // @ts-ignore
    const {simulatedOrderName, parentOrderName, version, note} = state;

    useEffect(() => {
        // @ts-ignore
        const simulatedOrderId = history?.location?.state?.orderId;
        dispatch(toggleSpinner());
        // @ts-ignore
        Promise.all([
            findAllProducts(pagination, buildProductFilter(), sortField),
            simulatedOrderId && !orderLoaded && getSimulatedProductsOrderedBySimulatedOrderId(simulatedOrderId),
            simulatedOrderId && getSimulatedOrderById(simulatedOrderId)
        ])
            .then((response: any[]) => {
                const paginationHeaderLink = response[0].headers.link.split(',');
                const totalCount = response[0].headers["x-total-count"];
                const checkedRows = response[1]?.data || selectedRows;
                if (response[2]) {
                    const nome = response[2]?.data?.simulatedOrderName.split(' ver');
                    const nomeOrdine = nome[0];
                    const regex = /(\d*)$/;
                    let version = '';

                    //Primo clone del padre
                    if (response[2]?.data?.children === 0) {
                        version = response[2]?.data.parentOrderName ?
                            nome[1] + '.1' :
                            parseInt(nome[1]) + 1 + '';
                    } else {
                        version = response[2]?.data.parentOrderName ?
                            nome[1] + '.' + (parseInt(response[2]?.data?.children) + 1) :
                            nome[1].replace(nome[1].match(regex)[0], (parseInt(nome[1].match(regex)[0]) + parseInt(response[2]?.data?.children) + 1).toString())
                    }
                    //const version = response[2]?.data?.children === 0 ? parseInt(nome[1]) + 1 : nome[1] + '.' + (parseInt(response[2]?.data?.children) + 1);
                    // nome[1].match(regex)[0] + '.1'
                    const note = response[2]?.data?.note;
                    dispatchState({
                        type: "CLONA_PREVENTIVO", payload: {
                            simulatedOrderName: nomeOrdine,
                            parentOrderName: response[2]?.data?.simulatedOrderName,
                            version,
                            note
                            // response[2]?.data?.children
                        }
                    });
                }
                const rowData = response[0].data.map((product: IProduct) => (
                    {
                        ...product,
                        checked: checkedRows.map((product: IProduct) => product.id).includes(product.id)
                    }
                ))

                // set the branding if it is a modification of order
                if (response[1]) {
                    const data = response[1]?.data ? response[1]?.data : []
                    const findBrand = data.map((p: IProduct) => p.brand)
                    if (Array.isArray(findBrand) && findBrand.length) {
                        setBrandFilter(_ => findBrand[0])
                    }
                }

                if (response[1]?.data) {
                    setSelectedRows(response[1].data.map((d: any) => ({
                        ...d, productType: d.productType.type,
                        // @ts-ignore
                        serviceStart: d.serviceStart ? (d.serviceStart).split("T")[0] : null
                    })));
                    setOrderLoaded(true)

                }

                setRowData(rowData.map((d: any) => ({...d, productType: d.productType.type})));
                setPagination(updatePagination(pagination, paginationHeaderLink, totalCount));
                dispatch(toggleSpinner());
            })
            .catch(e => {
                dispatch(toggleSpinner())
                toggleToast('error', PRDOUCT_LIST_ERROR + '. ' + (e.messaggio || ''));
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize, brandFilter, categoryFilter, skuFilter, descriptionFilter, sortField]);

    useEffect(() => {
        dispatch(toggleSpinner());
        const categoriesPromise = findAllCategories();
        const vendorsPromise = findAllVendors();
        Promise.all([categoriesPromise, vendorsPromise].filter(Boolean)).then(response => {
            const categoriesResponse = response[0];
            const vendorsResponse = response[1];
            if (categoriesResponse.status === 200) setCategories(categoriesResponse.data);
            if (vendorsResponse.status === 200) setVendors(vendorsResponse.data)
        }).catch(e => {
            toggleToast('error', (e.messaggio || ''))
        }).finally(() => dispatch(toggleSpinner()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVendorsList = () => vendors.map((vendor) => vendor.name);

    const productsOrderedPrice = (product: ISelectedProduct, price: string) => {
        // @ts-ignore
        return (product.quantity * parseFloat(product[price])).toFixed(2)
    };

    const formatProductsOrdered = () => selectedRows.filter(selectedRow => selectedRow.quantity > 0).map(product => {
        return {
            quantity: product.quantity,
            totalBeforeDiscount: productsOrderedPrice(product, PRICE_DESCRIPTION.price),
            totalAfterDiscount: productsOrderedPrice(product, PRICE_DESCRIPTION.discountPrice),
            productsId: product.id,
        }
    });

    const handleSubmit = async () => {
        if (!simulatedOrderName) {
            toggleToast('error', 'Inserire il nome del preventivo');
            return
        }
        const username = localStorage.getItem('username');
        dispatch(toggleSpinner());
        const orderName: string = simulatedOrderName + ' ver' + version;
        const newOrder = {
            simulatedOrderName: orderName,
            orderDate: new Date(),
            totalBeforeDiscount: selectedRows.reduce((acc, el: ISelectedProduct) => acc + (el.quantity * parseFloat(el[PRICE_DESCRIPTION.price])), 0).toFixed(2),
            totalAfterDiscount: selectedRows.reduce((acc, el: ISelectedProduct) => acc + (el.quantity * parseFloat(el[PRICE_DESCRIPTION.discountPrice])), 0).toFixed(2),
            username,
            simulatedProductsOrderedDTOList: formatProductsOrdered(),
            parentOrderName,
            note,
        };

        createSimulatedOrder(newOrder).then(() => {
            toggleToast('success', ORDER_SAVED_SUCCESS);
            routeTo('/preventivi');
        }).catch(e => toggleToast('error', ORDER_SAVED_ERROR + '. ' + (e.messaggio || '')))
            .finally(() => dispatch(toggleSpinner()));
    };

    const sortTable = (param: string) => {
        setSortField(field => field.name !== param ? {name: param, value: 'desc'} : field.value === 'asc' ? {
            ...field,
            value: null
        } : {...field, value: 'asc'})
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = (event: any) => {
        const pageSize = parseInt(event.target.value, 10);
        setPagination({...pagination, page: 1, pageSize})
    };

    const toggleProduct = (product: IProduct) => {
        const rowDataUpdated = rowData.map(row => {
            if (row.id === product.id) {
                return {
                    ...row,
                    checked: true
                }
            }
            return row;
        });
        setRowData(rowDataUpdated);
    }

    const toggleSelectedProduct = (product: IProduct) => {
        setSelectedRows(rows => [...rows, {
            ...product as IProduct,
            checked: true,
            quantity: 1
        }])
        toggleProduct(product);
    };

    return (
        <Fragment>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <h3>Nome Preventivo</h3>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <FormControl variant="outlined">
                        <TextField
                            id="nomeOrdine"
                            variant="outlined"
                            label="Nome Preventivo *"
                            value={simulatedOrderName}
                            onChange={(e: any) => dispatchState({type: 'NOME_PREVENTIVO', payload: e.target.value})}
                        />
                    </FormControl>
                    <span className={classes.version}>
                        ver{version}
                    </span>
                </Paper>
            </div>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <h3>Note</h3>
                <Paper elevation={0} component="div" className={classes.filterInput}>
                    <FormControl>
                        <TextField
                            id="note"
                            variant="outlined"
                            multiline={true}
                            label="Note"
                            value={note}
                            onChange={(e: any) => dispatchState({type: 'NOTE_PREVENTIVO', payload: e.target.value})}
                            style={{width: 420}}
                        />
                    </FormControl>
                </Paper>
            </div>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <FormControl>
                        <h3>
                            Brand di riferimento del preventivo
                        </h3>
                        <Autocomplete
                            id="brand-filter"
                            options={getVendorsList()}
                            style={{width: 300}}
                            renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
                            value={brandFilter}
                            onChange={(e: any, newValue: string | null) => {
                                if (selectedRows.length > 0) {
                                    setSureChangeBrand({currentBrand: newValue, showModal: true})
                                } else {
                                    setBrandFilter(newValue);
                                    setPagination(pagination => ({...pagination, page: 0}))
                                }
                            }}
                        />
                    </FormControl>
                </Paper>
            </div>
            {brandFilter && (
                <>
                    <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                        <h3>Elenco prodotti</h3>
                        <Paper elevation={0} component="div" className={classes.filterContainer}>
                            <FormControl>
                                <TextField
                                    id="description-filter"
                                    variant="outlined"
                                    label="Descrizione"
                                    style={{width: 350}}
                                    value={descriptionFilter}
                                    onChange={(e: any) => {
                                        setDescriptionFilter(e.target.value);
                                        setPagination({...pagination, page: 0});
                                    }}/>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Categoria"
                                    id="demo-simple-select"
                                    value={categoryFilter}
                                    onChange={(e: any) => {
                                        setCategoryFilter(e.target.value || '');
                                        setPagination({...pagination, page: 0})
                                    }}
                                >
                                    <MenuItem value="">Nessuna</MenuItem>
                                    {React.Children.toArray(categories.map(c => <MenuItem
                                        value={c.id}>{c.type}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <TextField
                                    id="sku-filter"
                                    variant="outlined"
                                    label="SKU"
                                    value={skuFilter}
                                    onChange={(e: any) => {
                                        setSkuFilter(e.target.value);
                                        setPagination(pagination => ({...pagination, page: 0}))
                                    }}/>
                            </FormControl>
                            {/*<Button variant="contained" color="primary" onClick={filterResults}>Filtra</Button>*/}
                        </Paper>

                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {React.Children.toArray(columns.map((col: ColDef) => (
                                            col.field === 'check' ?
                                                <TableCell style={{minWidth: col.width}}>

                                                </TableCell> :
                                                <TableCell style={{minWidth: col.width, cursor: 'pointer'}}
                                                           onClick={() => sortTable(col?.field || '')}>
                                                    {col.headerName}
                                                    {
                                                        sortField.value &&
                                                        sortField.name === col.field &&
                                                        <i
                                                            className={`ml-1 text-secondary fas ${sortField.value === 'desc' ? 'fa-long-arrow-alt-down' : 'fa-long-arrow-alt-up'}`}/>
                                                    }
                                                </TableCell>
                                        )))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {React.Children.toArray(rowData.map((row: IProduct) => (
                                        <TableRow className={classes.root} key={row.id}
                                                  style={row.checked ? {backgroundColor: '#E7E7E7'} : {backgroundColor: 'white'}}>
                                            {
                                                React.Children.toArray(columns.map((col) => (
                                                    <TableCell component="th" scope="row">
                                                        {
                                                            col.field === 'check' ?
                                                                <Button
                                                                    variant="contained" color="primary"
                                                                    onClick={() => toggleSelectedProduct(row)}
                                                                > AGGIUNGI
                                                                </Button> :
                                                                col.valueFormatter ? col.valueFormatter(row[col.field]) : row[col.field]
                                                        }
                                                    </TableCell>
                                                )))
                                            }
                                        </TableRow>
                                    )))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={pagination.rowCount || 20}
                                page={pagination.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                rowsPerPage={pagination.pageSize}
                                rowsPerPageOptions={ROWS_PER_PAGE}
                            />
                        </TableContainer>
                    </div>
                    <SelectedProducts
                        columns={columns}
                        selectedRows={selectedRows}
                        orderType={"preventivo"}
                        quantityChangeHandler={(e, index) => quantityChangeHandler(e, selectedRows, setSelectedRows, index, rowData, setRowData)}
                    />
                    <div style={{overflow: 'hidden'}}>
                        <Button
                            style={{float: 'right'}}
                            variant="contained" color="primary"
                            disabled={selectedRows?.length === 0}
                            onClick={handleSubmit}
                        >
                            {orderLoaded ? 'MODIFICA' : 'CREA'} PREVENTIVO
                        </Button>
                    </div>
                </>
            )}
            {/* modal confirm change brand */}
            <Modal
                open={sureChangeBrand.showModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    Attenzione! Cambiando brand eliminerai tutti i prodotti selezionati. Vuoi continuare?
                    <div className="mt-2">
                        <Button
                            color="secondary"
                            onClick={() => {
                                setSureChangeBrand({...sureChangeBrand, showModal: false})
                            }}
                        >
                            Annulla
                        </Button>
                        <Button
                            style={{float: 'right'}} color="primary"
                            onClick={() => {
                                setBrandFilter(sureChangeBrand.currentBrand)
                                setSelectedRows([])
                                setSureChangeBrand({...sureChangeBrand, showModal: false})
                                setPagination(pagination => ({...pagination, page: 0}))
                            }}
                        >
                            Conferma
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* modal confirm change brand */}
        </Fragment>
    )
});

export default NuovoOrdineSimulato;
