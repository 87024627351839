import axiosInstance from './interceptor'

const {REACT_APP_BASENAME} = process.env

const resource = `${REACT_APP_BASENAME}/listings`

export interface IListing {
    id: number
    vendor: string
    hash: string
    name: string
}

export const getListings = async () => {
    return await axiosInstance.get<IListing[]>(resource)
}

export const uploadListing = async (formData: FormData, vendor = 'vendor') => {
    return await axiosInstance.post(`${resource}/${vendor}`, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export const downloadListingById = async (id: string | number) => {
    return await axiosInstance.get(`${resource}/download/${id}`, { responseType: 'blob' })
}
