import {
    Input,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core"
import React from "react"
import {ISelectedProduct} from "../../utils/model_types";
import {PRICE_DESCRIPTION} from "../../store/actions/general";
import {ColDef} from "@material-ui/data-grid";
import {convertEuro} from "../../routing/utils";
import {daysBetweenTwoDates} from "../../utils/utils";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const useStyles = makeStyles({
    table: {
        minWidth: 1400,
    },
    textField: {
        marginLeft: 1,
        marginRight: 1,
        width: 140,
    },
    root: {
        overflowX: "auto"
    }
});

interface IProps {
    columns: ColDef[],
    selectedRows: ISelectedProduct[]
    quantityChangeHandler: (e: any, index: number) => void,
    handleChangeServiceDate?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string, index: number) => void,
    changeValidityPeriod?: (e: any) => void,
    changeValidityUnit?: (e: any) => void,
    orderType: string,
    handleChangeDestination?: (e: any, index: number) => void,
    handleChangeNote?: (value: string, index: number) => void,
    destinations?: any
}

type Destination = {
    id?: number
    address?: string
}

const findDestination = (destinations?: Destination[], param?: Destination) => {
    return destinations?.find(d => d.id === param?.id || d.address === param?.address) || {id: param?.id || 0, address: param?.address || ''}
}

const SelectedProducts = (props: IProps) => {
    const {
        columns,
        selectedRows,
        quantityChangeHandler,
        handleChangeServiceDate,
        orderType,
        handleChangeDestination,
        destinations,
        handleChangeNote
    } = props

    const classes = useStyles();
    const getTotalNumberOfProducts = () => {
        return selectedRows.map(el => el.quantity).reduce((acc, el) => acc + el, 0.0)
    };

    const getTotalPrice = (priceDescription: string) => selectedRows.reduce((acc, el: ISelectedProduct) => acc + fixPrice(el[priceDescription], el.quantity), 0);

    const fixPrice = (price: any, quantity: number) => quantity * Number(parseFloat(price.toFixed(2)))

    return (
        <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
            <h2>Prodotti selezionati</h2>

            <TableContainer component={Paper} className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {columns?.slice(1).map(column => <TableCell key={column.field}
                                                                        style={{minWidth: column.width}}
                                                                        align="left">{column.headerName}</TableCell>)}
                            {orderType === 'ordine' && <TableCell align="left">Note</TableCell>}
                            <TableCell align="left">Quantità</TableCell>
                            {orderType === 'ordine' && <TableCell align="left">Manutenzione</TableCell>}
                            {orderType === 'ordine' && <TableCell align="left">Periodo validità</TableCell>}
                            {orderType === 'ordine' &&
                            <TableCell align="left" style={{minWidth: 250}}>Destinazione</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedRows.map((el: ISelectedProduct, index) => {
                            const destinationAddressValue = findDestination(destinations, {
                                id: el.destinationsId,
                                address: el.destinationsAddress
                            })
                            // @ts-ignore
                            // @ts-ignore
                            // @ts-ignore
                            return <TableRow key={index + el.family}>
                                <TableCell align="left">{el.sku}</TableCell>
                                {orderType === 'preventivo' && <TableCell align="left">{el.family}</TableCell>}
                                <TableCell align="left">{el.description}</TableCell>
                                <TableCell align="left">{el.productType}</TableCell>
                                <TableCell align="left">{el.brand}</TableCell>
                                <TableCell align="left">{convertEuro(el.price)}</TableCell>
                                <TableCell align="left">{convertEuro(fixPrice(el.price, el.quantity))}</TableCell>
                                <TableCell align="left">{convertEuro(el.discountPrice)}</TableCell>
                                <TableCell align="left">{convertEuro(fixPrice(el.discountPrice, el.quantity))}</TableCell>
                                {
                                    orderType === 'ordine' && (
                                        <TableCell style={{minWidth: 200}}>
                                            <TextField
                                                variant="outlined"
                                                label="Nota"
                                                value={el.note || ''}
                                                onChange={(e) => {
                                                    if (handleChangeNote) {
                                                        handleChangeNote(e.target.value, index)
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    )
                                }
                                <TableCell align="left">
                                    <Input id={el.sku} onChange={e => quantityChangeHandler(e, index)} type="number"
                                           value={el.quantity}/>
                                </TableCell>
                                {
                                    orderType === 'ordine' && (
                                        <TableCell style={{width: 'auto', display: 'inline-flex'}}>
                                            <TextField
                                                type="date"
                                                inputProps={{'data-id': el.sku}}
                                                value={el.serviceStart || ''}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleChangeServiceDate && handleChangeServiceDate(e, 'serviceStart', index)}
                                                label="Data Avvio"
                                                style={{marginBottom: 10}}
                                            />
                                            <TextField
                                                type="date"
                                                inputProps={{'data-id': el.sku}}
                                                value={el.serviceEnd || ''}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleChangeServiceDate && handleChangeServiceDate(e, 'serviceEnd', index)}
                                                label="Data Fine"
                                            />
                                        </TableCell>
                                    )
                                }
                                {
                                    orderType === 'ordine' && <TableCell>
                                        <Input id={el.sku}
                                               type="number"
                                               value={daysBetweenTwoDates(el.serviceStart, el.serviceEnd)}
                                               readOnly
                                               disabled
                                        />
                                    </TableCell>
                                }
                                {
                                    orderType === 'ordine' && <TableCell>
                                        <Autocomplete
                                            id={el.sku}
                                            style={{minWidth: 200}}
                                            options={destinations}
                                            value={destinationAddressValue}
                                            // @ts-ignore
                                            getOptionLabel={(option) => option.address}
                                            onChange={(e: any, newValue: any) => handleChangeDestination && handleChangeDestination(newValue, index)}
                                            // @ts-ignore
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           variant="outlined"/>}
                                        />
                                        {/*  <Input id={el.sku}
                                       type="text"
                                       value={el.destinationsAddress}
                                       onChange={e => handleChangeDestination &&  handleChangeDestination(e,index)}
                                />*/}
                                    </TableCell>
                                }
                            </TableRow>
                        })}
                        <TableRow>
                            <TableCell align="left"
                                       colSpan={orderType === 'preventivo' ? 6 : 5}><b>Totale</b></TableCell>
                            <TableCell
                                colSpan={orderType === 'preventivo' ? 2 : 2}
                                align="left">{convertEuro(getTotalPrice(PRICE_DESCRIPTION.price))}</TableCell>
                            <TableCell
                                align="left">{convertEuro(getTotalPrice(PRICE_DESCRIPTION.discountPrice))}</TableCell>
                            {orderType === 'ordine' && (<TableCell />)}
                            <TableCell align="left">{getTotalNumberOfProducts()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default SelectedProducts;
