import React, {useCallback, useEffect, useState} from "react"
import {
    Button,
    FormControl,
    InputLabel,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core"
import ListaOrdiniRow from "../../components/ListaOrdiniRow/ListaOrdiniRow";

import {IOrder, IPagination, IParams} from "../../utils/model_types";
import {exportListaOrdini, getOrdersList, getOrderWithFilters} from "../../api/orders";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {
    ACCEPTED,
    ORDER_LIST_ERROR,
    PROGETTISTA,
    ROWS_PER_PAGE,
    SELECTED_PAGE,
    updatePagination,
    US,
    USP,
    UTBO,
    UTBOP
} from "../../store/actions/general";
import {getRoles} from "../../routing/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {useHistory} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {step} from "../../utils/enums";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {Channel, Owner} from "../DettaglioOrdine/DettaglioOrdine";
import {saveAs} from "file-saver";
import { getChannelList } from "../../store/actionCreators/channels";

const useStyles = makeStyles({
    table: {
        minWidth: 700
    },
    addButton: {
        float: 'right',
        marginBottom: 15,
    },
    exportButton: {
        float: 'left',
        marginBottom: 15,
    },
    tableContainer: {
        marginTop: 15
    },
    filterInput: {
        width: 420,
        marginTop: 20
    },
    select: {
        width: 150,
        marginLeft: 30,
    },
    filterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: '15px 0',
        backgroundColor: 'transparent',
        '& > div > div': {
            marginRight: 10
        }
    },
    formControl: {
        minWidth: 120,
    },
});

const ListaOrdini = () => {

    const classes = useStyles();
    const history = useHistory();

    const [orders, setOrders] = useState<IOrder[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});
    const [togglePagination, setTogglePagination] = useState(true);
    const [reloadOrders, setReloadOrders] = useState(false)
    const [owner, setOwner] = useState('')
    const [channel, setChannel] = useState('')
    const [orderName, setOrderName] = useState('')

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);
    const dispatch = useDispatch();

    const storeChannels = useSelector((state: RootState) => state.channels.channelList);

    const getAllOrders = useCallback(() => {
        dispatch(toggleSpinner());
        const roles = getRoles(storeUser);
        let params: IParams[] = [];
        if (roles?.includes(US)) {
            params.push({
                key: 'username',
                operator: 'equals',
                value: localStorage.getItem('username') || ''
            })
        } else if (roles?.includes(UTBO) || roles?.includes(UTBOP)) {
            params.push({
                key: 'status1stStep',
                operator: 'equals',
                value: ACCEPTED
            })
        } else if (roles?.includes(PROGETTISTA)) {
            params.push({
                key: 'orderProject',
                operator: 'equals',
                value: '1'
            })
        }
        getOrdersList(params, pagination).then(response => {

            if (response.status === 200) {
                const paginationHeaderLink = response.headers.link.split(',');
                const totalCount = response.headers["x-total-count"];
                setPagination(updatePagination(pagination, paginationHeaderLink, totalCount));
                setTogglePagination(true)
                setOrders(response.data);
            }
            dispatch(toggleSpinner());
        }).catch(() => {
            dispatch(toggleSpinner())
            toggleToast('error', ORDER_LIST_ERROR)
        })
    }, [storeUser, dispatch, pagination])

    useEffect(() => {
        if (owner) {
            filterOrderByOwner(owner)
        } else {
            getAllOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize]);

    useEffect(() => {
        if (reloadOrders) {
            getAllOrders()
            setReloadOrders(false)
        }
    }, [reloadOrders, getAllOrders])

    useEffect(()=>{
        /** filtro con lista di canali disponibile per tutti i ruoli */
        // if(auth?.includes('UTBOP'))
        dispatch(getChannelList());
    },[])


    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = (event: any) => {
        const pageSize = parseInt(event.target.value, 10);
        setPagination({...pagination, page: 0, pageSize})
    };

    const filterOrders = (orderName: string) => {
        getOrderWithFilters({
            pagination,
            orderName,
            owner,
            channel
        }).then(response => {
            if (response.status === 200) {
                const paginationHeaderLink = response.headers.link.split(',');
                const totalCount = response.headers["x-total-count"];
                setPagination(updatePagination({
                    "page": 0,
                    "pageSize": 10
                }, paginationHeaderLink, totalCount))
                setTogglePagination(false);
                setOrders(response.data);
            }
        });
    }

    function goToNewOrder() {
        dispatch({type: SELECTED_PAGE, payload: {message: 'Nuovo ordine'}});
        localStorage.setItem('selected-page', 'Nuovo ordine');
        history.push('/creazione-ordine')
    }

    function downloadExcel() {
        dispatch(toggleSpinner());
        exportListaOrdini(auth)
            .then(res => {
                dispatch(toggleSpinner());
                saveAs(res.data, `ListaOrdini.xlsx`)
            });
    }

    const filterOrderByOwner = (owner?: string, initialPagination?: IPagination) => {
        const newPagination = !!initialPagination ? initialPagination : pagination;
        getOrderWithFilters({
            pagination: newPagination,
            orderName,
            owner,
            channel
        })
            .then(response => {
                if (response.status === 200) {
                    const paginationHeaderLink = response.headers.link.split(',');
                    const totalCount = response.headers["x-total-count"];
                    setPagination(updatePagination(pagination, paginationHeaderLink, totalCount))
                    setOrders(response.data);
                }
            })
    }

    const filterOrderByChannel = (channel?: string, initialPagination?: IPagination) => {
        const newPagination = !!initialPagination ? initialPagination : pagination;
        getOrderWithFilters({
            pagination: newPagination,
            orderName,
            owner,
            channel
        })
            .then(response => {
                if (response.status === 200) {
                    const paginationHeaderLink = response.headers.link.split(',');
                    const totalCount = response.headers["x-total-count"];
                    setPagination(updatePagination(pagination, paginationHeaderLink, totalCount))
                    setOrders(response.data);
                }
            })
    }

    return (
        <>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <div>
                        <FormControl>
                            <TextField id="outlined-basic"
                                       variant="outlined"
                                       label="Filtra per nome ordine"
                                       onChange={({target: {value}}) => {
                                           setOrderName(value)
                                           value !== '' && setPagination({page: 0, pageSize: pagination.pageSize})
                                           filterOrders(value)
                                       }}/>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="Owner-select-label">Filtra per owner</InputLabel>
                            <Select
                                labelId="Owner-select-label"
                                label="Filtra per owner"
                                id="Owner-select"
                                style={{width: 200}}
                                value={owner}
                                onChange={({target: {value}}: any) => {
                                    setOwner(value)
                                    value !== '' && setPagination({page: 0, pageSize: pagination.pageSize})
                                    filterOrderByOwner(value, {page: 0, pageSize: pagination.pageSize})
                                }}
                            >
                                <MenuItem value="">
                                    <em>Nessuno</em>
                                </MenuItem>
                                <MenuItem value={Owner.TIM}>{Owner.TIM}</MenuItem>
                                <MenuItem value={Owner.ITW}>{Owner.ITW}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="Channel-select-label">Filtra per canale</InputLabel>
                            <Select
                                labelId="Channel-select-label"
                                label={"Filtra per canale"}
                                id="Channel-select"
                                style={{width: 200}}
                                value={channel}
                                onChange={({target: {value}}: any) => {
                                    setChannel(value)
                                    value !== '' && setPagination({page: 0, pageSize: pagination.pageSize})
                                    filterOrderByChannel(value, {page: 0, pageSize: pagination.pageSize})
                                }}
                            >
                                
                                <MenuItem value="">
                                    <em>Nessuno</em>
                                </MenuItem>
                                {
                                    storeChannels.map(el => 
                                        <MenuItem key={el.id} value={el.id}>{el.channel}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                </Paper>
            </div>

            {[US, USP].join(", ").includes(auth) && (
                <>
                    <Button className={classes.addButton} variant="contained" color="primary" onClick={goToNewOrder}>
                        Nuovo ordine
                    </Button>
                </>
            )}
            <Button className={classes.exportButton} variant="contained" color="primary" onClick={downloadExcel}>
                Esporta lista ordini
            </Button>

            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{padding: '10px'}}>Nome ordine</TableCell>
                            <TableCell style={{padding: '10px'}}>Canale</TableCell>
                            {auth?.includes(UTBOP) && (<TableCell align="center">Owner</TableCell>)}
                            <TableCell style={{padding: '10px'}} align="center">Data Creazione</TableCell>
                            <TableCell style={{padding: '10px'}} align="center">Acronimo</TableCell>
                            <TableCell style={{padding: '10px'}} align="center">Creato da</TableCell>
                            {
                                React.Children.toArray(
                                    step.map(step => (
                                        <TableCell style={{padding: '10px'}} align="center">
                                            <div>{step}</div>
                                        </TableCell>
                                    ))
                                )
                            }
                            {auth?.includes(UTBOP) && (
                                <TableCell style={{padding: '10px'}} align="center">Ordine chiuso</TableCell>)}
                            <TableCell style={{padding: '10px'}} align="center">Totale scontato</TableCell>
                            <TableCell style={{padding: '10px'}} align="center">Fatturato</TableCell>
                            <TableCell style={{padding: '10px'}} align="center">Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders && React.Children.toArray(orders.map((el: IOrder) => (
                            <ListaOrdiniRow key={el.id} row={el} handlerSwitchOrder={() => setReloadOrders(true)}/>
                        )))}
                    </TableBody>
                </Table>
                {togglePagination && <TablePagination
                    component="div"
                    count={pagination.rowCount || 0}
                    page={pagination.page}
                    onChangePage={handleChangePage}
                    rowsPerPage={pagination.pageSize}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                />}
            </TableContainer>
        </>
    )
};

export default ListaOrdini
