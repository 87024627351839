import React from "react"
import {Table, TableCell, TableRow} from "@material-ui/core";


export default function TableDettaglioOrdiniSimulati({children, tableHeaders}: any) {
    return (
        <Table>
            <TableRow>
                {
                    React.Children.toArray(tableHeaders.map((header:any) => {
                        return (
                            <TableCell style={{fontWeight: 'bold'}}>{header}</TableCell>
                        )
                    }))
                }
            </TableRow>
            {children}
        </Table>
    )

}
