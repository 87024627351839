import { Drawer, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
//import { ChevronLeft } from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import React from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom";
import { getRouteItems } from "../../routing/utils";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../store/reducers";
import { useStyles } from "./styles";
import {IRoute} from "../../routing/types";
import {SELECTED_PAGE} from "../../store/actions/general";

interface ISidebar {
    open: boolean
    handleDrawerClose: () => void
}

const Sidebar = ({ open, handleDrawerClose }: ISidebar) => {
    const classes = useStyles();
    const history = useHistory();
    const storeUser = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();

    const routeTo = (route: IRoute) => {
        dispatch({type: SELECTED_PAGE, payload: {message: route.name}});
        localStorage.setItem('selected-page', route.name);
        history.push(route.path);
    }

    const renderRouteButtons = () => {
        const availableRoutes = getRouteItems(storeUser)

        return availableRoutes.filter(r => r.showInSidebar).map(r => (
            <ListItem button onClick={() => routeTo(r)} title={r.name} key={"sidebar_" + r.name}>
                <ListItemIcon style={{paddingLeft: '8px'}}>
                    {r.logo.icon && React.createElement(r.logo.icon)}
                </ListItemIcon>
                <ListItemText primary={r.name} />
            </ListItem>
        ));
    }

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <MenuIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                {renderRouteButtons()}
            </List>
            <Divider />

        </Drawer>
    )
}

export default Sidebar;
