import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React from "react"


const BasicDialog = (props: { open: boolean, handleClose: any, title: string, type: string, selectedObject: any, confirmOperation: any, textModal: string }) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.textModal}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={props.handleClose}>Annulla</Button>
                <Button variant="contained" onClick={props.confirmOperation}> Conferma</Button>
            </DialogActions>
        </Dialog>
    );
}

export default BasicDialog;
