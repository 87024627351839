import { makeStyles } from "@material-ui/core"

export const useStyle = makeStyles({
    dashboardContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    subTitle: {
        marginBottom: 20
    },
    table: {
        minWidth: 650,
    },
    gauge: {
        height: 300
    },
    main: {
        display: "flex",
        width: "100%",
        marginLeft: "1rem",
        marginBottom: "2em"
    },
    leftSide: {
        width: "65%"
    },
    rightSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: "1 1",
        width: "35%"
    }
});