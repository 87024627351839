import axiosInstance from "../../../api/interceptor"
import { toggleToast } from "../../../store/actionCreators/general";
import { IPagination, IParams } from "../../../utils/model_types";

const { REACT_APP_BASENAME: URL } = process.env;

export const getDynamicTableData = <T>(path: string) =>
    async (params?: IParams[], pagination?: IPagination) => {
        let url = `${URL}/${path}/table`
        let parameters = ''
        let paginationParameter = ''
        if (params) {
            parameters = params.map(param => `${param.key}.${param.operator}=${param.value}`).join(',')
        }
        if (pagination) {
            paginationParameter = `page=${pagination.page}&size=${pagination.pageSize}`
        }
        if (paginationParameter) {
            url += `?${paginationParameter}`
        }
        if (parameters) {
            url += `&${parameters}`
        }

        try {
            const resp = await axiosInstance.get<T>(url)
            return resp.data
        } catch (e) {
            console.log(e)
            toggleToast('error', e.messaggio)
        }
    }

export const updateDynamicTableRow = <T>(path: string) =>
    async (data: any, updateSuccessMessage: string, updateErrorMessage: string) => {
        let url = `${URL}/${path}`

        try {
            const resp = await axiosInstance.put<T>(url, data)
            toggleToast('success', updateSuccessMessage)
            return resp.data
        } catch (e) {
            toggleToast('error', updateErrorMessage)
        }
    }

export const createDynamicTableRow = <T>(path: string) =>
    async (data: any, createSuccessMessage: string, createErrorMessage: string) => {
        let url = `${URL}/${path}`

        try {
            const resp = await axiosInstance.post<T>(url, data)
            toggleToast('success', createSuccessMessage)
            return resp.data
        } catch (e) {
            toggleToast('error', createErrorMessage)
        }
    }

export const deleteDynamicTableRow = <T>(path: string) =>
    async (id: string | number, deleteSuccessMessage: string, deleteErrorMessage: string) => {
        let url = `${URL}/${path}`

        try {
            const resp = await axiosInstance.delete<T>(`${url}/${id}`)
            toggleToast('success', deleteSuccessMessage)
            return resp.data
        } catch (_) {
            toggleToast('error', deleteErrorMessage)
        }
    }