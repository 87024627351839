import React, { memo } from 'react'
import styles from './Icons.module.css'

const Previous: React.FC = memo(() => {
    return (
        <svg
            className={styles.iconRoot}
            focusable="false"
            viewBox="0 0 24 24"
        >
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
        </svg>
    )
})

export default Previous