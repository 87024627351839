import React from 'react'
import DynamicTable, { ToastMessages } from '../../components/DynamicTable'

const errorMessages: ToastMessages = { 
    delete: 'Ops.. non è possibile eliminare questa commessa',
    create: 'Ops.. non è possibile creare questa commessa',
    update: 'Ops.. non è possibile aggiornare questa commessa'
}

const successMessages: ToastMessages = {
    delete: 'Commessa eliminata con successo',
    create: 'Commessa creata con successo',
    update: 'Commessa aggiornata con successo'
}

const GestioneCommesse: React.FC = () => {
    return (
        <>
            <DynamicTable
                labelCreate={'Nuova commessa'}
                path='commitments'
                primaryKey='id'
                errorMessages={errorMessages}
                successMessages={successMessages}
            />
        </>
    )
}

export default GestioneCommesse