import {Dispatch} from "react";

import {EmailBoxAction, TOGGLE_EMAIL_BOX} from "../reducers/email-box";
import {EmailBoxParams} from "../../components/EmailBox";
import {toggleSpinner, toggleToast} from "./general";
import {GeneralAction} from "../actions/types";
import axiosInstance from "../../api/interceptor";
import {ORDER_MAIL_ERROR} from "../actions/general";

export const openEmailBox = (): EmailBoxAction => {
    return {
        type: TOGGLE_EMAIL_BOX,
        payload: {
            isOpenEmailBox: true
        }
    }
}

export const closeEmailBox = (): EmailBoxAction => {
    return {
        type: TOGGLE_EMAIL_BOX,
        payload: {
            isOpenEmailBox: false
        }
    }
}

const resource = `${process.env.REACT_APP_BASENAME}/mail/service-desk`

export const submitEmailBox = (dispatch: Dispatch<EmailBoxAction | GeneralAction>, formInput: EmailBoxParams, cb: () => void) => {
    const token = window.localStorage.getItem('token');
    if (token) {
        dispatch(toggleSpinner());

        try {
            const parsedToken = JSON.parse(atob(token.split('.')[1]))
            const email = parsedToken.email;
            const {text, subject} = formInput

            axiosInstance.post(resource, {subject, email, text}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if (res.status === 200) {
                    dispatch(toggleToast('success', 'Email inviata correttamente'));
                    dispatch(closeEmailBox())
                    cb()
                } else {
                    dispatch(toggleToast('error', ORDER_MAIL_ERROR));
                }
            }).catch(e => {
                dispatch(toggleToast('error', ORDER_MAIL_ERROR));
            }).finally(() => {
                dispatch(toggleSpinner());
            })
        } catch (e) {
            console.error(e)
            dispatch(toggleSpinner());
            dispatch(toggleToast('error', ORDER_MAIL_ERROR));
        }
    }
}