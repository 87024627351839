import React from "react";
import {makeStyles, Button, Modal} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

type Props = {
    show: boolean
    onConfirm: () => void
    onCancel: () => void
}

const ModalConfirmUploadPO: React.FC<Props> = (props) => {
    const {show, onCancel, onConfirm} = props
    const classes = useStyle();

    return (
        <Modal
            open={show}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}>
            <div className={classes.paper}>
                Una volta caricato il Piano Operativo, non sarà più possibile applicare gli sconti Trade-In.
                Proseguire?
                <div className="mt-2">
                    <Button
                        color="secondary"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        No
                    </Button>
                    <Button
                        style={{float: 'right'}} color="primary"
                        onClick={() => {
                            onConfirm()
                        }}
                    >
                        Sì
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalConfirmUploadPO;
