export const mockData = {
    "labels": [
        "id",
        "orderId",
        "orderDate",
        "approvalDate1stStep",
        "status1stStep",
        "note1stStep",
        "approvalDate2ndStep",
        "status2ndStep",
        "note2ndStep",
        "approvalDate3rdStep",
        "status3rdStep",
        "note3rdStep",
        "lastPossibleDate3rdStep",
        "approvalDate4thStep",
        "status4thStep",
        "note4thStep",
        "lastPossibleDate4thStep",
        "totalBeforeDiscount",
        "totalAfterDiscount",
        "username",
        "productsOrderedDTOList",
        "deliveries",
        "simulatedOrderName",
        "commitmentCode",
        "commitmentDescription",
        "warehouse"
    ],
    "pageItems": {
        "content": [
            {
                "id": 1,
                "orderId": "b5d1ca3d7e361b76281da1c30dda0e49527daa3f",
                "orderDate": "2021-03-11T14:02:19Z",
                "approvalDate1stStep": null,
                "status1stStep": "ONHOLD",
                "note1stStep": null,
                "approvalDate2ndStep": null,
                "status2ndStep": null,
                "note2ndStep": null,
                "approvalDate3rdStep": null,
                "status3rdStep": null,
                "note3rdStep": null,
                "lastPossibleDate3rdStep": null,
                "approvalDate4thStep": null,
                "status4thStep": null,
                "note4thStep": null,
                "lastPossibleDate4thStep": null,
                "totalBeforeDiscount": 30600.0,
                "totalAfterDiscount": 25664.22,
                "username": "user_sogei",
                "productsOrderedDTOList": null,
                "deliveries": {
                    "id": 1,
                    "firstNameReferent": "Andrea",
                    "lastNameReferent": "Rossopinti",
                    "principal": true,
                    "address": "Sito di Disaster Recovery",
                    "necessaryLicencePlate": true,
                    "licencePlate": "ED184EA",
                    "ordersId": null
                },
                "simulatedOrderName": "1 - Andrea Rossopinti",
                "commitmentCode": null,
                "commitmentDescription": null,
                "warehouse": null
            },
            {
                "id": 2,
                "orderId": "d14616d21e8e04ad29fad5715b3c8863f0ee64a5",
                "orderDate": "2021-03-11T14:30:21Z",
                "approvalDate1stStep": null,
                "status1stStep": "ONHOLD",
                "note1stStep": null,
                "approvalDate2ndStep": null,
                "status2ndStep": null,
                "note2ndStep": null,
                "approvalDate3rdStep": null,
                "status3rdStep": null,
                "note3rdStep": null,
                "lastPossibleDate3rdStep": null,
                "approvalDate4thStep": null,
                "status4thStep": null,
                "note4thStep": null,
                "lastPossibleDate4thStep": null,
                "totalBeforeDiscount": 220355.0,
                "totalAfterDiscount": 184811.74,
                "username": "user_sogei",
                "productsOrderedDTOList": null,
                "deliveries": {
                    "id": 2,
                    "firstNameReferent": "Andrea",
                    "lastNameReferent": "Rossopinti",
                    "principal": true,
                    "address": "Roma -Via XX Settembre",
                    "necessaryLicencePlate": false,
                    "licencePlate": "",
                    "ordersId": null
                },
                "simulatedOrderName": "2 - Andrea Rossopinti",
                "commitmentCode": null,
                "commitmentDescription": null,
                "warehouse": null
            },
            {
                "id": 3,
                "orderId": "f2c642c6bfc53b1177c9b06a65958d7a92ae8057",
                "orderDate": "2021-03-11T16:30:41Z",
                "approvalDate1stStep": "2021-03-11T16:38:10Z",
                "status1stStep": "ACCEPTED",
                "note1stStep": null,
                "approvalDate2ndStep": null,
                "status2ndStep": "ONHOLD",
                "note2ndStep": null,
                "approvalDate3rdStep": null,
                "status3rdStep": null,
                "note3rdStep": null,
                "lastPossibleDate3rdStep": "2021-03-16T16:38:14Z",
                "approvalDate4thStep": null,
                "status4thStep": null,
                "note4thStep": null,
                "lastPossibleDate4thStep": null,
                "totalBeforeDiscount": 122305.0,
                "totalAfterDiscount": 102577.2,
                "username": "user_sogei",
                "productsOrderedDTOList": null,
                "deliveries": {
                    "id": 3,
                    "firstNameReferent": "Andrea",
                    "lastNameReferent": "Rossopinti",
                    "principal": true,
                    "address": "Sito di Piazza Mastai",
                    "necessaryLicencePlate": false,
                    "licencePlate": "",
                    "ordersId": null
                },
                "simulatedOrderName": "3 -      Andrea Rossopinti",
                "commitmentCode": null,
                "commitmentDescription": null,
                "warehouse": null
            },
            {
                "id": 4,
                "orderId": "1f77027cae58e9e4ed816",
                "orderDate": "2020-11-19T10:32:32Z",
                "approvalDate1stStep": null,
                "status1stStep": "ONHOLD",
                "note1stStep": null,
                "approvalDate2ndStep": null,
                "status2ndStep": null,
                "note2ndStep": null,
                "approvalDate3rdStep": null,
                "status3rdStep": null,
                "note3rdStep": null,
                "lastPossibleDate3rdStep": null,
                "approvalDate4thStep": null,
                "status4thStep": null,
                "note4thStep": null,
                "lastPossibleDate4thStep": null,
                "totalBeforeDiscount": 399.0,
                "totalAfterDiscount": 247.38,
                "username": "user_sogei",
                "productsOrderedDTOList": null,
                "deliveries": {
                    "id": 4,
                    "firstNameReferent": "mario",
                    "lastNameReferent": "malla",
                    "principal": true,
                    "address": "via via",
                    "necessaryLicencePlate": false,
                    "licencePlate": null,
                    "ordersId": null
                },
                "simulatedOrderName": "4 - null",
                "commitmentCode": "COD-111",
                "commitmentDescription": "COD-CENTOUNDICI",
                "warehouse": null
            },
            {
                "id": 5,
                "orderId": "d708da975b9c16433a86dba8d6dd20953c47b3a1",
                "orderDate": "2021-03-11T15:03:32Z",
                "approvalDate1stStep": null,
                "status1stStep": "ONHOLD",
                "note1stStep": null,
                "approvalDate2ndStep": null,
                "status2ndStep": null,
                "note2ndStep": null,
                "approvalDate3rdStep": null,
                "status3rdStep": null,
                "note3rdStep": null,
                "lastPossibleDate3rdStep": null,
                "approvalDate4thStep": null,
                "status4thStep": null,
                "note4thStep": null,
                "lastPossibleDate4thStep": null,
                "totalBeforeDiscount": 62055.0,
                "totalAfterDiscount": 52045.53,
                "username": "user_sogei",
                "productsOrderedDTOList": null,
                "deliveries": {
                    "id": 5,
                    "firstNameReferent": "Andrea",
                    "lastNameReferent": "Rossopinti",
                    "principal": true,
                    "address": "Roma -Via A. Soldati (La Rustica)",
                    "necessaryLicencePlate": false,
                    "licencePlate": "",
                    "ordersId": null
                },
                "simulatedOrderName": "5 - Andrea Rossopinti",
                "commitmentCode": null,
                "commitmentDescription": null,
                "warehouse": null
            }
        ],
        "pageable": {
            "sort": {
                "sorted": false,
                "unsorted": true,
                "empty": true
            },
            "offset": 0,
            "pageSize": 20,
            "pageNumber": 0,
            "unpaged": false,
            "paged": true
        },
        "last": true,
        "totalPages": 1,
        "totalElements": 5,
        "size": 20,
        "number": 0,
        "sort": {
            "sorted": false,
            "unsorted": true,
            "empty": true
        },
        "first": true,
        "numberOfElements": 5,
        "empty": false
    },
    showColumnsList: ['id', 'orderId', 'orderDate', 'status1stStep'],
    primaryKey: 'id'
}