import React, { useEffect, useState } from "react";
import {
    Button,
    TableCell,
    TableRow,
} from "@material-ui/core";
import TableDettaglioOrdiniSimulati from "./TableDettaglioOrdiniSimulati";
import { downloadExcelOrdineSimulato } from "../../api/simulatedOrders";
import { ISelectedProduct, ISimulatedOrder } from "../../utils/model_types";
import { findAllVendorsBuying } from "../../api/vendors";
import { convertEuro } from "../../routing/utils";
import { useDispatch } from "react-redux";
import { toggleSpinner } from "../../store/actionCreators/general";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { saveAs } from "file-saver";
import { useStyles } from "./style";
import { getSimulatedOrderById } from "../../api/simulatedOrders";
import { getSimulatedProductsOrderedBySimulatedOrderId } from "../../api/simulatedProductsOrdereds";

interface VendorI {
    "quantity": number,
    "budget": number,
    "accumulated": number,
    "orderDate": string,
    "totalPending": number,
    "vendorName": string
}

const tableHeaders = ["SKU","Categoria", "Brand", "Prezzo", "Prezzo Scontato","Quantità", "Totale"];

// interface IProps {
//     children: any,
//     title: string
// }

export default function DettaglioOrdine(props: any) {

    const [ordine, setOrdine] = useState<ISimulatedOrder>();
    const [productsOrdered, setProductsOrdered] = useState<ISelectedProduct[]>([]);
    const [vendors, setOrderVendors] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();

    // const storeUser = useSelector((state: RootState) => state.auth.user);
    // const auth = getRoles(storeUser);

    // const history = useHistory();

    // const routeTo = (routeName: string) => {
    //     history.push(routeName)
    // };

    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    useEffect(() => {
        const id = props.match.params.id;
        getSimulatedOrderById(id).then(({data}) => {
            setOrdine(data);
            getSimulatedProductsOrderedBySimulatedOrderId(id).then(({data}) => {
                setProductsOrdered(data.map((d: any) => ({...d, productType: d.productType.type})));
                // @ts-ignore
                const vendors = [...new Set(data.map((v: { brand: string; }) => v.brand))];
                findAllVendorsBuying()
                    .then(res => {
                        // @ts-ignore
                        setOrderVendors(res.data.filter(v => vendors.includes(v.vendorName)));
                    })
                    .catch()
            });
        });
    }, [props.match.params.id]);

    function exportOrder() {
        dispatch(toggleSpinner());
        downloadExcelOrdineSimulato(ordine?.id)
            .then(res => {
                dispatch(toggleSpinner());
                saveAs(res.data, `Preventivo fornitura ${ordine?.simulatedOrderName}.xlsx`)
            });
    }

    return (
        <div style={{backgroundColor: 'white', padding: '20px'}}>
            <p style={{textAlign: 'center', fontSize: '18px'}}>{ordine?.simulatedOrderName}</p>
            <div>
                <h4>Note:</h4>
                <span>{ordine?.note}</span>
            </div>
            <div style={{marginTop: "50px", width: '100%'}}>
                <h3>Lista Prodotti
                    <Button className={classes.exportOrdine} variant="contained" color="primary" onClick={exportOrder}>
                        Esporta preventivo
                    </Button>
                </h3>
                <TableDettaglioOrdiniSimulati tableHeaders={tableHeaders}>
                    {
                        productsOrdered && productsOrdered.map(product => {
                            return (
                                <TableRow key={product.sku}>
                                    <TableCell>{product.sku}</TableCell>
                                    <TableCell>{product.productType}</TableCell>
                                    <TableCell>{product.brand}</TableCell>
                                    <TableCell>{convertEuro(Number(product.price))}</TableCell>
                                    <TableCell>{convertEuro(Number(product.discountPrice))}</TableCell>
                                    <TableCell>{product.quantity}</TableCell>
                                    <TableCell>{convertEuro(Number(product.discountPrice) * product.quantity)}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    <React.Fragment>
                        <TableRow onClick={handleClick} style={{backgroundColor: '#F5F5F5'}}>
                            <TableCell colSpan={5}><b>TOTALI</b></TableCell>
                            <TableCell><b>{productsOrdered.reduce((tot, el) => tot + el.quantity, 0)}</b></TableCell>
                            <TableCell>
                                <b>{convertEuro(productsOrdered.reduce((tot, el) => tot + Number(el.discountPrice) * el.quantity, 0))}</b>
                                {open ? <ExpandLess style={{float: 'right', cursor: 'pointer'}}/> :
                                    <ExpandMore style={{float: 'right', cursor: 'pointer'}}/>}
                            </TableCell>
                        </TableRow>
                        {open && vendors.map((vendor: VendorI) => (
                            <TableRow key={vendor.vendorName}>
                                <TableCell style={{border: 0}} colSpan={2}/>
                                <TableCell colSpan={3}><b>{vendor.vendorName}</b></TableCell>
                                <TableCell/>
                                <TableCell>
                                    {convertEuro(productsOrdered.filter((product: ISelectedProduct) => product.brand === vendor.vendorName).reduce((tot, el) => tot + Number(el.discountPrice) * el.quantity, 0))}
                                    {/* {convertEuro(productsOrdered.reduce((tot, el) => tot + Math.round(el.quantity * Number(el.price) * 100) / 100, 0))}*/}
                                </TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>

                </TableDettaglioOrdiniSimulati>
            </div>
        </div>
    )
};

