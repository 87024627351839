export type EmailBox = {
    isOpenEmailBox: boolean
}

const initialState: EmailBox = {
    isOpenEmailBox: false
}

export type EmailBoxAction = {
    type: string
    payload: EmailBox
}

export const TOGGLE_EMAIL_BOX = 'OPEN_EMAIL_BOX'

const emailBoxReducer = (state = initialState, action: EmailBoxAction) => {
    switch (action.type) {
        case TOGGLE_EMAIL_BOX:
            const {payload: {isOpenEmailBox}} = action

            return {
                ...state,
                isOpenEmailBox
            }
        default:
            return state
    }
}

export default emailBoxReducer