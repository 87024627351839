import React, {useState} from 'react';
import {InputLabel, makeStyles, Modal, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {uploadSimulatedOrders} from "../../api/simulatedOrders";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {SIMULATED_ORDER_UPLOAD_ERROR, SIMULATED_ORDER_UPLOAD_SUCCESS} from "../../store/actions/general";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import TextField from "@material-ui/core/TextField";

const useStyle = makeStyles((theme) => ({
    importFileContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}))

export default function ImportaPreventivo() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyle();
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [nomePreventivo, setNomePreventivo] = useState<string>('');
    const [openModaleErrori, setOpenModaleErrori] = useState<any>(null);

    function uploadFile() {
        const {files} = selectedFile;
        let body = new FormData();
        body.append('file', files[0]);
        body.append('name', nomePreventivo || files[0].name);

        dispatch(toggleSpinner());
        uploadSimulatedOrders(body).then((response) => {
            toggleToast('success', SIMULATED_ORDER_UPLOAD_SUCCESS);
            dispatch(toggleSpinner());
            history.push('preventivi');
        }).catch((err) => {
            if (err.messaggio.status && err.messaggio.status === 406) {
                setOpenModaleErrori(err.messaggio.data)
            } else if (err.messaggio.status && err.messaggio.status === 422) {
                toggleToast('error', err.messaggio.data);
            } else {
                toggleToast('error', SIMULATED_ORDER_UPLOAD_ERROR);
            }
            dispatch(toggleSpinner());
        });
    }

    function handleChangeNomePreventivo(e: any) {
        const value = e.target.value;
        setNomePreventivo(value);
    }

    return (
        <div>
            <div>
                <TextField id="nome-preventivo"
                           variant="outlined"
                           style={{width: 250}}
                           label="Nome preventivo"
                           value={nomePreventivo}
                           onChange={handleChangeNomePreventivo}
                />
            </div>
            <div style={{marginTop: '20px'}}>
                <InputLabel>Seleziona file da importare</InputLabel>
                <div className={classes.importFileContainer}>
                    <input
                        style={{display: 'block', marginRight: '10px'}}
                        type='file'
                        accept='.xls, .xlsx'
                        onChange={(e) => setSelectedFile(e.target)}
                    />
                    {
                        selectedFile &&
                        <label className="m-0 btn btn-sm btn-outline-primary" onClick={() => uploadFile()}>
                            <i className="fas fa-cloud-upload-alt"/> Importa
                        </label>
                    }
                </div>
            </div>
            {openModaleErrori && <Modal open={!!openModaleErrori}
                                        onClose={() => setOpenModaleErrori(null)}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        className={classes.modal}>
                <div className={classes.paper}>
                    Errori trovati:
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{minWidth: 250}}>Riga del file</TableCell>
                                <TableCell align="left" style={{minWidth: 250}}>Messaggio di errore</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.entries(openModaleErrori).map((error: any) => {
                                    return <TableRow key={error[0]}>
                                        <TableCell align="left">{error[0]}</TableCell>
                                        <TableCell align="left">{error[1]}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            </Modal>}
        </div>
    )
}
