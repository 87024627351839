import axiosInstance from "./interceptor";
import {IVendors} from "../utils/model_types";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/vendors`;

export const findAllVendors = () => axiosInstance.get(resource);

export const findAllVendorsBuying = () => axiosInstance.get(`${resource}/spese-fornitori`);

export const changeBudgets = (vendors: IVendors[]) => axiosInstance.put(`${REACT_APP_BASENAME}/multi/vendors`, vendors, {
    headers: {'Content-Type': 'application/json'}
});
