import React from 'react'
import { IPaginatonProps } from '..'
import { Next, Previous } from '../../Icons'
import styles from '../DynamicTable.module.css'

const DynamicTableFooter: React.FC<IPaginatonProps> = (props) => {
    const {
        rowsPerPageOptions, totalElements, number, size,
        handleChangeRowsPerPage, handleClickNextPage,
        handleClickPrevPage, last, first
    } = props

    const start = number * size + 1;
    const end = Math.min(start + size - 1, totalElements);

    return (
        <div
            className={styles.pagination}
        >
            <div className={styles.rowsPerPage}>
                <span>
                    Righe per pagina:
                </span>
                <select
                    onChange={(e) => handleChangeRowsPerPage && handleChangeRowsPerPage(Number(e.target.value))}
                >
                    {
                        rowsPerPageOptions?.map(o => <option value={o} key={o}>{o}</option>)
                    }
                </select>
            </div>
            <div>
                <span>
                    {start || ''}-{end || ''} di {totalElements}
                </span>
            </div>
            <div>
                <button
                    disabled={first}
                    onClick={() => handleClickPrevPage && handleClickPrevPage()}
                >
                    <Previous />
                </button>
                <button
                    disabled={last}
                    onClick={() => handleClickNextPage && handleClickNextPage()}
                >
                    <Next />
                </button>
            </div>
        </div>
    )
}

export default DynamicTableFooter