import {IProduct, ISelectedProduct} from "./model_types";
import moment from 'moment';
import {USP, UTBOP} from "../store/actions/general";

export const quantityChangeHandler = (event: any, selectedRows: ISelectedProduct[], setSelectedRows: Function, index: number, rowData: IProduct[], setRowData: Function) => {
    let updatedSelection = [...selectedRows];
    updatedSelection[index].quantity = Math.max(parseFloat(event.target.value), 0);
    setSelectedRows(updatedSelection);
    if (event.target.value === '0') {
        updatedSelection.splice(index, 1);
        setSelectedRows(updatedSelection)
        const selectedRowIdx = rowData.findIndex(el => el.sku === event.target.id);
        // eslint-disable-next-line
        if (selectedRowIdx == -1) return;
        let newData = [...rowData];
        newData[selectedRowIdx].checked = false;
        setRowData(newData);
    }
};

export const changeServiceStart = (e: any, selectedRows: ISelectedProduct[], setSelectedRows: Function) => {
    let updatedSelection = [...selectedRows];
    const selectedRowIdx = updatedSelection.findIndex(el => el.sku === e.target.id);
    updatedSelection[selectedRowIdx].serviceStart = e.target.value;
    setSelectedRows(updatedSelection)
};

export const handleChangeNote = (value: string, index: number, selectedRows: ISelectedProduct[], setSelectedRows: Function) => {
    const updatedSelection = [...selectedRows]
    updatedSelection[index].note = value
    setSelectedRows(updatedSelection)
}

export const changeValidityPeriod = (e: any, selectedRows: ISelectedProduct[], setSelectedRows: Function) => {
    let updatedSelection = [...selectedRows];
    const selectedRowIdx = updatedSelection.findIndex(el => el.sku === e.target.id);
    updatedSelection[selectedRowIdx].validityPeriod = e.target.value;
    setSelectedRows(updatedSelection)
};

export const changeValidityUnit = (e: any, selectedRows: ISelectedProduct[], setSelectedRows: Function) => {
    let updatedSelection = [...selectedRows];
    const selectedRowIdx = updatedSelection.findIndex(el => el.sku === e.target.name);
    updatedSelection[selectedRowIdx].validityUnit = e.target.value;
    setSelectedRows(updatedSelection)
};

export const handleChangeDestination = (value: any, selectedRows: ISelectedProduct[], setSelectedRows: Function, index: number) => {
    let updatedSelection = [...selectedRows];
    if (value && value.id && value.address) {
        updatedSelection[index].destinationsId = value.id !== 0 ? value.id : null;
        updatedSelection[index].destinationsAddress = value.address;
    } else {
        updatedSelection[index].destinationsAddress = value;
        updatedSelection[index].destinationsId = 0;
    }
    setSelectedRows(updatedSelection)
};

declare global {
    interface Array<T> {
        findValuesInArray(valuesArray: string[] | number []): boolean;
    }
}

// eslint-disable-next-line
Array.prototype.findValuesInArray = function (valuesArray: string[] | number []) {
    for (let i = 0; i < this.length; i++)
        //@ts-ignore
        if (valuesArray.indexOf(this[i]) > -1) return true;
    return false;
}

export const daysBetweenTwoDates = (start?: string, end?: string) => {
    const isValidStartDate = Date.parse(start || '') > 0
    const isValidEndDate = Date.parse(end || '') > 0

    if (!start && !end) {
        return 0;
    }


    if (!isValidStartDate || !isValidEndDate) {
        return -1;
    }

    if (isValidEndDate && isValidStartDate) {
        const startDate = moment(start)
        const endDate = moment(end)

        return endDate.diff(startDate, 'days') > 0 ? endDate.diff(startDate, 'days') : -1;
    }

    return -1;
}

export const checkServiceDateValuesIsNotNull = (row: ISelectedProduct) => {
    return !!((row.serviceStart && !row.serviceEnd) || (row.serviceEnd && !row.serviceStart));

}

export const nanoid = (size = 21) => {
    let id = ''
    let bytes = crypto.getRandomValues(new Uint8Array(size))

    // A compact alternative for `for (var i = 0; i < step; i++)`.
    while (size--) {
        // It is incorrect to use bytes exceeding the alphabet size.
        // The following mask reduces the random byte in the 0-255 value
        // range to the 0-63 value range. Therefore, adding hacks, such
        // as empty string fallback or magic numbers, is unneccessary because
        // the bitmask trims bytes down to the alphabet size.
        let byte = bytes[size] & 63;
        if (byte < 36) {
            // `0-9a-z`
            id += byte.toString(36);
        } else if (byte < 62) {
            // `A-Z`
            id += (byte - 26).toString(36).toUpperCase();
        } else if (byte < 63) {
            id += '_'
        } else {
            id += '-'
        }
    }
    return id;
}

export const renderElementByRole = (auth: string[], renderEl = true) => {
    return (el: JSX.Element) => {
        if ((auth.includes(UTBOP) || auth.includes(USP)) || renderEl) {
            return el
        }

        return null
    }
}

export const downloadFile = (data: any, fileName: string, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a")
    a.style.display = "none"
    document.body.appendChild(a)

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(data)

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click()

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a)
}
