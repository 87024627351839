import {IChannel} from "../../utils/model_types";
import {ChannelAction} from "../actions/types";
import {SET_CHANNEL} from "../actions/channels";

export interface channelStateI {
    channelList: IChannel[]
}

const channelState: channelStateI = {
    channelList: []
}

export default function channelReducer(state = channelState, action: ChannelAction) {
    switch (action.type) {
        case SET_CHANNEL:
            return {
                ...state,
                channelList: [...action.payload]
            }
        default:
            return state;
    }
}