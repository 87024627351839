import axiosInstance from "./interceptor";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/order-documents`;

export const createOrderDocuments = async (data: object) => {
  return await axiosInstance.post(resource, data, {
    headers: {'Content-Type': 'application/json'}
  })
};

export const getOrderDocuments = async (filters: string) => {
  return await axiosInstance.get(filters? `${resource}?${filters}` : resource);
};

export const deleteOrderDocument = async (id:number) => {
  return await axiosInstance.delete( `${resource}/${id}`);
};
