import {
    AddBox,
    Euro,
    Dashboard,
    FormatListBulleted,
    People,
    TrendingUp,
    Business,
    BusinessCenter,
    Description,
    Folder,
    CloudUpload
} from '@material-ui/icons';

import {Fragment} from 'react';
import {IRoute} from './types';
import {US, USP, UTBO, UTBOP, PROGETTISTA, USS} from "../store/actions/general";
import Login from '../pages/Login/Login';
import UnimplementedRoute from '../pages/Unimplemented/Unimplemented';
import NuovoOrdine from '../pages/NuovoOrdine/NuovoOrdine';
import ListaOrdini from '../pages/ListaOrdini/ListaOrdini';
import Monitoraggio from '../pages/Monitoraggio/Monitoraggio';
import GestioneUtenti from '../pages/GestioneUtenti/GestioneUtenti';
import DettaglioOrdine from '../pages/DettaglioOrdine/DettaglioOrdine';
import NuovoUtente from "../pages/NuovoUtente/NuovoUtente";
import AreaPersonale from "../pages/AreaPersonale/AreaPersonale";
import Listino from "../pages/Listino/Listino";
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Preventivi from '../pages/Preventivi/Preventivi';
import NuovoOrdineSimulato from "../pages/NuovoOrdineSimulato/NuovoOrdineSimulato";
import DettaglioOrdineSimulato from "../pages/DettaglioOrdineSimulato/DettaglioOrdineSimulato";
import ModulazioneBudget from "../pages/ModulazioneBudget/ModulazioneBudget";

import GestioneDestinazioni from '../pages/GestioneDestinazioni'
import GestioneCommesse from '../pages/GestioneCommesse';
import Listings from "../pages/Listings";
import Assets from "../pages/Assets";
import ImportAcronym from "../pages/ImportAcronym/ImportAcronym";
import ImportaPreventivo from "../pages/ImportaPreventivo/ImportaPreventivo";

/*
 TIPOLOGIE DI ACCESSO
    0: visibile a tutti gli utenti (non autenticati, autenticati e autenticati con privilegi)
    1: visibile solo agli utenti non autenticati
    2: visibile a tutti utenti autenticati
    3: visibile solo agli utenti autenticati con un determinato ruolo
        In caso di accesso di tipo 3 bisogna indicare nell'array "roles" quali possono accedere alla pagina.
        Il tipo di dato da inserire all'interno di "roles" può essere di qualsiasi tipo (numero, stringa ecc.)

 Per impostare un path nei route senza farlo comparire nei link basta mettere il name come stringa vuota
 */

/* ========== Components attached to routes ==========
        ATTENZIONE! Usare sempre il lazy loading
*/
// const Login = React.lazy(() => import('../pages/Login/Login'));
// const UnimplementedRoute = React.lazy(() => import('../pages/Unimplemented/Unimplemented'));
// const NuovoOrdine = React.lazy(() => import('../pages/NuovoOrdine/NuovoOrdine'));
// const ListaOrdini = React.lazy(() => import('../pages/ListaOrdini/ListaOrdini'));
// const Monitoraggio = React.lazy(() => import('../pages/Monitoraggio/Monitoraggio'));
// const GestioneUtenti = React.lazy(() => import('../pages/GestioneUtenti/GestioneUtenti'));
// const GestioneSconti = React.lazy(() => import('../pages/GestioneSconti/GestioneSconti'));
// const DettaglioOrdine = React.lazy(() => import('../pages/DettaglioOrdine/DettaglioOrdine'));


// Array contente tutte le pagine con relative configurazioni

const defaultRoute: IRoute = {
    path: "/",
    name: "",
    isExternalPage: false,
    logo: {},
    component: Fragment,
    access: [2],
    private: false,
    roles: [],
    style: {},
    showInSidebar: true
};

export const routes: IRoute[] = [
    {
        ...defaultRoute,
        path: "/login",
        component: Login,
        access: [1],
        roles: []
    },
    {
        ...defaultRoute,
        name: "Nuovo ordine",
        path: "/creazione-ordine",
        logo: {icon: AddBox},
        component: NuovoOrdine,
        access: [3],
        roles: [US, PROGETTISTA, USP, UTBOP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Modifica ordine",
        path: "/modifica-ordine",
        logo: {icon: AddBox},
        component: NuovoOrdine,
        access: [3],
        roles: [US, USP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Listino",
        path: "/listino",
        logo: {icon: Euro},
        component: Listino,
        access: [2],
        roles: []
    },
    {
        ...defaultRoute,
        name: "Elenco ordini",
        path: "/lista-ordini",
        logo: {icon: FormatListBulleted},
        component: ListaOrdini,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, USS]
    },
    {
        ...defaultRoute,
        name: "Monitoraggio",
        path: "/dashboard",
        logo: {icon: Dashboard},
        component: Monitoraggio,
        access: [3],
        roles: [USP, UTBO, UTBOP, USS]
    },
    {
        ...defaultRoute,
        name: "Gestione utenti",
        path: "/gestione-utenti",
        logo: {icon: People},
        component: GestioneUtenti,
        access: [3],
        roles: [USP, UTBO, UTBOP, USS]
    },
    /*    {
            ...defaultRoute,
            name: "Gestione sconti",
            path: "/gestione-sconti",
            logo: {icon: AttachMoney},
            component: GestioneSconti,
            access: [3],
            roles: [USP, UTBO, UTBOP]
        },*/
    {
        ...defaultRoute,
        name: "Page not found",
        path: "/404",
        component: UnimplementedRoute,
        access: [0],
        roles: [],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Dettaglio Ordine",
        path: "/dettaglio-ordine/:id",
        component: DettaglioOrdine,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, USS],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Creazione utente",
        path: "/gestione-utenti/nuovo-utente",
        component: NuovoUtente,
        access: [3],
        roles: [USP, UTBOP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Modifica utente",
        path: "/gestione-utenti/modifica-utente/:id",
        component: NuovoUtente,
        access: [3],
        roles: [USP, UTBOP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Area personale",
        path: "/area-personale",
        logo: {icon: PersonIcon},
        component: AreaPersonale,
        access: [2],
        roles: [],
        showInSidebar: true
    },
    {
        ...defaultRoute,
        name: "Preventivi",
        path: "/preventivi",
        logo: {icon: ReceiptIcon},
        component: Preventivi,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, PROGETTISTA, USS]
    },
    {
        ...defaultRoute,
        name: "Nuovo preventivo",
        path: "/creazione-preventivo",
        logo: {icon: ReceiptIcon},
        component: NuovoOrdineSimulato,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, PROGETTISTA],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Importa preventivo",
        path: "/importa-preventivo",
        logo: {icon: ReceiptIcon},
        component: ImportaPreventivo,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, PROGETTISTA],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Dettaglio preventivo",
        path: "/dettaglio-preventivo/:id",
        component: DettaglioOrdineSimulato,
        access: [3],
        roles: [US, USP, UTBO, UTBOP, PROGETTISTA, USS],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Modifica preventivo",
        path: "/modifica-preventivo",
        component: NuovoOrdineSimulato,
        access: [3],
        roles: [PROGETTISTA, US, USP, UTBOP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Gestione Budget",
        path: "/gestione-budget",
        component: ModulazioneBudget,
        logo: {icon: TrendingUp},
        access: [3],
        roles: [USP, USS]
    },
    {
        ...defaultRoute,
        name: "Gestione Commesse",
        path: "/gestione-commesse",
        component: GestioneCommesse,
        logo: {icon: BusinessCenter},
        access: [3],
        roles: [USP, UTBOP]
    },
    {
        ...defaultRoute,
        name: "Gestione Destinazioni",
        path: "/gestione-destinazioni",
        component: GestioneDestinazioni,
        logo: {icon: Business},
        access: [3],
        roles: [USP, UTBOP]
    },
    {
        ...defaultRoute,
        name: "Gestione Listini",
        path: "/listings",
        component: Listings,
        logo: {icon: Description},
        access: [3],
        roles: [USP, UTBOP]
    },
    {
        ...defaultRoute,
        name: "Asset Sogei",
        path: "/assets",
        component: Assets,
        logo: {icon: Folder},
        access: [3],
        roles: [UTBOP]
    },
    {
        ...defaultRoute,
        name: "Import Acronimo",
        path: "/import-acronimo",
        component: ImportAcronym,
        logo: {icon: CloudUpload},
        access: [3],
        roles: [UTBOP]
    }
    /*    {
            ...defaultRoute,
            name: "Gestione sedi secondarie Sogei",
            path:"/gestionesedisecondarie",
            component: GestioneSediSecondarie,
            logo: {icon: Business},
            access:[3],
            roles: [USP,UTBOP],
            showInSidebar: false
        },
        {
            ...defaultRoute,
            name: "Gestione clienti Sogei",
            path:"/gestioneclientisogei",
            component: GestioneClientiSogei,
            logo: {icon: People},
            access: [3],
            roles: [USP,UTBOP],
            showInSidebar: true
        }*/
];
