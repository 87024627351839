import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    loginContainer: {
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
    },

    loginForm: {
        display: "flex",
        flexDirection: "column",
        height: 400,
        width: 320,
        justifyContent: "space-between",
        alignItems: "center",
        padding: 25
    },

    formLogo: {
        width: 100,
        height: 100
    },

    formControl: {
        width: "100%"
    }
}));