import React, {useCallback, useEffect, useState} from "react"
import {makeStyles, TablePagination} from "@material-ui/core";
import {ColDef, DataGrid} from "@material-ui/data-grid";
import {useDispatch} from "react-redux";
import TextField from "@material-ui/core/TextField";

import {ROWS_PER_PAGE, updatePagination} from "../../store/actions/general";
import {IPagination} from "../../utils/model_types";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {HttpStatusCode} from "../../utils/protocols/http";
import {IAsset, searchAsset} from "../../api/assets";

const useStyle = makeStyles({
    formControl: {
        width: "100%",
        marginBottom: 20,
    },
    dataGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 1252,
        justifyContent: 'flex-end',
        margin: 'auto'
    },
    table: {},
    formCard: {
        padding: 25
    },
    formContainer: {
        paddingRight: 25,
        paddingLeft: 25,
        width: 500
    },
    addButton: {
        float: 'right',
        marginBottom: '20px'
    },
    filterInput: {
        width: 420,
        marginBottom: 15,
        marginRight: 10
    }
})

const columns: ColDef[] = [
    {field: 'contract', headerName: 'Repertorio contratto', width: 200},
    {field: 'ordersId', headerName: 'Numero Ordine', width: 150},
    {field: 'acronym', headerName: 'Acronimo'},
    {field: 'owner', headerName: 'Owner'},
    {field: 'type', headerName: 'Tipologia'},
    {field: 'vendor', headerName: 'Produttore', width: 150},
    {field: 'model', headerName: 'Modello', width: 150},
    {field: 'sku', headerName: 'Serial Number', width: 150},
    {field: 'description', headerName: 'Descrizione', width: 250},
    {field: 'quantity', headerName: 'Q.tà'},
]

const Assets = () => {
    const classes = useStyle();
    const [rowData, setRowData] = useState<IAsset[]>([]);
    const [skuSearchValue, setSkuSearchValue] = useState('');
    const [orderIdSearchValue, setOrderIdSearchValue] = useState('');
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});

    const dispatch = useDispatch();

    const getAssetsWithOptionalFilters = useCallback(() => {
        dispatch(toggleSpinner())
        searchAsset({sku: skuSearchValue, orderId: orderIdSearchValue, pagination})
            .then(res => {
                if (res.status === HttpStatusCode.ok) {
                    const paginationHeaderLink = res.headers.link.split(',');
                    const totalCount = res.headers["x-total-count"];

                    setPagination(updatePagination(pagination, paginationHeaderLink, totalCount))
                    setRowData(res.data)
                }
            })
            .catch(e => toggleToast('error', e.messaggio))
            .finally(() => dispatch(toggleSpinner()))
    }, [dispatch, pagination])

    useEffect(() => {
        getAssetsWithOptionalFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize, skuSearchValue, orderIdSearchValue]);

    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = ({target: {value}}: any) => {
        const pageSize =Number(value);
        setPagination({...pagination, page: 0, pageSize})
    };

    return (
        <>
            <TextField
                value={skuSearchValue}
                label="Cerca per Serial Number"
                variant="outlined"
                className={classes.filterInput}
                onChange={e => {
                    setPagination({...pagination, page: 0});
                    setSkuSearchValue(e.target.value)
                }}
            />
            <TextField
                value={orderIdSearchValue}
                label="Cerca per Numero Ordine"
                variant="outlined"
                className={classes.filterInput}
                onChange={e => {
                    setPagination({...pagination, page: 0});
                    setOrderIdSearchValue(e.target.value)
                }}
            />
            <DataGrid
                autoHeight
                className={classes.table}
                rows={rowData}
                columns={columns}
                showColumnRightBorder
                hideFooter
            />
            <TablePagination
                component="div"
                count={pagination.rowCount || 0}
                page={pagination.page}
                onChangePage={handleChangePage}
                rowsPerPage={pagination.pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE}
            />
        </>
    );
}

export default Assets
