import React from 'react'
import {
    useDynamicTable,
    useCommandBar,
    DynamicTableProps,
    DynamicTableHeader,
    DynamicTableBody,
    DynamicTableFooter,
    CommandBar,
} from './'
import styles from './DynamicTable.module.css'

const DynamicTable: React.FC<DynamicTableProps> = (props) => {
    const { labelCreate } = props
    const {
        dtColumns, dtRows, dtPrimaryKey, dtPagination, elRefs,
        dtHandleDeleteRow, dtHandleEditRow, dtHandleSaveEditRow,
        dtAddRow, dtHandleCancel
    } = useDynamicTable(props)

    const { dtHandleCreate } = useCommandBar({ dtAddRow, dtColumns, dtPrimaryKey })

    return (
        <div className={styles.dynamicTableContainer}>
            <CommandBar
                labelCreate={labelCreate}
                handleCreate={dtHandleCreate}
            />
            <div className={styles.tableContainer}>
                <DynamicTableHeader
                    columns={dtColumns}
                />
                <DynamicTableBody
                    elRefs={elRefs.current}
                    editableColumns={dtColumns.filter(c => c !== dtPrimaryKey)}
                    columns={dtColumns}
                    dtPrimaryKey={dtPrimaryKey}
                    handleDelete={dtHandleDeleteRow}
                    handleEdit={dtHandleEditRow}
                    handleCancel={dtHandleCancel}
                    handleSaveEdit={dtHandleSaveEditRow}
                    rows={dtRows}
                />
                <DynamicTableFooter
                    {...dtPagination}
                />
            </div>
        </div>
    )
}

export default DynamicTable
