import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core"
import { ExitToApp } from "@material-ui/icons"
import IconButton from '@material-ui/core/IconButton';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import React from "react"
import clsx from 'clsx';
import { Menu } from "@material-ui/icons"
import {useDispatch} from "react-redux";
import { logout } from "../../store/actionCreators/auth";
import {EMAIL_BOX} from "../../utils/enums";
import {openEmailBox} from "../../store/actionCreators/email-box";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
}));

interface IHeader {
    open: boolean
    handleDrawerOpen: () => void
}

const portalTitle = 'Portale forniture Sogei';

const Header = ({ open, handleDrawerOpen }: IHeader) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const handleLogout = () => dispatch(logout());

    const handleOpenEmailBox = () => dispatch(openEmailBox())

    return (
        <AppBar color="default" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <Menu />

                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {portalTitle}
                </Typography>

                <IconButton
                    color="inherit"
                    aria-label="contact"
                    onClick={handleOpenEmailBox}
                    title={EMAIL_BOX.title}
                >
                    <ContactMailIcon />
                </IconButton>

                <IconButton color="inherit" aria-label="logout" onClick={handleLogout}>
                    <ExitToApp />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
};

export default Header;
