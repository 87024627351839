import axiosInstance from "./interceptor";
import {IPagination, IParams} from "../utils/model_types";
import {HttpStatusCode} from "../utils/protocols/http";
import {toggleToast} from "../store/actionCreators/general";
import {DOCUMENT_UPLOAD_ERROR, DOCUMENT_UPLOAD_SUCCESS} from "../store/actions/general";

const {REACT_APP_BASENAME} = process.env;

const resource = `${REACT_APP_BASENAME}/orders`;

export const createOrder = (data: Object) => {
    return axiosInstance.post(resource, data, {
        headers: {'Content-Type': 'application/json'}
    })
};

export const getOrdersList = (params: IParams[], pagination: IPagination) => {
    const parameters = params?.map(param => `${param.key}.${param.operator}=${param.value}`);
    const deletedParameter = `deleted.equals=false`;
    const sortParameter = 'sort=orderDate,desc&sort=id';
    const paginationParameter = `page=${pagination.page}&size=${pagination.pageSize}`;
    return axiosInstance.get(`${resource}?${paginationParameter}&${sortParameter}&${parameters}&${deletedParameter}`);
};

export const getOrderById = (id: number) => {
    return axiosInstance.get(`${resource}/${id}`);
};

export const modifyOrder = (data: Object, step: Number) => {
    return axiosInstance.put(`${resource}/step/${step}`, data);
};

export const modifyOrderBasic = (data: Object) => {
    return axiosInstance.put(`${resource}/`, data);
};

export const getOrderByOrderName = (orderName: string) => {
    return axiosInstance.get(`${resource}?simulatedOrderName.contains=${orderName}`);
};

export const getOrderByOwner = (owner: string, pagination: IPagination) => {
    const paginationParameter = `page=${pagination.page}&size=${pagination.pageSize}`;
    const sortParameter = 'sort=orderDate,desc&sort=id';
    return axiosInstance.get(`${resource}?${paginationParameter}&${sortParameter}&owner.equals=${owner}`);
};

export const getOrderWithFilters = ({pagination, orderName, owner, channel}: {pagination: IPagination, orderName?: string, owner?: string, channel?: string}) => {
    const deletedParameter = `deleted.equals=false`;
    const paginationParameter = `page=${pagination.page}&size=${pagination.pageSize}`;
    const sortParameter = 'sort=orderDate,desc&sort=id';
    let url = `${resource}?${deletedParameter}&${paginationParameter}&${sortParameter}`;
    if (orderName) {
        url = url.concat(`&simulatedOrderName.contains=${orderName}`)
    }
    if (owner) {
        url = url.concat(`&owner.equals=${owner}`)
    }
    if (channel) {
        url = url.concat(`&channelId.equals=${channel}`)
    }
    return axiosInstance.get(`${url}`);
};

export const downloadExcelOrdine = (id: number | undefined) => {
    return axiosInstance.get(`${resource}/download-excel/${id}`, {
        responseType: 'blob'
    })
};

export const sendMail = (orderId: string | undefined, data: Object) => {
    return axiosInstance.post(`${resource}/${orderId}/mail`, data, {
        headers: {'Content-Type': 'application/json'}
    });
};

export const sendTradeInDiscountMail = (orderId: string | undefined) => {
    return axiosInstance.post(`${resource}/${orderId}/mailtradein`, {}, {
        headers: {'Content-Type': 'application/json'}
    });
};


export const approveOperationPlans = (data: object) => {
    return axiosInstance.put(`${resource}/approve-operation-plan`, data, {
        headers: {'Content-Type': 'application/json'}
    })
};

export const switchCloseOrder = (id: number, orderClosed: boolean) => {
    return axiosInstance.put(`${resource}/switchclose/${id}`, {orderClosed});
};

export const exportListaOrdini = (auth: any) => {
    const userRole = auth[0];
    const username = localStorage.getItem('username')

    return axiosInstance.post(`${resource}/download-all`, {
            userRole,
            username
        }, {responseType: 'blob'});
};

export const uploadAcronymFileService = (data: object) => {
    return axiosInstance.post(`${resource}/acronym/load`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export const uploadAcronymFile = async (obj: any) => {
    try {
        const resp = await uploadAcronymFileService(obj)
        if (resp.status === HttpStatusCode.ok) {
            toggleToast('success', DOCUMENT_UPLOAD_SUCCESS.replace('Documento', 'File'))
        }
    } catch (e) {
        toggleToast('error', DOCUMENT_UPLOAD_ERROR.replace('del documento', 'del file'))
    }
}

export const findChannelsPerVendor = (channel: string) => axiosInstance.get(`${resource}/channel/${channel}`);
export const findCategoriesPerVendor = (typeExpense: string) => axiosInstance.get(`${resource}/type/${typeExpense}`);