import React, {createRef, memo, useEffect, useState} from 'react';
import {makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {convertEuro, getRoles} from "../../routing/utils";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {changeBudgets, findAllVendors} from "../../api/vendors";
import {AxiosResponse} from "axios";
import {IVendors} from "../../utils/model_types";
import {useDispatch, useSelector} from "react-redux";
import './ModulazioneBudget.css'
import {RootState} from "../../store/reducers";
import {USS} from "../../store/actions/general";

const useStyle = makeStyles({
    subTitle: {
        marginBottom: 20
    },
    table: {
        minWidth: 800,
    },
});

const ModulazioneBudget = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [verdorsBudget, setVendorsBudget] = useState([]);
    const [verdorsChangedBudget, setVendorsChangedBudget] = useState<IVendors[]>([]);
    const elRefs = React.useRef([]);

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);

    useEffect(() => {
        dispatch(toggleSpinner());
        findAllVendors().then((response: AxiosResponse) => {

            setVendorsBudget(response.data)
            setVendorsChangedBudget(response.data)
            dispatch(toggleSpinner());

        }).catch((e: any) => {
            dispatch(toggleSpinner());
            toggleToast('error', e.messaggio)
        })
        // eslint-disable-next-line
    }, [])

    const changeBudget = (index: number, newBudget: any) => {
        const value = parseFloat(newBudget.current.value);
        setVendorsChangedBudget(budgets => budgets.map((b, i) => (i === index && value <= b.maxBudget - b.budget) ? {...b, budget: b.budget + value} : {...b}))
    }

    const delta = verdorsBudget.reduce((tot, v: IVendors) => tot + v.budget, 0) - verdorsChangedBudget.reduce((tot, v: IVendors) => tot + v.budget, 0);

    const saveBudgets = async () => {
        if (delta !== 0) return;

        dispatch(toggleSpinner())
        try {
            await changeBudgets(verdorsChangedBudget);
            dispatch(toggleToast('success', 'Budget aggiornati con successo'))
        } catch (e) {
            dispatch(toggleToast('error', e))
        } finally {
            dispatch(toggleSpinner())
        }

    }

    const resetBudgets = () => setVendorsChangedBudget(verdorsBudget);

    elRefs.current = verdorsBudget.map((_, i) => elRefs.current[i] || createRef())

    return (
        <div>
            <TableContainer component={Paper} style={{margin: '0 auto', width: 800}}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome Fornitore</TableCell>
                            <TableCell align="right">Budget</TableCell>
                            <TableCell align="right">Budget massimo</TableCell>
                            <TableCell align="right">Budget provvisorio</TableCell>
                            {!auth.includes(USS) && (
                                <TableCell align="center">Azioni</TableCell>
                            )}</TableRow>
                    </TableHead>
                    <TableBody>
                        {verdorsBudget.map((s: IVendors, index) => {
                            return (
                                <TableRow key={index} className={verdorsChangedBudget[index]?.budget === s.budget ? 'bg-white' : 'bg-warning'}>
                                    <TableCell component="th" scope="row">{s.name}</TableCell>
                                    <TableCell align="right">{convertEuro(s.budget)}</TableCell>
                                    <TableCell align="right">{convertEuro(s.maxBudget)}</TableCell>
                                    <TableCell align="right">{convertEuro(verdorsChangedBudget[index]?.budget)}</TableCell>
                                    {!auth.includes(USS) && (
                                        <TableCell align="center" style={{width: '200px'}}>
                                            <input type="number"
                                                   max={s.maxBudget - s.budget}
                                                   style={{width: '85px'}}
                                                   ref={elRefs.current[index]}
                                                   defaultValue={(verdorsChangedBudget[index]?.budget - s.budget) || 0}/>
                                            <div className="ml-2 d-inline-block align-items-center">
                                                <span onClick={() => changeBudget(index, elRefs.current[index])} className="btn btn-sm btn-success py-0 px-1"><i className="fas fa-save"/></span>
                                            </div>
                                        </TableCell>
                                    )}

                                </TableRow>
                            )
                        })}
                    </TableBody>
                    {!auth.includes(USS) && (
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} align="right">
                                    Budget residuo: <span className={[delta !== 0 && 'text-danger'].filter(Boolean).join(' ')}>{convertEuro(delta)}</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}</Table>
                {!auth.includes(USS) && (<div className="p-2 text-right">
                    <button className="btn btn-sm btn-danger" onClick={resetBudgets}>Annulla</button>
                    <button className={`btn btn-sm ml-1 ${delta === 0 ? 'btn-success' : 'btn-secondary disabled'}`} onClick={saveBudgets}>Salva</button>
                </div>)}
            </TableContainer>
        </div>
    )
}

export default memo(ModulazioneBudget);
