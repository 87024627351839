import { useCallback } from 'react'
import { IObjectKeyValue, DtColumns } from '..'

type Props = {
    dtAddRow: (row: IObjectKeyValue) => void
    dtColumns: DtColumns
    dtPrimaryKey: string
}

const useCommandBar = (props: Props) => {
    const { dtAddRow, dtColumns, dtPrimaryKey } = props

    const dtHandleCreate = useCallback(() => {
        let row = {}
        dtColumns.forEach(c => {
            if (c === dtPrimaryKey) row[c] = 0
            else
                row[c] = ''
        })
        dtAddRow(row)
    }, [dtPrimaryKey, dtColumns, dtAddRow])

    return { dtHandleCreate }
}

export default useCommandBar