import React, { useEffect, useState } from "react";
import {getUserByUsername, updateUserLogged} from "../../api/users";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    FormControl,
    Input,
    InputLabel,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { getRoles } from "../../routing/utils";
import { toggleSpinner, toggleToast } from "../../store/actionCreators/general";
import CreateIcon from "@material-ui/icons/Create";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FullName} from "../NuovoUtente/NuovoUtente";

const useStyle = makeStyles((theme) => ({
    formControl: {
        width: "50%",
        marginBottom: 20,
        padding: 20
    },
    formCard: {
        padding: 25
    },

    label: {
        left: 20
    },

    formContainer: {
        paddingRight: 25,
        paddingLeft: 25,
        width: '800px',
        margin: '0 auto'

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function AreaPersonale() {

    const [user, setUser] = useState<any>({});
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [ruolo, setRuolo] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [fullName, setFullName] = useState<FullName>({firstName: '', lastName: ''})
    const classes = useStyle();
    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleSpinner());
        const username = localStorage.getItem('username');
        getUserByUsername(username || '').then(res => {
            const userLogged = res.data[0];
            setUser(userLogged);
            setUsername(userLogged.username);
            setEmail(userLogged.email);
            setFullName({firstName: userLogged.firstName, lastName: userLogged.lastName})
            setRuolo(auth[0]);
            dispatch(toggleSpinner());
        }).catch(e => {
            toggleToast('error', e.messaggio)
            dispatch(toggleSpinner());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateEmail = (email: string) => {
        /* eslint-disable-next-line */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password: string, confirmPassword: string) => {
        if (password === confirmPassword) {
            return true
        }
    };

    const handleSubmitMail = () => {
        dispatch(toggleSpinner());
        const newuser = {
            username: user.username,
            email: email,
            password: ""
        };
        const emailIsValid = validateEmail(email);

        !emailIsValid && toggleToast('error', 'Email non valida');

        emailIsValid && updateUserLogged(newuser).then(() => {
            toggleToast('success', 'Email modificata correttamente')
            dispatch(toggleSpinner());
        }).catch(e => {
            toggleToast('error', e.messaggio)
            dispatch(toggleSpinner());
        });
    };

    const handleSubmitFullName = () => {
        dispatch(toggleSpinner());
        const userOdt = {
            username: user.username,
            email,
            firstName: fullName.firstName,
            lastName: fullName.lastName,
            password: ""
        }

       updateUserLogged(userOdt).then(() => {
            toggleToast('success', 'Nome e cognome modificati correttamente')
            dispatch(toggleSpinner());
        }).catch(e => {
            toggleToast('error', e.messaggio)
            dispatch(toggleSpinner());
        });
    };

    const handleSubmitPassword = () => {
        dispatch(toggleSpinner());
        const newuser = {
            username: user.username,
            email: "",
            password: password
        };

        const passwordIsValid = validatePassword(password, confirmPassword);
        const passwordIsNotEmpty = password.length !== 0;

        !passwordIsValid && toggleToast('error', 'Verificare che le password siano uguali');
        !passwordIsNotEmpty && toggleToast('error', "Il campo password non può essere vuoto")

        if (passwordIsValid && passwordIsNotEmpty) {
            updateUserLogged(newuser).then(() => {
                dispatch(toggleSpinner());
                toggleToast('success', 'Password modificata correttamente')
            }).catch(e => {
                dispatch(toggleSpinner());
                toggleToast('error', e.messaggio)
            });
        }

    };

    return (
        <div className={classes.formContainer}>
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
                <span style={{marginRight: '10px'}}><b> Username: </b> {username} </span>
                <b> Ruolo: </b> {ruolo} </div>
            <Card elevation={3} className={classes.formCard}>
                <Accordion style={{marginBottom: '20px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Nome e cognome</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{width: '100%'}}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <Input id="firstName" aria-describedby="firstName-text" value={fullName.firstName} onChange={(e: any) => {
                                    setFullName({...fullName, firstName: e.target.value})
                                }}/>
                            </FormControl>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <Input id="lastName" aria-describedby="lastName-text" value={fullName.lastName} onChange={(e: any) => {
                                    setFullName({...fullName, lastName: e.target.value})
                                }}/>
                            </FormControl>
                            <div className="btn-container">
                                <Button startIcon={<CreateIcon/>} title="Crea Utente" variant="contained"
                                        color="primary" onClick={handleSubmitFullName}>
                                    Modifica
                                </Button>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{marginBottom: '20px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Email</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{width: '100%'}}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <Input id="email" aria-describedby="email-text" value={email} onChange={(e: any) => {
                                    setEmail(e.target.value)
                                }}/>
                            </FormControl>
                            <div className="btn-container">
                                <Button startIcon={<CreateIcon/>} title="Crea Utente" variant="contained"
                                        color="primary" onClick={handleSubmitMail}>
                                    Modifica
                                </Button>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Password</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{width: '100%'}}>
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.label} htmlFor="password">Password</InputLabel>
                                <Input id="password" type="password" aria-describedby="password-text" value={password}
                                       onChange={(e: any) => {
                                           setPassword(e.target.value)
                                       }}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.label} htmlFor="confirmpassword">Conferma
                                    password</InputLabel>
                                <Input id="confirmpassword" type="password" aria-describedby="password-text"
                                       value={confirmPassword}
                                       onChange={(e: any) => {
                                           setConfirmPassword(e.target.value)
                                       }}/>
                            </FormControl>
                            <div className="btn-container">
                                <Button startIcon={<CreateIcon/>} title="Crea Utente" variant="contained"
                                        color="primary" onClick={handleSubmitPassword}>
                                    Modifica
                                </Button>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Card>
        </div>
    )
}
