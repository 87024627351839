import axiosInstance from './interceptor'
import {HttpStatusCode} from "../utils/protocols/http";
import {toggleToast} from "../store/actionCreators/general";
import {DOCUMENT_UPLOAD_ERROR, DOCUMENT_UPLOAD_SUCCESS} from "../store/actions/general";
import {IPagination} from "../utils/model_types";

const {REACT_APP_BASENAME} = process.env

const resource = `${REACT_APP_BASENAME}/assets`

export interface IAsset {
    id: number;
    contract: string;
    owner: string;
    type: string;
    vendor: string;
    model: string;
    sku: string;
    description: string;
    quantity: number;
}

export const searchAsset = async ({sku, orderId, pagination}: { sku?: string, orderId?: string, pagination: IPagination }) => {
    let url = `${REACT_APP_BASENAME}/custom/assets`;
    let queryString = `?page=${pagination.page}&size=${pagination.pageSize}`;
    if (sku) {
        queryString = queryString.concat('&').concat(`sku.contains=${sku}`);
    }
    if (orderId) {
        queryString = queryString.concat('&').concat(`ordersId.equals=${orderId}`);
    }

    return await axiosInstance.get<IAsset[]>(`${url}${queryString}`);
}

export const uploadAssetService = async (formData: FormData, orderId: number) => {
    return await axiosInstance.post(`${resource}/${orderId}/load`, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export const uploadAssetFile = async (file: File, orderId: number) => {
    const formData = new FormData();
    formData.append('file', file)
    try {
        const resp = await uploadAssetService(formData, orderId)
        if (resp.status === HttpStatusCode.ok) {
            toggleToast('success', DOCUMENT_UPLOAD_SUCCESS.replace('Documento', 'Asset'))
            return true
        }
    } catch (e) {
        toggleToast('error', DOCUMENT_UPLOAD_ERROR.replace('del documento', 'dell\'asset'))
        return false
    }
}

export const downloadAssetsTemplate = async () => {
    return await axiosInstance.get(`${REACT_APP_BASENAME}/custom/assets/template`, {responseType: 'blob'});
}
