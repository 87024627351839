import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Step,
    StepLabel,
    Stepper,
    TableCell,
    TableRow,
    TextField,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input/Input";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {getProductsOrderedByOrderId, modifyStartEndDateProduct} from "../../api/productsOrdereds";
import {useStyles} from './style';
import TableDettaglioOrdine from "./TableDettaglioOrdine";
import {
    downloadExcelOrdine,
    getOrderById,
    modifyOrder,
    modifyOrderBasic,
    sendMail,
    sendTradeInDiscountMail
} from "../../api/orders";
import {IOrder, ISelectedProduct, IUser, IChannel} from "../../utils/model_types";
import WarningIcon from '@material-ui/icons/Warning';
import {findAllVendorsBuying} from "../../api/vendors";
import {convertEuro, getRoles} from "../../routing/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import moment from "moment";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {
    COD_COMMESSA_ERROR,
    COD_COMMESSA_SUCCESS,
    DOCUMENT_DELETED_ERROR,
    DOCUMENT_DELETED_SUCCESS,
    DOCUMENT_ERROR,
    DOCUMENT_UPLOAD_ERROR,
    DOCUMENT_UPLOAD_SUCCESS,
    EMAIL_SENT,
    INDIRIZZO_ITALWARE_SUCCESS,
    ORDER_ACCEPT_SUCCESS,
    ORDER_MAIL_ERROR,
    ORDER_REJECT_SUCCESS,
    PO_UPLOAD_ERROR,
    PO_UPLOAD_SUCCESS,
    STEP_ACCEPTED,
    STEP_REJECTED,
    US,
    USP,
    USS,
    UTBO,
    UTBOP
} from "../../store/actions/general";
import {useHistory} from "react-router-dom";
import {step} from "../../utils/enums";
import {saveAs} from "file-saver";
import './DettaglioOrdine.css';
import {createOrderDocuments, deleteOrderDocument, getOrderDocuments} from "../../api/orderDocuments";
import {daysBetweenTwoDates} from "../../utils/utils";
import {getCommitmentById, getCommitments} from "../../api/commitments";
import ModalConfirmRfiFile from "./components/ModalConfirmRfiFile";
import {getUserByUsername} from "../../api/users";
import {HttpStatusCode} from "../../utils/protocols/http";
import {downloadAssetsTemplate, uploadAssetFile} from "../../api/assets";
import ModalConfirmUploadPO from "./components/modalConfirmUploadPO";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Modal from "@material-ui/core/Modal/Modal";
import ModalAssetsTemplateInfo from "./components/ModalAssetsTemplateInfo";
import {getChannelList} from "../../store/actionCreators/channels";


interface VendorI {
    "quantity": number,
    "budget": number,
    "accumulated": number,
    "orderDate": string,
    "totalPending": number,
    "vendorName": string
}

interface DettaglioOrdineI {
    "id": number,
    "orderId": string,
    "orderDate": string,
    "approvalDate1stStep": string | null,
    "status1stStep": string | null,
    "note1stStep": string | null,
    "approvalDate2ndStep": string | null,
    "status2ndStep": string | null,
    "note2ndStep": string | null,
    "approvalDate3rdStep": string | null,
    "status3rdStep": string | null,
    "note3rdStep": string | null,
    "approvalDate4thStep": string | null,
    "status4thStep": string | null,
    "note4thStep": string | null,
    "operationPlan": string,
    "totalBeforeDiscount": number,
    "totalAfterDiscount": number,
    "username": string | null,
    "productsOrderedDTOList": any
}

const dateFormat = 'DD/MM/YYYY HH:mm:ss'; //HH:mm:ss

interface IProps {
    children: any,
    title: string
}

const OrderSectionWrapper = ({title, children}: IProps) => {
    return (
        <div style={{margin: '20px 0'}}>
            <h3>{title}</h3>
            {children}
        </div>
    );
}

const fileList = [
    {nome: "Piano Operativo", owner: [UTBOP], visibilita: ["ALL"], step: "2", category: "po", uploadedFromBe: false},
    {
        nome: "Dettaglio elementi in fornitura",
        owner: [UTBOP, UTBO],
        visibilita: ["ALL"],
        step: ">2",
        category: "def",
        uploadedFromBe: false
    },
    {
        nome: "Asset Sogei",
        owner: [UTBOP, UTBO],
        visibilita: ["ALL"],
        step: ">2",
        category: "ass",
        uploadedFromBe: false
    },
    {nome: "DUVRI", owner: [UTBOP, UTBO], visibilita: ["ALL"], step: "", category: "duv", uploadedFromBe: false},
    {nome: "DDT", owner: [UTBOP, UTBO], visibilita: ["ALL"], step: ">2", category: "ddt", uploadedFromBe: false},
    {nome: "CNI", owner: [UTBOP, UTBO], visibilita: [UTBO, UTBOP], step: "", category: "cni", uploadedFromBe: false},
    {
        nome: "Piano di collaudo",
        owner: [UTBOP],
        visibilita: ["ALL"],
        step: ">2",
        category: "pdc",
        uploadedFromBe: false
    },
    {
        nome: "Rapporto fine installazione",
        owner: [UTBOP],
        visibilita: ["ALL"],
        step: ">2",
        category: "rfi",
        uploadedFromBe: false
    },
    {
        nome: "Relazione dei servizi erogati",
        owner: [UTBOP],
        visibilita: ["ALL"],
        step: ">2",
        category: "rse",
        uploadedFromBe: false
    },
    {
        nome: "Nota di dismissione",
        owner: [USP],
        visibilita: ["ALL"],
        step: ">2",
        category: "ndd",
        uploadedFromBe: false
    },
    {nome: "F.I.R.", owner: [UTBOP, UTBO], visibilita: ["ALL"], step: ">2", category: "fir", uploadedFromBe: false},
    {
        nome: "Nota di verifica di conformità",
        owner: [UTBOP],
        visibilita: [UTBO, UTBOP],
        step: ">2",
        category: "nvc",
        uploadedFromBe: false
    },
];

const findStep = (currentStep: number, rule: string) => {
    const step = Number(/\d{1,}/.exec(rule)) || -1;
    if (rule.indexOf('>') === 0) {
        return currentStep > step
    }
    return step === -1 || currentStep === step
}

export enum Owner {
    TIM = 'TIM',
    ITW = 'ITW'
}

export enum Channel {
    MATICMIND = 'Maticmind',
    AXIANS = 'AXIANS',
    ITALIA_NET_SERVICES_IRTET = 'italianetservices IRTET',
}

export default function DettaglioOrdine(props: any) {

    const [ordine, setOrdine] = useState<IOrder>();
    const [showMotivazione, setShowMotivazione] = useState<boolean>(false);
    const [motivazione, setMotivazione] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [dataApprovazione, setDateApprovazione] = useState<Array<any>>([]);
    const [productsOrdered, setProductsOrdered] = useState<ISelectedProduct[]>([]);
    const [vendors, setOrderVendors] = useState([]);
    const [rejected, setRejected] = useState<Boolean>(false);
    const [loadedPage, setLoadedPage] = useState<Boolean>(false);
    const [documents, setDocuments] = useState(fileList)
    const [uploadedFiles, setUploadedFiles] = useState<any[]>(new Array(fileList.length).fill(null));
    const [originalUploadedFiles, setOriginalUploadedFiles] = useState<any[]>(new Array(fileList.length).fill(null));
    const [showCommitmentCode, setShowCommitmentCode] = useState<Boolean>(false);
    const [commitmentCode, setCommitmentCode] = useState('');
    const [commitmentDescription, setCommitmentDescription] = useState('');
    const [isDisabledPutCommitment, setIsDisbabledPutCommitment] = useState(true);
    const [indirizzoItalware, setIndirizzoItalware] = useState('');
    const [commitments, setCommitments] = useState<any>([]);
    const [rfiFileConfirm, setRfiFileConfirm] = useState({modal: false, checked: false})
    const [showModalAssetsTemplateInfo, setShowModalAssetsTemplateInfo] = useState(false)
    const [isReject4thStep, setIsReject4thStep] = useState(false)
    const [applicantOrder, setApplicantOrder] = useState<IUser | null>(null)
    const [owner, setOwner] = useState<Owner>()
    const [channel, setChannel] = useState<IChannel>()
    const [commitmentOda, setNumOda] = useState<string>();
    const [selectAllProducts, setSelectAllProducts] = useState<boolean>(false);
    const [showConfirmUploadModal, setShowConfirmUploadModal] = useState<boolean>(false);
    const [showTradeInDiscountModal, setShowTradeInDiscountModal] = useState<boolean>(false);
    const [commonTradeInDiscount, setCommonTradeInDiscount] = useState<number>(0);
    const [tableHeaders, setTableHeaders] = useState<string[]>([]);

    const classes = useStyles();
    const dispatch = useDispatch();

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);

    const storeChannels = useSelector((state: RootState) => state.channels.channelList);

    useEffect(()=>{
        if(auth?.includes(UTBOP))
        dispatch(getChannelList());
    },[])

    useEffect(() => {
        let newTableHeaders = ["SKU", "Categoria", "Brand", "Manutenzione", "Periodo validità", "Destinazione", "Prezzo", "Prezzo scontato", "Quantità", "Totale"];

        if (auth.includes(UTBOP) && ordine?.status3rdStep !== 'ACCEPTED') {
            newTableHeaders = ["Checkbox", "SKU", "Categoria", "Brand", "Manutenzione", "Periodo validità", "Destinazione", "Prezzo", "Prezzo scontato", "Sconto trade in", "Prezzo scontato trade in", "Quantità", "Totale"];
        } else if (ordine?.status3rdStep === 'ACCEPTED' && (!auth.includes(USP) && !auth.includes(UTBO))) {
            newTableHeaders = ["SKU", "Categoria", "Brand", "Manutenzione", "Periodo validità", "Destinazione", "Prezzo", "Prezzo scontato", "Sconto trade in", "Prezzo scontato trade in", "Quantità", "Totale"];
        } else if (auth.includes(USP) || auth.includes(UTBO)) {
            newTableHeaders = ["SKU", "Categoria", "Brand", "Manutenzione", "Periodo validità", "Destinazione", "Prezzo", "Sconto trade in", "Prezzo scontato", "Quantità", "Totale"]
        }

        setTableHeaders(newTableHeaders);
        // eslint-disable-next-line
    }, [ordine])

    useEffect(() => {
        // if received commitment from service hide submit button
        setIsDisbabledPutCommitment(!!((ordine?.commitmentDescription && ordine?.commitmentCode && ordine?.numOda) &&
            !auth.includes(USP)))
    }, [auth, ordine])

    const history = useHistory();

    const routeTo = (routeName: string) => {
        history.push(routeName)
    };

    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    useEffect(() => {
        const id = props.match.params.id;
        getOrderById(id).then(({data}) => {
            setNumOda(data.numOda)
            data.warehouse && setIndirizzoItalware(data.warehouse);
            data.approvalDate4thStep && data.commitmentsId && getCommitmentById(data.commitmentsId).then(response => {
                setCommitmentCode(response.data.code);
                setCommitmentDescription(response.data.description)
            });
            data.approvalDate4thStep && getAllCommitments();
            data.approvalDate4thStep && setShowCommitmentCode(true);
            setOrdine(data);
            setOwner(data.owner)
            setChannel(data.channel)
            calculateStep(data);
            getProductsOrderedByOrderId(id).then(({data}) => {
                setProductsOrdered(data.map((d: any) => ({
                    ...d,
                    productType: d.productType.type,
                    discountPrice: d.tradeInDiscount > 0 ? d.discountPrice : d.totalBeforeTradeIn,
                    selected: false
                })));
                // @ts-ignore
                const vendors = [...new Set(data.map((v: { brand: string; }) => v.brand))];
                findAllVendorsBuying()
                    .then(res => {
                        // @ts-ignore
                        setOrderVendors(res.data.filter(v => vendors.includes(v.vendorName)));
                    })
                    .catch()
            });
            setDocumentUploaded(id);
        });
        // eslint-disable-next-line
    }, [props.match.params.id]);

    const calculateStep = (data: DettaglioOrdineI) => {
        const {status1stStep, status2ndStep, status3rdStep, status4thStep} = data;
        let step = 0;
        let date = [];
        let reject = false;

        switch (status1stStep) {
            case "ACCEPTED": {
                date.push({
                    status: "Accettato il",
                    data: moment(data.approvalDate1stStep).format(dateFormat)
                });
                step = 1;
                break;
            }
            case "REJECTED": {
                date.push({
                    status: "Rifiutato il",
                    data: moment(data.approvalDate1stStep).format(dateFormat),
                    motivazione: data.note1stStep
                });
                step = -1;
                reject = true;
            }
        }

        switch (status2ndStep) {
            case "ACCEPTED": {
                date.push({
                    status: "Accettato il",
                    data: moment(data.approvalDate2ndStep).format(dateFormat),
                });
                step = 2;

                break;
            }
            case "REJECTED": {
                date.push({
                    status: "Rifiutato il",
                    data: moment(data.approvalDate2ndStep).format(dateFormat),
                    motivazione: data.note2ndStep
                });
                step = -1;
                reject = true;
            }
        }

        switch (status3rdStep) {
            case "ACCEPTED": {
                date.push({
                    status: "Caricato il",
                    data: moment(data.approvalDate3rdStep).format(dateFormat)
                });
                step = 3;
                break;
            }
            case "REJECTED": {
                date.push({
                    status: "Rifiutato il",
                    data: moment(data.approvalDate3rdStep).format(dateFormat),
                    motivazione: data.note3rdStep
                });
                step = -1;
                reject = true;
            }
        }

        switch (status4thStep) {
            case "ACCEPTED": {
                date.push({
                    status: "Accettato il",
                    data: moment(data.approvalDate4thStep).format(dateFormat)
                });

                step = 5;
                break;
            }
            case "REJECTED": {
                date.push({
                    status: "Rifiutato il",
                    data: moment(data.approvalDate4thStep).format(dateFormat),
                    motivazione: data.note4thStep
                });
                step = -1;
                reject = true;
                setIsReject4thStep(reject)
            }

        }
        setActiveStep(step);
        setDateApprovazione(date);
        setRejected(reject);
        setLoadedPage(true);
    };

    const sendEmail = useCallback((newOrder: object, idOrder: any, mailData: any, status: string, redirect: boolean) => {
        modifyOrder(newOrder, mailData.step)
            .then(resp => {
                !redirect && setOrdine(resp.data)
                sendMail(idOrder, mailData)
                    .then(() => {
                        // toggleToast('success', EMAIL_SENT)
                    }).catch(e => toggleToast('error', ORDER_MAIL_ERROR + '. ' + (e.messaggio || '')))
                dispatch(toggleSpinner());
                toggleToast('success', status === 'rejected' ? ORDER_REJECT_SUCCESS : ORDER_ACCEPT_SUCCESS);
                redirect && routeTo('/lista-ordini');
            })
            .catch(() => dispatch(toggleSpinner()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const accetta = () => {
        if (isMandatoryOwner()) {
            toggleToast('error', 'L’inserimento dell’ownership è obbligatorio')
            return
        }
        if (isMandatoryChannel()) {
            toggleToast('error', 'L’inserimento del canale è obbligatorio')
            return
        }
        setShowMotivazione(false);
        const date = new Date();
        const idOrder = ordine?.orderId;

        let newOrder: any = {
            ...ordine,
            owner,
            channel
        };

        let status = 'accepted';

        /*
        * status = [accepted, rejected, loaded, created, modified]
        * */
        const mailData = {
            userRole: [],
            notes: '',
            step: '',
            status: STEP_ACCEPTED
        }

        dispatch(toggleSpinner());

        if (activeStep === 0 && auth.includes(USP)) {
            newOrder = {
                ...newOrder,
                status1stStep: "ACCEPTED",
                approvalDate1stStep: date,
                status2ndStep: "ONHOLD"
            };
            sendEmail(newOrder, idOrder, {...mailData, userRole: [UTBO, UTBOP], step: '1'}, status, true);
        }
        if (activeStep === 1 && auth.includes(UTBOP)) {
            newOrder = {
                ...newOrder,
                status2ndStep: "ACCEPTED",
                approvalDate2ndStep: date,
                status3rdStep: "ONHOLD"
            };
            // @ts-ignore
            sendEmail(newOrder, idOrder, {
                ...mailData,
                username: newOrder.username,
                userRole: [USP],
                step: '2'
            }, status, true);
        }
        if (activeStep === 3 && auth.includes(USP)) {
            getAllCommitments();
            setShowCommitmentCode(true);
            newOrder = {
                ...newOrder,
                status4thStep: "ACCEPTED",
                approvalDate4thStep: date
            };
            sendEmail(newOrder, idOrder, {...mailData, userRole: [UTBO, UTBOP, USS], step: '4'}, status, false);
            // @ts-ignore
            document.getElementById('step3active').remove();
        }

    };

    const rifiuta = () => {
        const date = new Date();
        const idOrder = ordine?.orderId;
        if (isMandatoryOwner()) {
            toggleToast('error', 'L’inserimento dell’ownership è obbligatorio')
            return
        }
        if (isMandatoryChannel()) {
            toggleToast('error', 'L’inserimento del canale è obbligatorio')
            return
        }
        let newOrder: any = {
            ...ordine,
            owner,
            channel
        };

        let status = 'rejected';

        const mailData = {
            userRole: [],
            notes: motivazione,
            step: '',
            status: STEP_REJECTED
        }

        dispatch(toggleSpinner());

        if (activeStep === 0 && auth.includes(USP)) {
            newOrder = {
                ...newOrder,
                status1stStep: "REJECTED",
                approvalDate1stStep: date,
                note1stStep: motivazione
            };
            // @ts-ignore
            sendEmail(newOrder, idOrder, {
                ...mailData,
                username: newOrder.username,
                userRole: [UTBO, UTBOP],
                step: '1'
            }, status, true);
        }
        if (activeStep === 1 && auth.includes(UTBOP)) {
            newOrder = {
                ...newOrder,
                status2ndStep: "REJECTED",
                approvalDate2ndStep: date,
                note: motivazione,
                note2ndStep: motivazione
            };
            // @ts-ignore
            sendEmail(newOrder, idOrder, {
                ...mailData,
                username: newOrder.username,
                userRole: [USP],
                step: '2'
            }, status, true);
        }
        if (activeStep === 2 && auth.includes(UTBOP)) {
            newOrder = {
                ...newOrder,
                status3rdStep: "REJECTED",
                approvalDate3rdStep: date,
                note3rdStep: motivazione
            };
            // @ts-ignore
            sendEmail(newOrder, idOrder, {
                ...mailData,
                username: newOrder.username,
                userRole: [USP],
                step: '3'
            }, status, true);
        }
        if (activeStep === 3 && auth.includes(USP)) {
            newOrder = {
                ...newOrder,
                status4thStep: "REJECTED",
                approvalDate4thStep: date,
                note4thStep: motivazione
            };
            sendEmail(newOrder, idOrder, {...mailData, userRole: [UTBO, UTBOP], step: '4'}, status, true);
        }
    };

    const changeMotivazione = (value: any) => {
        setMotivazione(value);
    };

    /**
     * @param event
     * @param categoryFile
     * @param index
     */
    const uploadFile = (event: any, categoryFile: string, index: number) => {
        const {files} = event.target;
        const ff = uploadedFiles.map((file, i) => i === index && files[0] ? {
            // @ts-ignore
            ordersId: ordine?.id,
            nameFile: files[0].name,
            fileData: files[0],
            category: categoryFile,
            uploadedFromBe: false
        } : file);

        // @ts-ignore
        document.getElementById(`uploadedFile${index}`).value = "";
        setUploadedFiles(ff);
    };

    const eliminaUploadedFile = (index: number) => {
        if (uploadedFiles[index].uploadedFromBe) {
            dispatch(toggleSpinner());
            // @ts-ignore
            deleteOrderDocument(uploadedFiles[index].id).then(() => {
                const ff = uploadedFiles.map((file, i) => i === index ? null : file)
                setOriginalUploadedFiles(ff);
                setUploadedFiles(ff);
                // @ts-ignore
                document.getElementById(`uploadedFile${index}`).value = "";

                toggleToast('success', DOCUMENT_DELETED_SUCCESS);
                dispatch(toggleSpinner());
            }).catch(() => {
                dispatch(toggleSpinner());
                toggleToast('success', DOCUMENT_DELETED_ERROR);
            })
        }
    };

    const addUploadedFile = () => {
        setUploadedFiles(files => [...files, null]);
        const docs = [...documents];
        docs.push({
            nome: "Altri",
            owner: [UTBOP, UTBO],
            visibilita: ["ALL"],
            step: "",
            category: "oth",
            uploadedFromBe: false
        },)
        setDocuments(docs)
    }

    const getClassDate = (index: number) => {
        if (index === 1) {
            return classes.stepDate2;
        }
        if (index === 2 || index === 3) {
            return classes.stepDate3;
        }
    };

    const downloadOrderDocuments = (index: number) => {
        const file = uploadedFiles[index];
        const a = document.createElement("a");
        a.href = file.fileData;
        a.download = file.nameFile;
        a.click()
    };

    const handleDownloadAssetsTemplate = () => {
        dispatch(toggleSpinner());
        downloadAssetsTemplate().then(response => {
            saveAs(response.data, "File Asset_Repertorio_NRXG200374 Ordine XX.xlsx");
            setShowModalAssetsTemplateInfo(!showModalAssetsTemplateInfo)
            dispatch(toggleSpinner());
        }).catch(() => {
            dispatch(toggleSpinner());
        })
    }

    const isMandatoryOwner = () => activeStep === 1 && auth.includes(UTBOP) && !owner;
    const isMandatoryChannel = () => activeStep === 1 && auth.includes(UTBOP) && !channel;

    const calculatePrivilegi = () => {
        //STEP 1
        if (activeStep === 0 && auth.includes(USP)) {
            if (isTotalOverBudget()) {
                return (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button size="large" style={{color: 'red'}} onClick={() => rifiutaOrderOverBudget()}>
                            Rifiuta
                        </Button>
                    </div>
                )
            } else {
                return (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button size="large" style={{color: 'green'}} onClick={() => accetta()}>Accetta</Button>
                        <Button size="large" style={{color: 'red'}} onClick={() => setShowMotivazione(true)}>
                            Rifiuta
                        </Button>
                    </div>
                )
            }

        }
        //STEP 2
        if (activeStep === 1 && auth.includes(UTBOP)) {
            return (
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button size="large" style={{color: 'green'}}
                            onClick={() => accetta()}>Accetta</Button>
                    <Button size="large" style={{color: 'red'}} onClick={() => setShowMotivazione(true)}>
                        Rifiuta
                    </Button>
                </div>
            )
        }
        //STEP 4
        if (activeStep === 3 && auth.includes(USP)) {
            return (
                <div style={{textAlign: "center", width: '100%'}}>
                    <div id="step3active">
                        <Button size="large" style={{color: 'green'}} onClick={() => accetta()}>Accetta</Button>
                        <Button size="large" style={{color: 'red'}} onClick={() => setShowMotivazione(true)}>
                            Rifiuta
                        </Button>
                    </div>
                </div>
            )
        }
    };

    function exportOrder() {
        dispatch(toggleSpinner());
        downloadExcelOrdine(ordine?.id)
            .then(res => {
                dispatch(toggleSpinner());
                saveAs(res.data, `${ordine?.simulatedOrderName}.xlsx`)
            });

    }

    const isTotalOverBudget = () => {
        let check = false;
        for (let i = 0; i < vendors.length; i++) {
            // @ts-ignore
            if (vendors[i].totalPending > (vendors[i].budget - vendors[i].accumulated)) {
                // @ts-ignore
                check = true;
            }
        }
        return check
    };

    const checkNameBrands = () => {
        let budgetsOver = [];
        for (let i = 0; i < vendors.length; i++) {
            // @ts-ignore
            if (vendors[i].totalPending > (vendors[i].budget - vendors[i].accumulated)) {
                // @ts-ignore
                budgetsOver.push(vendors[i].vendorName);
            }
        }
        if (isTotalOverBudget()) {
            return `Il totale richiesto nell'ordine per ${budgetsOver.toString()} supera il budget residuo per il vendor.`
        } else {
            return ''
        }
    };

    const rifiutaOrderOverBudget = () => {
        // @ts-ignore
        setMotivazione(checkNameBrands());
        setShowMotivazione(true)
    };

    const putCommitmentCode = () => {
        dispatch(toggleSpinner());
        let newOrder;
        const commitmentexisting = commitments.find((el: any) => el.code === commitmentCode);

        if (commitmentexisting) {
            newOrder = {
                ...ordine,
                commitmentsId: commitmentexisting.id,
                numOda: commitmentOda,
            };
        } else {
            newOrder = {
                ...ordine,
                commitmentCode,
                commitmentDescription,
                numOda: commitmentOda,
                commitmentsId: null
            };
        }

        modifyOrderBasic(newOrder)
            .then(() => {
                dispatch(toggleSpinner());
                toggleToast('success', COD_COMMESSA_SUCCESS);
                routeTo('/lista-ordini');
            })
            .catch(() => {
                dispatch(toggleSpinner());
                toggleToast('error', COD_COMMESSA_ERROR);
            })
    };

    const checkFileRfiBeforeSave = (fileToUpload: any, forceUpload: boolean) => {
        let result = true
        if (fileToUpload && fileToUpload.category === 'rfi') {
            productsOrdered.forEach(p => {
                if ((!p.serviceStart || !p.serviceEnd) && !forceUpload) {
                    result = false
                }
            })
        }

        if (!result) {
            setRfiFileConfirm({...rfiFileConfirm, modal: true})
        }

        return result
    }

    const putUploadedFile = (index: number, file: any, forceUpload = false) => {
        if (file) {
            let fileToUpload = uploadedFiles[index];
            const cloneFile = {...fileToUpload};
            if (fileToUpload.category === 'oth') fileToUpload.category = file.nome;
            if (fileToUpload && checkFileRfiBeforeSave(fileToUpload, forceUpload)) {
                dispatch(toggleSpinner());
                const fileBase64 = () => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(fileToUpload?.fileData);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = error => reject(error);
                }).then(b64 => {
                    fileToUpload = {
                        ...fileToUpload,
                        fileData: b64
                    }
                    createOrderDocuments(fileToUpload).then(response => {
                        let ff = uploadedFiles;
                        ff[index] = {...response.data, uploadedFromBe: true};
                        setUploadedFiles(ff);
                        fileToUpload.category !== 'po' && toggleToast('success', DOCUMENT_UPLOAD_SUCCESS);
                        const newOrder = {
                            ...ordine,
                            status3rdStep: "ACCEPTED",
                            approvalDate3rdStep: new Date(),
                            status4thStep: "ONHOLD",
                            owner,
                            channel
                        };
                        fileToUpload.category === 'po' && modifyOrder(newOrder, 3)
                            .then(() => {
                                const newOrder = {
                                    ...ordine,
                                    status3rdStep: "ACCEPTED",
                                    approvalDate3rdStep: new Date(),
                                    status4thStep: "ONHOLD",
                                    owner,
                                    channel
                                };
                                const mailData = {
                                    userRole: [],
                                    notes: motivazione,
                                    step: '',
                                    status: "LOADED"
                                }
                                let status = 'accepted';
                                dispatch(toggleSpinner());
                                sendEmail(newOrder, ordine?.orderId, {
                                    ...mailData,
                                    userRole: [USP],
                                    step: '3'
                                }, status, false);

                                toggleToast('success', PO_UPLOAD_SUCCESS);
                                routeTo('/lista-ordini');
                            }).catch(() => toggleToast('error', PO_UPLOAD_ERROR));
                        dispatch(toggleSpinner());
                    }).catch(() => {
                        toggleToast('error', DOCUMENT_UPLOAD_ERROR)
                        dispatch(toggleSpinner());
                    });
                })


                // if category file is ass (Asset Sogei) upload file to assets service
                if (cloneFile.category === 'ass') {
                    uploadAssetFile(cloneFile.fileData, cloneFile.ordersId).then((res) => {
                        if (res) {
                            // noinspection JSIgnoredPromiseFromCall
                            fileBase64()
                        } else {
                            dispatch(toggleSpinner());
                        }
                    })
                } else {
                    // noinspection JSIgnoredPromiseFromCall
                    fileBase64()
                }
            }
        } else {
            const ff = [...uploadedFiles];
            ff[index] = originalUploadedFiles[index];
            setUploadedFiles(ff);
        }
    };

    const setDocumentUploaded = (id: number) => {
        dispatch(toggleSpinner());
        getOrderDocuments(`ordersId.equals=${id}`).then(response => {
            let ff = uploadedFiles;
            const docs = [...documents];
            response.data.forEach((element: any, i: any) => {
                let index = documents.findIndex(element => element.category === response.data[i].category);

                if (index > -1) {
                    ff[index] = {...element, uploadedFromBe: true};
                } else {
                    setUploadedFiles(files => [...files, null]);
                    docs.push({
                        nome: element.category === 'oth' ? 'Altri' : element.category,
                        owner: [UTBOP, UTBO],
                        visibilita: ["ALL"],
                        step: "",
                        category: element.category,
                        uploadedFromBe: false
                    },);
                    ff[docs.length - 1] = {...element, uploadedFromBe: true};
                }
            });
            setOriginalUploadedFiles(ff);
            setUploadedFiles(ff);
            setDocuments(docs)
            dispatch(toggleSpinner());
        }).catch(() => {
            toggleToast('error', DOCUMENT_ERROR);
            dispatch(toggleSpinner());
        });
    };

    const putWareHouse = () => {
        dispatch(toggleSpinner());
        let newOrder = {
            ...ordine,
            warehouse: indirizzoItalware
        };
        modifyOrderBasic(newOrder)
            .then(() => {
                // @ts-ignore
                setOrdine(newOrder)
                dispatch(toggleSpinner());
                toggleToast('success', INDIRIZZO_ITALWARE_SUCCESS);
            })
            .catch(() => {
                dispatch(toggleSpinner())
            })
    };

    const checkValidDates = (start?: string, end?: string) => {
        if (start && end) {
            const startDate = moment(start)
            const endDate = moment(end)

            return startDate < endDate
        }

        return false
    };

    const changeDateManutenzione = (index: number) => {

        const startDate = productsOrdered[index].serviceStart;
        const endDate = productsOrdered[index].serviceEnd;

        if (startDate && endDate) {

            if (checkValidDates(startDate, endDate)) {
                const productId = productsOrdered[index].productOrderedId;
                const sD = new Date(startDate);
                const eD = new Date(endDate)

                // @ts-ignore
                modifyStartEndDateProduct(productId, sD.toISOString(), eD.toISOString()).then(() => {
                    toggleToast('success', 'Data manutenzione modificata correttamente')
                }).catch(() => {
                    toggleToast('error', 'Ops.. Qualcosa è andato storto durante la modifica della data manutenzione')
                })
            } else {
                const products = [...productsOrdered];
                products[index].serviceStart = '';
                products[index].serviceEnd = '';
                // @ts-ignore
                document.getElementById(`dataAvvio${index}`).value = "";
                // @ts-ignore
                document.getElementById(`dataFine${index}`).value = "";
                setProductsOrdered(products);
                toggleToast('error', 'Data fine non può essere precedente alla data avvio')
            }

        } else {
            toggleToast('error', 'Completare campo data manutenzione')
        }
    };

    const changeServiceStart = (e: any, index: number) => {
        const products = [...productsOrdered];
        products[index].serviceStart = e.target.value;
        setProductsOrdered(products)
    };

    const changeServiceEnd = (e: any, index: number) => {
        const products = [...productsOrdered];
        products[index].serviceEnd = e.target.value;
        setProductsOrdered(products)
    };

    const getCommitmentsList = () => commitments.map((comm: any) => comm.code);

    const getCommitmentsDescrList = () => commitments.map((comm: any) => comm.description);

    const handleChangeCodeCommitment = (params: any) => {
        const commitment = commitments.find((comm: any) => comm.code === params);
        setCommitmentCode(params)
        commitment && setCommitmentDescription(commitment.description);
    };

    const getAllCommitments = () => {
        getCommitments().then((res: any) => {
            setCommitments(res.data)
        });
    };

    useEffect(() => {
        if (ordine?.username && !applicantOrder) {
            getUserByUsername(ordine.username)
                .then(resp => {
                    if (resp.status === HttpStatusCode.ok) {
                        try {
                            const user = resp.data[0]
                            setApplicantOrder(user)
                        } catch (e) {
                            console.error(e)
                        }
                    }
                })
        }
    }, [ordine, applicantOrder])

    function checkAllProducts() {
        const newProductsOrdered = productsOrdered.map(prod => ({...prod, selected: !selectAllProducts}));
        setProductsOrdered(newProductsOrdered);
        setSelectAllProducts(!selectAllProducts);
    }

    function checkSingleProduct(index: number) {
        const newProductsOrdered = productsOrdered.map((prod, i) => ({
            ...prod,
            selected: index === i ? !prod.selected : prod.selected
        }));
        const allProductsChecked = newProductsOrdered.every(el => el.selected);
        setSelectAllProducts(allProductsChecked);
        setProductsOrdered(newProductsOrdered);
    }

    function changeTradeInDiscount(value: number, idx: number) {
        const newProductList = productsOrdered.map((prod, i) => (
            {
                ...prod,
                initialTradeInDiscount: idx === i ? value : (prod.initialTradeInDiscount || prod.tradeInDiscount)
            }
        ));

        setProductsOrdered(newProductList);
    }

    function createRightDTOList(list: ISelectedProduct[]) {
        return list.map(el => ({
            destinationAddress: el.destinationsAddress,
            note: el.note,
            productsId: el.id,
            quantity: el.quantity,
            serviceEnd: el.serviceEnd,
            serviceStart: el.serviceStart,
            totalAfterDiscount: el.discountPrice * el.quantity,
            totalBeforeDiscount: el.price * el.quantity,
            totalBeforeTradeIn: el.totalBeforeTradeIn * el.quantity,
            tradeInDiscount: el.tradeInDiscount
        }))
    }

    function applyDiscount() {
        dispatch(toggleSpinner());

        const newProductList = productsOrdered.map((prod) => {
            const newDiscountPrice = prod.initialTradeInDiscount ? prod.price - ((prod.price * prod.initialTradeInDiscount) / 100) : prod.discountPrice
            return {
                ...prod,
                tradeInDiscount: prod.initialTradeInDiscount || prod.tradeInDiscount,
                discountPrice: newDiscountPrice > 0 ? parseFloat(newDiscountPrice.toFixed(2)) : 0
            }
        });
        applyDiscountTradeIn(newProductList);
    }

    function applyMultipleTradeInDiscount() {
        dispatch(toggleSpinner());

        const newProductList = productsOrdered.map((prod) => {
            if (prod.selected) {
                const newDiscountPrice = parseFloat((prod.price - ((prod.price * commonTradeInDiscount) / 100)).toFixed(2));
                return {
                    ...prod,
                    tradeInDiscount: commonTradeInDiscount,
                    discountPrice: newDiscountPrice > 0 ? parseFloat(newDiscountPrice.toFixed(2)) : 0
                }
            } else {
                return prod;
            }
        });
        applyDiscountTradeIn(newProductList);
    }

    function applyDiscountTradeIn(newProductList: ISelectedProduct[]) {
        const newOrder = {
            ...ordine,
            totalAfterDiscount: parseFloat(newProductList.reduce((tot, el) => tot + Number(el.discountPrice) * el.quantity, 0).toString()),
            productsOrderedDTOList: createRightDTOList(newProductList)
        };

        modifyOrderBasic(newOrder)
            .then(() => {
                dispatch(toggleSpinner());
                setShowTradeInDiscountModal(false);
                setCommonTradeInDiscount(0);
                toggleToast('success', 'Sconti applicati con successo.');
                routeTo('/lista-ordini');
                sendTradeInDiscountMail(ordine?.orderId)
                    .then(() => toggleToast('success', EMAIL_SENT))
                    .catch(e => toggleToast('error', ORDER_MAIL_ERROR + '. ' + (e.messaggio || '')))
            })
            .catch(() => {
                dispatch(toggleSpinner());
                setShowTradeInDiscountModal(false);
                setCommonTradeInDiscount(0);
                toggleToast('error', 'Ops... Qualcosa è andato storto durante inserimento sconti.');
            })
    }

    function initialDiscountIsLowerThenTradeInDiscount(product: ISelectedProduct): boolean {
        if (!!product.initialTradeInDiscount && product.initialTradeInDiscount > 0) {
            return product.discountsDTO.discountValue <= product.initialTradeInDiscount
        } else {
            return true;
        }
    }

    function initialDiscountIsLowerThenTradeInDiscountGlobal(products: ISelectedProduct[]): boolean {
        return products.every(product => product.discountsDTO.discountValue <= product.initialTradeInDiscount)
    }

    function getMaxDiscount(products: ISelectedProduct[]): number {
        let maxValue = 0;
        products.forEach(el => maxValue = el.discountsDTO.discountValue > maxValue ? el.discountsDTO.discountValue : maxValue);
        return maxValue;
    }

    function handleChangeCategory(file: any, index: number, value: string) {
        const docs = [...documents];
        const doc = {...docs[index]};
        doc.nome = value;
        docs[index] = doc;
        setDocuments(docs);
    }

    // @ts-ignore
    return (
        <div style={{backgroundColor: 'white', padding: '20px'}}>
            <p className="m-0" style={{textAlign: 'center', fontSize: '18px'}}>{ordine?.simulatedOrderName}</p>

            <Stepper activeStep={activeStep}>
                {step.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {
                dataApprovazione.length > 0 && <div style={{display: 'flex', padding: '10px 24px'}}>
                    {
                        dataApprovazione.length > 0 && dataApprovazione.map((el, index) => (
                                <div key={index}
                                     style={{width: '25%', color: 'gray'}}
                                     className={getClassDate(index)}>
                                    {el.status} {el.data}
                                    {el.motivazione && <div>Motivo rifiuto: {el.motivazione}</div>}
                                </div>
                            )
                        )
                    }
                </div>
            }
            <div className="w-100">
                {!rejected && loadedPage && calculatePrivilegi()}
                {
                    showMotivazione &&
                    <div style={{textAlign: 'right', marginTop: '25px'}}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined">Motivo</InputLabel>
                            <OutlinedInput
                                id="outlined"
                                onChange={event => changeMotivazione(event.target.value)}
                                labelWidth={60}
                                defaultValue={motivazione}
                            />
                        </FormControl>
                        <Button size="large" color="primary" disabled={!motivazione}
                                onClick={() => setShowMotivazione(false)}> Annulla</Button>
                        <Button size="large" color="primary" disabled={!motivazione}
                                onClick={() => rifiuta()}> Conferma</Button>
                    </div>
                }
                {
                    showCommitmentCode &&
                    <div style={{textAlign: 'right', marginTop: '25px'}}>
                        <FormControl fullWidth variant="outlined">
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="combo-commitment-code"
                                        options={getCommitmentsList()}
                                        onChange={(e: any, newValue: string | null) => handleChangeCodeCommitment(newValue || '')}
                                        debug
                                        disabled={!auth.findValuesInArray([USP])}
                                        inputValue={commitmentCode}
                                        onInputChange={(event, newInputValue) => {
                                            handleChangeCodeCommitment(newInputValue || '')
                                        }}
                                        // @ts-ignore
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       label="Codice commessa"
                                                       variant="outlined"/>}
                                    />
                                </Grid>
                                <Grid
                                    item xs={4}>
                                    <Autocomplete
                                        id="combo-commitment-descr"
                                        inputValue={commitmentDescription}
                                        options={getCommitmentsDescrList()}
                                        debug
                                        disabled={commitments.find((comm: any) => comm.code === commitmentCode) || !auth.findValuesInArray([USP])}
                                        // @ts-ignore
                                        onChange={(e: any, newValue: string | null) => setCommitmentDescription(newValue || '')}
                                        onInputChange={(event, newInputValue) => {
                                            setCommitmentDescription(newInputValue || '')
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Descrizione commessa"
                                                                            variant="outlined"/>}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" style={{width: '100%'}}>
                                        <InputLabel id="demo-simple-select-label">Numero ODA</InputLabel>
                                        <Input
                                            value={commitmentOda || ''}
                                            aria-describedby="standard-weight-helper-text"
                                            onChange={e => setNumOda(e.target.value)}
                                            type="text"
                                            className="col-12"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormControl>
                        {
                            (auth.findValuesInArray([USP, UTBOP]) &&
                                ((!isDisabledPutCommitment && auth.includes(USP)) &&
                                    (commitmentCode && commitmentDescription))) && <div>
                                <Button
                                    size="large"
                                    color="primary"
                                    onClick={() => {
                                        setCommitmentCode('')
                                        setCommitmentDescription('')
                                        setNumOda('')
                                    }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    size="large"
                                    color="primary"
                                    onClick={() => putCommitmentCode()}
                                >
                                    Conferma
                                </Button>
                            </div>
                        }
                    </div>
                }
                <ModalConfirmRfiFile
                    show={rfiFileConfirm.modal}
                    onConfirm={() => {
                        const index = uploadedFiles.findIndex(f => f && f.category === 'rfi')
                        if (index > -1) {
                            putUploadedFile(index, true)
                        }
                        setRfiFileConfirm(_ => ({checked: true, modal: false}))
                    }}
                    onCancel={() => setRfiFileConfirm({checked: false, modal: false})}
                />
                <ModalAssetsTemplateInfo
                    show={showModalAssetsTemplateInfo}
                    onConfirm={handleDownloadAssetsTemplate}
                    onCancel={() => setShowModalAssetsTemplateInfo(!showModalAssetsTemplateInfo)}
                />
                <h3 className="mt-3">Documenti</h3>
                <table className="table table-sm table-striped table-bordered mb-4">
                    <thead>
                    <tr>
                        <th className="text-center" style={{width: '300px'}} scope="col">Categoria</th>
                        <th scope="col" className="text-center">Nome</th>
                        <th scope="col" className="text-center" style={{width: '110px'}}>Upload</th>
                        <th scope="col" className="text-center" style={{width: '120px'}}>Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        React.Children.toArray(documents.map((file, index) => (file.visibilita.includes('ALL')
                            || auth.find((el: string) => [USP, UTBOP, UTBO, US].includes(el) && file.visibilita.includes(el))
                        ) && (
                            <tr>
                                <td style={{verticalAlign: 'middle'}}>
                                    {file.category === 'oth' && !(uploadedFiles[index] && uploadedFiles[index].uploadedFromBe) ?
                                        <Input type="text"
                                               defaultValue={file.nome}
                                               onChange={({target: {value}}) => handleChangeCategory(file, index, value)}/>
                                        : <div className={"download-asset"}>
                                            <span>{file.nome}</span>
                                            {file.nome === "Asset Sogei" ?
                                                <small className={"download-asset-label"}
                                                       onClick={() => setShowModalAssetsTemplateInfo(!showModalAssetsTemplateInfo)}>
                                                    <i className={"fas fa-download download-asset-icon"}/>
                                                    Download
                                                </small>
                                                : ""
                                            }
                                        </div>}
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    {uploadedFiles[index] ? (
                                        <span className="d-flex align-items-center justify-content-between">
                                            {uploadedFiles[index]?.nameFile}
                                            {
                                                (
                                                    // please see point 9 from Segnalazioni Portale Sogei.docx
                                                    (auth.includes(UTBOP) && isReject4thStep && file.category === 'po')
                                                    ||
                                                    (auth.findValuesInArray(file.owner) && findStep(activeStep, file.step))
                                                ) ?
                                                    <span>
                                                        {!uploadedFiles[index].uploadedFromBe ?
                                                            <button className="btn btn-sm btn-outline-danger ml-2"
                                                                    onClick={() => putUploadedFile(index, null)}>
                                                                <i className="fas fa-save"/> Annulla
                                                            </button>
                                                            :
                                                            <button className="btn btn-sm btn-outline-danger"
                                                                    onClick={() => eliminaUploadedFile(index)}>
                                                                Elimina
                                                            </button>
                                                        }
                                                        {!uploadedFiles[index].uploadedFromBe && (
                                                            <button className="btn btn-sm btn-success ml-2"
                                                                    onClick={() => putUploadedFile(index, file)}>
                                                                <i className="fas fa-save"/> Salva
                                                            </button>)
                                                        }
                                            </span> : null
                                            }
                                        </span>
                                    ) : file.nome === "Asset Sogei" ?
                                        <small style={{fontStyle: 'italic'}}>Il caricamento (secondo il formato
                                            definito) consente di valorizzare i SN dell’ordine di fornitura nella vista
                                            Asset Sogei.</small>
                                        : '-'}
                                </td>
                                <td style={{verticalAlign: 'middle'}} className="text-center">
                                    {
                                        (
                                            // please see point 9 from Segnalazioni Portale Sogei.docx
                                            (auth.includes(UTBOP) && isReject4thStep && file.category === 'po')
                                            ||
                                            (auth.findValuesInArray(file.owner) && findStep(activeStep, file.step))
                                        ) ? (
                                            <React.Fragment>
                                                <input type="file"
                                                       id={`uploadedFile${index}`}
                                                       onChange={e => uploadFile(e, file.category, index)}/>
                                                <label className="m-0 btn btn-sm btn-outline-primary"
                                                       onClick={() => file.nome === 'Piano Operativo' ? setShowConfirmUploadModal(true) : document.getElementById(`uploadedFile${index}`)?.click()}>
                                                    <i className="fas fa-cloud-upload-alt"/> Importa
                                                </label>
                                            </React.Fragment>
                                        ) : '-'
                                    }
                                </td>
                                <td style={{verticalAlign: 'middle'}} className="text-center">
                                    {
                                        uploadedFiles[index] && uploadedFiles[index].uploadedFromBe ? (
                                            <span className="text-center d-flex align-items-center">
                                                <button className="btn btn-sm btn-outline-danger"
                                                        onClick={() => downloadOrderDocuments(index)}>
                                                    <i className="fas fa-download"/> Download
                                                </button>
                                            </span>
                                        ) : '-'
                                    }
                                </td>
                            </tr>
                        )))
                    }
                    {
                        auth.findValuesInArray([UTBO, UTBOP]) && (
                            <tr>
                                <td colSpan={4} className="text-right" style={{verticalAlign: 'middle'}}>
                                    <span onClick={addUploadedFile} className="cursor-pointer"><i
                                        className="fas fa-plus"/> Aggiungi altro documento</span>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <div className={classes.itemSpedizione}>
                    <label> <b> Indirizzo magazzino: </b></label>
                    {!auth.findValuesInArray([UTBO, UTBOP]) ?
                        <span> {ordine?.warehouse ? ordine.warehouse : '-'}</span> :
                        <>
                            <Input
                                value={indirizzoItalware}
                                aria-describedby="standard-weight-helper-text"
                                onChange={e => setIndirizzoItalware(e.target.value)}
                                type="text"
                                className="ml-3"
                            />
                            <Button variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.margin}
                                    disabled={!indirizzoItalware || ordine && indirizzoItalware === ordine.warehouse}
                                    onClick={() => putWareHouse()}>
                                Salva
                            </Button>
                        </>
                    }
                    {
                        auth?.includes(UTBOP) && ordine && !ordine.owner && (
                            <FormControl variant="outlined" style={{minWidth: 120, marginLeft: 100}}>
                                <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                                <Select
                                    labelId="Owner-select-label"
                                    label="Owner"
                                    id="Owner-select"
                                    value={owner}
                                    disabled={Boolean(ordine?.owner)}
                                    onChange={(e: any) => {
                                        setOwner(e.target.value)
                                    }}
                                >
                                    <MenuItem value={Owner.TIM}>{Owner.TIM}</MenuItem>
                                    <MenuItem value={Owner.ITW}>{Owner.ITW}</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }
                    {
                        auth?.includes(UTBOP) && ordine && !ordine.channel && (
                            <FormControl variant="outlined" style={{minWidth: 120, marginLeft: 100}}>
                                <InputLabel id="channel-label">Canale</InputLabel>
                                <Select
                                    labelId="channel-label"
                                    label="Canale"
                                    id="Channel-select"
                                    value={channel}
                                    disabled={Boolean(ordine?.channel)}
                                    onChange={(e: any) => {
                                        setChannel(storeChannels.find(s => s.id === e.target.value))
                                    }}
                                >
                                    {
                                        storeChannels.map(el => <MenuItem key={el.id} value={el.id}>{el.channel}</MenuItem>)
                                    }
                                    
                                </Select>
                            </FormControl>
                        )
                    }
                </div>
                <OrderSectionWrapper title="Informazioni per la spedizione">
                    <div className={classes.itemSpedizione}>
                        <label htmlFor="applicant-order"><b>Richiedente ordine</b>:&nbsp;</label>
                        <span
                            id="applicant-order">{applicantOrder?.firstName} {applicantOrder?.lastName}</span>
                        <label htmlFor="email-applicant" style={{marginLeft: 15}}><b>Email</b>:&nbsp;</label>
                        <span
                            id="email-applicant"
                        >
                            {applicantOrder?.email}
                        </span>
                        {
                            ordine?.cellNumber && (
                                <>
                                    <label htmlFor="cell-applicant" style={{marginLeft: 15}}><b>N° cell.</b>:&nbsp;
                                    </label>
                                    <span
                                        id="cell-applicant"
                                    >
                                        {ordine?.cellNumber}
                                    </span>
                                </>
                            )
                        }
                    </div>
                    <div className={classes.itemSpedizione}>
                        <label htmlFor="referente-sede"><b>Referente spedizione</b>:&nbsp;</label>
                        <span
                            id="email-referent">{ordine?.deliveries?.firstNameReferent} {ordine?.deliveries?.lastNameReferent}</span>
                        <label htmlFor="email-referent" style={{marginLeft: 15}}><b>Email</b>:&nbsp;</label>
                        <span
                            id="email-referent"
                        >
                            {ordine?.deliveries?.emailReferent}
                        </span>
                        <label htmlFor="cell-referent" style={{marginLeft: 15}}><b>N° cell.</b>:&nbsp;</label>
                        <span
                            id="cell-referent"
                        >
                            {ordine?.deliveries?.cellNumberReferent}
                        </span>
                    </div>
                    <div className={classes.itemSpedizione}>
                        <label htmlFor="sede"><b>Sede spedizione</b>:&nbsp;</label>
                        <span id="sede">{ordine?.deliveries?.address}</span>
                    </div>
                    <div className={classes.itemSpedizione}>
                        <label htmlFor="targa"><b>Fornire targa veicolo</b>:&nbsp;</label>
                        <span id="targa">{ordine?.deliveries?.necessaryLicencePlate ? 'si' : 'no'}</span>
                    </div>
                </OrderSectionWrapper>
                <OrderSectionWrapper title="Situazione budget fornitore dell'ordine">
                    <ul>
                        {
                            vendors.map((vendor: VendorI) => (
                                <li key={vendor.vendorName} className={classes.vendorItem}>
                                    {Number(vendor.totalPending) > (Number(vendor.budget) - Number(vendor.accumulated)) &&
                                        <WarningIcon className={classes.warningIcon}/>}
                                    <span>
                                    <b>{vendor.vendorName}</b> - Budget totale: {convertEuro(vendor.budget)}
                                        - Budget speso: {convertEuro(vendor.accumulated)}
                                        - Budget residuo: {convertEuro(vendor.budget - vendor.accumulated)}
                                        - Budget da approvare: <span
                                        className={[Number(vendor.totalPending) > (Number(vendor.budget) - Number(vendor.accumulated)) && classes.alertBudget].filter(Boolean).join(' ')}>{convertEuro(Number(vendor.totalPending))}</span>
                                </span>
                                </li>
                            ))
                        }
                    </ul>
                </OrderSectionWrapper>
                <h3 className="mt-5">Lista Prodotti
                    <Button className={classes.exportOrdine} variant="contained" color="primary" onClick={exportOrder}>
                        Esporta ordine
                    </Button>
                    {
                        auth.includes(UTBOP) &&
                        <>
                            <Button className={classes.exportOrdine}
                                    disabled={!initialDiscountIsLowerThenTradeInDiscountGlobal(productsOrdered.filter(el => el.initialTradeInDiscount && el.initialTradeInDiscount > 0))}
                                    style={{marginRight: '10px'}} variant="contained"
                                    color="primary" onClick={() => applyDiscount()}>
                                Applica sconti
                            </Button>
                            <Button className={classes.exportOrdine}
                                    disabled={productsOrdered.filter(el => el.selected).length === 0}
                                    style={{marginRight: '10px'}} variant="contained"
                                    color="primary" onClick={() => setShowTradeInDiscountModal(true)}>
                                Sconta selezionati
                            </Button>
                        </>
                    }
                </h3>
                <TableDettaglioOrdine tableHeaders={tableHeaders} checkAllProducts={() => checkAllProducts()}
                                      checkedAll={selectAllProducts}>
                    {
                        productsOrdered && productsOrdered.map((product, index) => {
                            return (
                                <TableRow key={product.sku}>
                                    {
                                        auth.includes(UTBOP) && ordine?.status3rdStep !== 'ACCEPTED' &&
                                        <TableCell>
                                            <input type='checkbox' onChange={() => checkSingleProduct(index)}
                                                   checked={product.selected}/>
                                        </TableCell>
                                    }
                                    <TableCell>{product.sku}</TableCell>
                                    <TableCell>{product.productType}</TableCell>
                                    <TableCell>{product.brand}</TableCell>
                                    {
                                        auth.includes(USP) && uploadedFiles.some(item => item?.category === 'pdc') ?
                                            <TableCell>
                                                <TextField
                                                    id={`dataAvvio${index}`}
                                                    type="date"
                                                    className={classes.textField}
                                                    label="Data Avvio"
                                                    style={{marginBottom: 10}}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    defaultValue={product.serviceStart ? moment(product.serviceStart).format('YYYY-MM-DD') : null}
                                                    onChange={e => changeServiceStart(e, index)}
                                                />
                                                <TextField
                                                    id={`dataFine${index}`}
                                                    type="date"
                                                    className={classes.textField}
                                                    label="Data Fine"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    defaultValue={product.serviceEnd ? moment(product.serviceEnd).format('YYYY-MM-DD') : null}
                                                    onChange={e => changeServiceEnd(e, index)}
                                                />
                                                <span>
                        <Button variant="contained"
                                size="small"
                                color="primary"
                                className={classes.margin}
                                onClick={() => changeDateManutenzione(index)}>
                          Salva
                        </Button>
                      </span>
                                            </TableCell> :
                                            <TableCell>
                                                {
                                                    product.serviceStart && product.serviceEnd ?
                                                        moment(product.serviceStart).format("DD/MM/YYYY") + ' - ' + moment(product.serviceEnd).format("DD/MM/YYYY") : '-'
                                                }
                                            </TableCell>
                                    }
                                    <TableCell>
                                        {
                                            daysBetweenTwoDates(product.serviceStart, product.serviceEnd)
                                        }
                                    </TableCell>
                                    <TableCell>{product.destinationsAddress ?
                                        product.destinationsAddress : '-'}</TableCell>
                                    <TableCell>{convertEuro(Number(product.price))}</TableCell>
                                    {((auth.includes(USP) || auth.includes(UTBO))) &&
                                        <TableCell>{product.tradeInDiscount > 0 ? product.tradeInDiscount + '%' : '-'}</TableCell>}
                                    {!auth.includes(USP) && !auth.includes(UTBO) &&
                                        <TableCell>{convertEuro(Number(product.totalBeforeTradeIn))}</TableCell>}
                                    {
                                        (auth.includes(UTBOP) || ordine?.status3rdStep === 'ACCEPTED') && !auth.includes(USP) && !auth.includes(UTBO) &&
                                        <TableCell>
                                            {
                                                ordine?.status3rdStep === 'ACCEPTED' ?
                                                    product.tradeInDiscount :
                                                    <input type='number'
                                                           min={product.discountsDTO.discountValue}
                                                           max={100}
                                                           step={0.01}
                                                           value={product.initialTradeInDiscount || product.tradeInDiscount || product.discountsDTO.discountValue}
                                                           onChange={(e) => changeTradeInDiscount(parseFloat(parseFloat(e.target.value).toFixed(2)), index)}
                                                           style={{
                                                               width: '75px',
                                                               marginRight: '10px',
                                                               outline: initialDiscountIsLowerThenTradeInDiscount(product) ? "none" : "red auto 1px"
                                                           }}/>
                                            } %
                                        </TableCell>
                                    }
                                    {(auth.includes(UTBOP) || ordine?.status3rdStep === 'ACCEPTED' || auth.includes(USP) || auth.includes(UTBO)) &&
                                        <TableCell>{convertEuro(Number(product.discountPrice))}</TableCell>}
                                    <TableCell>{product.quantity}</TableCell>
                                    <TableCell>{convertEuro(Number(product.discountPrice) * product.quantity)}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    <React.Fragment>
                        <TableRow onClick={handleClick} style={{backgroundColor: '#F5F5F5'}}>
                            <TableCell colSpan={tableHeaders.length - 2}><b>TOTALI</b></TableCell>
                            <TableCell><b>{productsOrdered.reduce((tot, el) => tot + el.quantity, 0)}</b></TableCell>
                            <TableCell style={{display: 'inline-flex'}}>
                                <b>{convertEuro(productsOrdered.reduce((tot, el) => tot + Number(el.discountPrice) * el.quantity, 0))}</b>
                                {open ? <ExpandLess style={{float: 'right', cursor: 'pointer'}}/> :
                                    <ExpandMore style={{float: 'right', cursor: 'pointer'}}/>}
                            </TableCell>
                        </TableRow>
                        {open && vendors.map((vendor: VendorI) => (
                            <TableRow key={vendor.vendorName}>
                                <TableCell style={{border: 0}} colSpan={2}/>
                                <TableCell colSpan={6}><b>{vendor.vendorName}</b></TableCell>
                                <TableCell/>
                                <TableCell>
                                    {convertEuro(productsOrdered.filter((product: ISelectedProduct) => product.brand === vendor.vendorName).reduce((tot, el) => tot + Number(el.discountPrice) * el.quantity, 0))}
                                    {/* {convertEuro(productsOrdered.reduce((tot, el) => tot + Math.round(el.quantity * Number(el.price) * 100) / 100, 0))}*/}
                                </TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>

                    <Modal
                        open={showTradeInDiscountModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className={classes.modal}>
                        <div className={classes.paper}>
                            <div className='d-flex justify-content-center align-items-center'>
                                Sconto:
                                <input
                                    type='number'
                                    min={getMaxDiscount(productsOrdered.filter(el => el.selected))}
                                    max={100}
                                    step={0.01}
                                    placeholder='Trade in'
                                    style={{
                                        marginRight: '10px',
                                        marginLeft: '10px',
                                        outline: commonTradeInDiscount > 0 ? commonTradeInDiscount < getMaxDiscount(productsOrdered.filter(el => el.selected)) ? "red auto 1px" : "none" : "none"
                                    }}
                                    value={commonTradeInDiscount > 0 ? commonTradeInDiscount : getMaxDiscount(productsOrdered.filter(el => el.selected))}
                                    onChange={(e) => setCommonTradeInDiscount(parseFloat(parseFloat(e.target.value).toFixed(2)))}/>%
                            </div>
                            <div className="mt-2">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCommonTradeInDiscount(0);
                                        setShowTradeInDiscountModal(false)
                                    }}>
                                    Annulla
                                </Button>
                                <Button
                                    disabled={commonTradeInDiscount > 0 ? commonTradeInDiscount < getMaxDiscount(productsOrdered.filter(el => el.selected)) : false}
                                    style={{float: 'right'}} color="primary"
                                    onClick={() => applyMultipleTradeInDiscount()}>
                                    Conferma
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <ModalConfirmUploadPO
                        show={showConfirmUploadModal}
                        onConfirm={() => {
                            setShowConfirmUploadModal(false);
                            document.getElementById('uploadedFile0')?.click()
                        }}
                        onCancel={() => setShowConfirmUploadModal(false)}/>

                </TableDettaglioOrdine>
            </div>
        </div>
    )
};

