import { Roles, User } from '../../routing/types';
import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT
} from '../actions/auth';
import { AuthAction } from '../actions/types';

export interface AuthState {
    token: string | undefined
    user: User
    loading: boolean
    error: boolean
    authorities: Roles
    authCheckEnd: boolean
    isLoading: boolean
}

const initialState: AuthState = {
    token: undefined,
    user: undefined,
    loading: false,
    error: false,
    authorities: [],
    authCheckEnd: false,
    isLoading: false
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AUTH_START: {
            return {
                ...state,
                error: false,
                loading: true,
                authCheckEnd: false,
                isLoading: true
            }
        }
        case AUTH_SUCCESS: {
            const { token, user, authorities } = action.payload;
            return {
                ...state,
                loading: false,
                token,
                user,
                authorities: authorities || [],
                error: false,
                authCheckEnd: true,
                isLoading: false
            }
        }
        case AUTH_FAIL: {
            return {
                ...state,
                error: true,
                loading: false,
                authCheckEnd: true,
                isLoading: false
            }
        }
        case AUTH_LOGOUT: {
            return {
                user: undefined,
                token: undefined,
                loading: false,
                error: false,
                authorities: [],
                isLoading: false,
                authCheckEnd: true
            }
        }
        default:
            return state;
    }
};

export default authReducer;