import React, {useState} from "react"
import {Delete, Edit, PlayArrow, Search} from "@material-ui/icons";
import {
    Box,
    Collapse,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core";
import {ISelectedProduct} from "../../utils/model_types";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {
    DETTAGLIO_ORDINE_SIMULATO_PATH,
    PROGETTISTA,
    SELECTED_PAGE,
    US, USP,
    UTBO,
    UTBOP
} from "../../store/actions/general";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {getRoles} from "../../routing/utils";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    successButton: {
        backgroundColor: '#00a152'
    }
});

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const formatDate = (date: number) => date ? moment(date).format(dateFormat) : '-';

const ListaOrdiniSimulatiRow = (props: any) => {
    const {row, eliminaOrdineSimulato} = props;
    const [productsOrdered,] = useState<ISelectedProduct[]>([]);
    const classes = useRowStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const routeTo = (routeName: string) => {
        history.push(routeName)
    };

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);

    function goToDetailPage(rowId: number) {
        dispatch({type: SELECTED_PAGE, payload: {message: 'Dettaglio preventivo'}});
        localStorage.setItem('selected-page', 'Dettaglio preventivo');
        routeTo(`${DETTAGLIO_ORDINE_SIMULATO_PATH}/${rowId}`);
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell component="th" scope="row">
                    {row.simulatedOrderName}
                </TableCell>
                <TableCell align="center">{formatDate(row.orderDate)}</TableCell>
                <TableCell align="center">{row.username}</TableCell>
                <TableCell style={{display: "flex", justifyContent: "center"}}>
                    <IconButton
                        color="primary"
                        onClick={() => goToDetailPage(row.id)}
                    >
                        <Search/>
                    </IconButton>
                    {[PROGETTISTA, US, USP, UTBOP].join(', ').includes(auth) && (
                        <Tooltip title="Modifica preventivo">
                            <IconButton
                                color="primary"
                                onClick={() => history.push({
                                    pathname: '/modifica-preventivo',
                                    state: {orderId: row.id}
                                })}
                            >
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {[US, USP, UTBOP].join(', ').includes(auth) && (
                        <Tooltip title="Crea ordine">
                            <IconButton
                                color="primary"
                                onClick={() => history.push(({
                                    pathname: '/creazione-ordine',
                                    state: {idOrderSimulato: row.id}
                                }))}
                            >
                                <PlayArrow/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {[PROGETTISTA, US, USP, UTBO, UTBOP].join(', ').includes(auth) && (
                        <Tooltip title="Elimina preventivo">
                            <IconButton
                                color="secondary"
                                onClick={() => eliminaOrdineSimulato(row.id)}
                            >
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0, backgroundColor: '#eeeeee'}} colSpan={9}>
                    <Collapse timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Dettaglio
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Categoria</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Price (€)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        productsOrdered &&
                                        productsOrdered.length > 0 &&
                                        productsOrdered.map(product => {
                                            return (
                                                <TableRow key={product.sku}>
                                                    <TableCell component="th" scope="row">
                                                        {product.sku}
                                                    </TableCell>
                                                    <TableCell>{product.category}</TableCell>
                                                    <TableCell>{product.brand}</TableCell>
                                                    <TableCell align="right">{product.quantity}</TableCell>
                                                    <TableCell align="right">
                                                        {Math.round(product.quantity * Number(product.price) * 100) / 100}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default ListaOrdiniSimulatiRow;
