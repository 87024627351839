import {makeStyles} from "@material-ui/core";
import {amber, red} from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({

    stepDate2: {
        textAlign: 'center'
    },
    vendorItem: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        position: 'relative',
        fontSize: '16px'
    },
    warningIcon: {
        color: amber[500],
        position: 'absolute',
        left: '-30px'
    },
    alertBudget: {
        color: red[400],
        fontWeight: 'bold',
        textAlign: 'center'
    },
    stepDate3: {
        textAlign: 'right'
    },
    exportOrdine: {
        float: 'right'
    },
    itemSpedizione: {
        fontSize: '16px',
        paddingLeft: '40px'
    }

}));
