import axiosInstance from "./interceptor";
import {IPagination, IProductsFilter} from "../utils/model_types";

const {REACT_APP_BASENAME} = process.env;

const productsUrl = `${REACT_APP_BASENAME}/products`;

const handleParams = (pagination: IPagination, filters?: IProductsFilter, sortField?: any | undefined) => {
    const _pagination = `page=${pagination.page}&size=${pagination.pageSize}`;
    const sort = sortField?.value && `sort=${sortField.name},${sortField.value}`;

    const _filters = () => {
        return filters && [
            `hidden.equals=false`,
            filters.brandFilter && `vendorsId.equals=${filters.brandFilter.id}`,
            filters.categoryFilter && `productTypeId.equals=${filters.categoryFilter}`,
            filters.descriptionFilter && `description.contains=${filters.descriptionFilter}`,
            filters.skuFilter &&  `sku.contains=${filters.skuFilter}`].filter(Boolean).join('&');
    };
    return `${productsUrl}?${[_pagination, _filters(), sort].filter(Boolean).join('&')}`;
};

export const findAllProducts = (pagination: IPagination, filters: IProductsFilter, sort?: any | undefined) => {
    return axiosInstance.get(handleParams(pagination, filters, sort), {
        headers: {'Content-Type': 'application/json'}
    })
};

//export const findAllCategories = () => axiosInstance.get(`${productsUrl}/categories`);

export const findAllCategories = () => axiosInstance.get(`product-types`);
