import React, {useReducer} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {closeEmailBox, submitEmailBox} from "../../store/actionCreators/email-box";
import {EMAIL_BOX} from "../../utils/enums";
import {Button, FormControl, TextField} from "@material-ui/core";

const styles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        marginTop: 30
    },
    button: {
        marginTop: theme.spacing(1),
        float: 'right'
    },
    iconSmall: {
        fontSize: 20
    },
    root: {
        padding: theme.spacing(3, 2)
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    info: {
        margin: theme.spacing(1),
        textAlign: 'right',
        width: '100%',
        float: 'left'
    }
}))

export type EmailBoxParams = { subject: string, text: string }

const EmailBox = () => {
    const [formInput, setFormInput] = useReducer(
        (state: EmailBoxParams, newState: { [key: string]: string }) => ({...state, ...newState}),
        {
            subject: "",
            text: ""
        }
    );

    const classes = styles()
    const dispatch = useDispatch()

    const isOpenEmailBox = useSelector((state: RootState) => state.emailBox.isOpenEmailBox)

    const handleClose = () => dispatch(closeEmailBox())

    const handleInput = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({[name]: newValue});
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        submitEmailBox(dispatch, formInput, () => {
            setFormInput({subject: '', text: ''})
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpenEmailBox}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenEmailBox}>
                <div className={classes.paper}>
                    <h5 id="transition-modal-title">{EMAIL_BOX.title}</h5>
                    <form id="transition-modal-description" onSubmit={handleSubmit}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                required
                                label={'Oggetto'}
                                id="subject"
                                name="subject"
                                defaultValue={formInput.subject}
                                onChange={handleInput}
                                aria-describedby="subject-text"
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                label={'Descrizione'}
                                required
                                multiline
                                rows={6}
                                defaultValue={formInput.text}
                                onChange={handleInput}
                                id="text"
                                name="text"
                                variant="outlined"
                                aria-describedby="text"
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            Invia
                        </Button>
                        <span className={classes.info}>
                             oppure telefona al numero <a href="tel:0683650379">06 83650379</a>
                        </span>
                    </form>
                </div>
            </Fade>
        </Modal>
    )
}

export default EmailBox