import React, { Fragment } from "react"
import { makeStyles, Typography } from "@material-ui/core"

import GaugeChart from "react-gauge-chart"
import {convertEuro} from "../../routing/utils";


const useStyle = makeStyles({
    subTitle: {
        marginBottom: 20
    }
});

export const chartStyle = {
    height: 200,
};

const ErosioneContratto = (props : {percentuale: number, accumulated: number, budgets: number}) => {

    const classes = useStyle();

    return (
        <Fragment>
            <Typography variant="h4" className={classes.subTitle}>
                Erosione contratto
            </Typography>
            <GaugeChart
                id="erosione-contratto"
                nrOfLevels={3}
                colors={["#70b3a1", "#dac599", "#bb626a"]}
                arcWidth={0.3}
                percent={props.percentuale}
                textColor={"#000000"}
                style={chartStyle}
                marginInPercent={0.01}
            />
            <Typography variant="h6">
                {convertEuro(props.accumulated)} / {convertEuro(props.budgets)}
            </Typography>
        </Fragment>
    )
};

export default ErosioneContratto
