import React, { useEffect, useRef, useState } from 'react';
import { getRoutes, scrollTop } from "../../routing/utils";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { Fab } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import EmailBox from "../EmailBox";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    appBarSpacer: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fab: {
        position: 'fixed',
        bottom: '90px',
        right: '30px',
        transition: '.3s display',
        display: 'none',
        '&.show': {
            display: 'block'
        },
        opacity: 0.4
    }
}));

const Layout = () => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);
    const storeUser = useSelector((state: RootState) => state.auth.user)
    const [show, setShow] = useState(false);
    const mainRef = useRef(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // @ts-ignore
        mainRef.current.onscroll = () => {
            // @ts-ignore
            mainRef.current.scrollTop > 250 ? setShow(true) : setShow(false)
        };
    }, []);

    return (
        <div className={classes.root}>
            <Header open={open} handleDrawerOpen={handleDrawerOpen}/>
            <Sidebar open={open} handleDrawerClose={handleDrawerClose}/>

            <main className={classes.content} ref={mainRef}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="xl" className={classes.container}>
                    {getRoutes(storeUser)}
                </Container>
            </main>
            <Fab color="primary"
                 onClick={() => scrollTop()}
                 className={[classes.fab, show && 'show'].filter(Boolean).join(' ')}
                 aria-label="add">
                <ArrowUpwardIcon/>
            </Fab>
            <EmailBox />
        </div>
    );
}

export default Layout;
