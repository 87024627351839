import React from 'react';
import {Button, makeStyles, Modal} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    filterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: '15px 0',
        backgroundColor: 'transparent',
        '& > div': {
            marginRight: 10
        }
    },
    table: {
        minWidth: 700
    },
    addButton: {
        float: 'right'
    },
    tableContainer: {
        marginTop: 15
    },
    filterInput: {
        width: 420
    },
    root: {},
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

type Props = {
    show: boolean
    onConfirm: () => void
    onCancel: () => void
}

const ModalConfirmRfiFile: React.FC<Props> = (props) => {
    const {show, onCancel, onConfirm} = props
    const classes = useStyle();

    return (
        <Modal
            open={show}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                Si sta caricando il Rapporto di fine installazione senza aver inserito le relative date di inizio e
                fine.
                Sei sicuro di voler continuare?
                <div className="mt-2">
                    <Button
                        color="secondary"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Annulla
                    </Button>
                    <Button
                        style={{float: 'right'}} color="primary"
                        onClick={() => {
                            onConfirm()
                        }}
                    >
                        Conferma
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalConfirmRfiFile;