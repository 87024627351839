import React, {Fragment, useEffect, useMemo, useReducer, useState} from "react"
import {useHistory} from "react-router-dom";
import {ColDef, ColParams, ValueFormatterParams} from '@material-ui/data-grid';
import SelectedProducts from "./SelectedProducts";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    makeStyles,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {IPagination, IProduct, ISelectedProduct, IVendors, sortI} from "../../utils/model_types";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {
    ONHOLD,
    ORDER_MAIL_ERROR,
    ORDER_SENT_ERROR,
    ORDER_SENT_SUCCESS,
    PRDOUCT_LIST_ERROR,
    PRICE_DESCRIPTION,
    ROWS_PER_PAGE,
    STEP_CREATED,
    STEP_MODIFIED,
    updatePagination,
    USP,
    UTBO,
    UTBOP
} from "../../store/actions/general";
import {findAllVendors, findAllVendorsBuying} from "../../api/vendors";
import {findAllCategories, findAllProducts} from "../../api/products";
import {createOrder, getOrderById, modifyOrder, sendMail} from "../../api/orders";
import {convertEuro} from "../../routing/utils";
import {useDispatch} from "react-redux";
import {getProductsOrderedByOrderId} from "../../api/productsOrdereds";
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {getSimulatedOrderById} from "../../api/simulatedOrders";
import {getSimulatedProductsOrderedBySimulatedOrderId} from "../../api/simulatedProductsOrdereds";
import {
    changeValidityPeriod,
    changeValidityUnit,
    checkServiceDateValuesIsNotNull,
    daysBetweenTwoDates,
    handleChangeDestination,
    handleChangeNote,
    quantityChangeHandler
} from "../../utils/utils";
import moment from 'moment'
import {getDestinations} from "../../api/destinations";

const useStyle = makeStyles((theme) => ({
    filterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: '15px 0',
        backgroundColor: 'transparent',
        '& > div': {
            marginRight: 10
        }
    },
    table: {
        minWidth: 700
    },
    addButton: {
        float: 'right'
    },
    tableContainer: {
        marginTop: 15
    },
    filterInput: {
        width: 420
    },
    root: {},
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

const initialState = {
    firstNameReferent: '',
    lastNameReferent: '',
    address: '',
    principal: true,
    necessaryLicencePlate: false,
    licencePlate: null,
    orderName: null,
    cellNumberReferent: '',
    emailReferent: ''
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...state,
                ...action.payload
            }
        }
        case 'NOME': {
            return {
                ...state,
                firstNameReferent: action.payload
            }
        }
        case 'COGNOME': {
            return {
                ...state,
                lastNameReferent: action.payload
            }
        }
        case 'cellNumberReferent': {
            return {
                ...state,
                cellNumberReferent: action.payload
            }
        }
        case 'emailReferent': {
            return {
                ...state,
                emailReferent: action.payload
            }
        }
        case 'SEDE_PRINCIPALE': {
            return {
                ...state,
                principal: action.payload
            }
        }
        case 'SEDE': {
            return {
                ...state,
                address: action.payload
            }
        }
        case 'TARGA_RICHIESTA': {
            return {
                ...state,
                necessaryLicencePlate: action.payload
            }
        }
        case 'licencePlate': {
            return {
                ...state,
                licencePlate: action.payload
            }
        }
    }
};

const sediPrincipali = [
    'Sede periferica',
    "Roma -Via XX Settembre",
    "Roma -Via M. Carucci",
    "Roma -Via A. Soldati (La Rustica)",
    "Roma -Piazza Dalmazia",
    "Sito di Disaster Recovery",
    "Sito di Piazza Mastai",
]

export const handleChangeServiceDate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedRows: ISelectedProduct[],
    selectedKey: string,
    setSelectedRows: Function,
    index: number) => {
    const updatedSelection = [...selectedRows];
    const serviceStart = selectedKey === 'serviceStart' ? selectedKey : 'serviceStart'
    const serviceEnd = selectedKey === 'serviceStart' ? 'serviceEnd' : selectedKey

    updatedSelection[index][selectedKey] = e.target.value

    const startDate = updatedSelection[index][serviceStart]
    const endDate = updatedSelection[index][serviceEnd]

    const isValidStartDate = Date.parse(startDate || '') > 0
    const isValidEndDate = Date.parse(endDate || '') > 0

    if (isValidEndDate && isValidStartDate) {
        if (!checkValidDates(startDate, endDate)) {
            toggleToast('error', 'Data fine non può essere precedente alla data avvio')
            /*            updatedSelection[index][serviceStart] = ''
                        updatedSelection[index][serviceEnd] = ''*/
        }
    }

    setSelectedRows(updatedSelection)
}

const checkValidDates = (start?: string, end?: string) => {
    if (start && end) {
        const startDate = moment(start)
        const endDate = moment(end)

        return startDate < endDate
    }

    return false
}

type SureChangeBrand = {
    showModal: boolean
    currentBrand: null | string
}

const NuovoOrdine = () => {
    const classes = useStyle();
    const history = useHistory();
    const [rowData, setRowData] = useState<IProduct[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});
    const [vendors, setVendors] = useState<IVendors[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<ISelectedProduct[]>([]);
    const {location: {state}} = history;
    const [infoSpedizione, dispatchInfoSpedizione] = useReducer(reducer, initialState)
    // @ts-ignore
    const idOrdine = state?.orderId;
    // @ts-ignore
    const idOrderSimulato = state?.idOrderSimulato;
    const [orderLoaded, setOrderLoaded] = useState(false);
    const [orderName, setOrderName] = useState<any | null>('');


    // Filter values
    const [brandFilter, setBrandFilter] = useState<string | null>(null);
    const [categoryFilter, setCategoryFilter] = useState<string>('');
    const [skuFilter, setSkuFilter] = useState<string>("");
    const [descriptionFilter, setDescriptionFilter] = useState<string>("");
    const [cellNumber, setCellNumber] = useState("");

    const [sortField, setSortField] = useState<sortI>({name: '', value: null});

    const [orderIdModify, setOrderIdModify] = useState<any | null>('');

    const [idModify, setIdModify] = useState<any | null>(null);

    const [brandsExceededBudget, setBrandsExceededBudget] = useState<any>(null);

    const [openModalBudgetOver, setOpenModalBudgetOver] = useState<boolean>(false);

    const [sureChangeBrand, setSureChangeBrand] = useState<SureChangeBrand>({showModal: false, currentBrand: null})

    const [destinations, setDestinations] = useState([]);

    const dispatch = useDispatch();

    const columns: ColDef[] = useMemo(() => [
        {
            field: 'check',
            renderCell: (params: ValueFormatterParams) => {
                return <Checkbox
                    checked={params.data.checked}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    onClick={() => {
                        const rowDataUpdated = rowData.map(row => {
                            if (row.id === params.data.id) {
                                row.checked = !row.checked
                            }
                            return row;
                        });
                        const isAlreadySelected = selectedRows.findIndex(elSelected => params.data.id === elSelected.id);
                        if (isAlreadySelected !== -1) {
                            setSelectedRows(selectedRows => selectedRows.filter(row => row.id !== params.data.id))
                        } else {
                            const selectedRow = {...params.data as IProduct, quantity: 1};
                            setSelectedRows([...selectedRows, selectedRow]);
                        }
                        setRowData(rowDataUpdated);
                    }}
                />
            },
            renderHeader: (params: ColParams) => {
                return <Checkbox
                    checked={selectedRows.length > 0}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    indeterminate={selectedRows.length > 0}
                />
            },
            sortable: false,
            headerName: 'check', width: 45
        },
        /*{ field: 'id', headerName: 'ID', width: 80 },*/
        {field: 'sku', headerName: 'SKU', width: 170},
        {field: 'description', headerName: 'Descrizione', width: 405},
        {field: 'productType', headerName: 'Categoria', width: 65},
        {field: 'brand', headerName: 'Brand', width: 130},
        {
            field: 'price',
            headerName: 'Prezzo',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 123
        },
        {
            field: 'totalPrice',
            headerName: 'Totale Prezzo',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 123
        },
        {
            field: 'discountPrice',
            headerName: 'Prezzo Scontato',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 140
        },
        {
            field: 'totalDiscountPrice',
            headerName: 'Totale Scontato',
            type: "number",
            valueFormatter: (params: ValueFormatterParams) => convertEuro(Number(params)),
            width: 140
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [rowData]);

    // const storeUser = useSelector((state: RootState) => state.auth.user);
    // const auth = getRoles(storeUser);

    const routeTo = (routeName: string) => {
        history.push(routeName)
    };

    const generateSHA1 = () => {
        const crypto = require('crypto');
        const current_date = (new Date()).valueOf().toString();
        const random = Math.random().toString();
        return crypto.createHash('sha1').update(current_date + random).digest('hex');
    };

    // const handlePaginationChange = (params: any) => {
    //     const paginationUpdated = {...pagination};
    //     paginationUpdated.page = params.page;
    //     paginationUpdated.pageSize = params.pageSize;
    //     setPagination(paginationUpdated);
    // };

    const buildProductFilter = () => {
        return {
            brandFilter: vendors.find(v => v.name === brandFilter),
            categoryFilter,
            skuFilter,
            descriptionFilter
        }
    };

    useEffect(() => {
        dispatch(toggleSpinner());
        // @ts-ignore
        Promise.all([
            findAllProducts(pagination, buildProductFilter(), sortField),
            idOrdine && !orderLoaded && getProductsOrderedByOrderId(idOrdine),
            idOrdine && getOrderById(idOrdine),

            idOrderSimulato && !orderLoaded && getSimulatedProductsOrderedBySimulatedOrderId(idOrderSimulato),
            idOrderSimulato && getSimulatedOrderById(idOrderSimulato)
        ])
            .then((response: any[]) => {
                const paginationHeaderLink = response[0].headers.link.split(',');
                const totalCount = response[0].headers["x-total-count"];
                const checkedRows = (idOrdine ? response[1]?.data : response[3]?.data) || selectedRows;
                const infoSpedizione = response[2]?.data?.deliveries;
                setOrderIdModify(response[2]?.data?.orderId);
                setIdModify(response[2]?.data?.id);
                if (response[2]?.data.orderId) setOrderName(response[2].data.simulatedOrderName?.split('-')[1]);
                if (infoSpedizione) dispatchInfoSpedizione({type: 'INIT', payload: infoSpedizione})

                const rowData = response[0].data.map((product: IProduct) => (
                    {
                        ...product,
                        checked: checkedRows.map((product: IProduct) => product.id).includes(product.id)
                    }
                ))

                // set the branding if it is a modification of order
                if (response[1] || response[3]) {
                    const data = idOrdine ? response[1]?.data : response[3]?.data
                    const findBrand = data.map((p: IProduct) => p.brand)
                    if (Array.isArray(findBrand) && findBrand.length) {
                        setBrandFilter(_ => findBrand[0])
                    }
                }

                if (idOrdine ? response[1]?.data : response[3]?.data) {
                    setSelectedRows(idOrdine ? response[1].data.map((d: any) => ({
                            ...d,
                            productType: d.productType.mtype,
                            // @ts-ignore
                            serviceStart: d.serviceStart ? (d.serviceStart).split("T")[0] : null,
                            serviceEnd: d.serviceEnd ? (d.serviceEnd).split("T")[0] : null

                        }))
                        : response[3].data.map((d: any) => ({
                            ...d,
                            productType: d.productType.type,
                        })));
                    idOrdine && setOrderLoaded(true)
                }
                setRowData(rowData.map((d: any) => ({...d, productType: d.productType.type})));
                setPagination(updatePagination(pagination, paginationHeaderLink, totalCount));
                dispatch(toggleSpinner());
            })
            .catch(e => {
                dispatch(toggleSpinner())
                toggleToast('error', PRDOUCT_LIST_ERROR + '. ' + (e.messaggio || ''));
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize, brandFilter, categoryFilter, skuFilter, descriptionFilter, sortField]);

    useEffect(() => {
        dispatch(toggleSpinner());
        const categoriesPromise = findAllCategories();
        const vendorsPromise = findAllVendors();
        const destinationsPromise = getDestinations();
        Promise.all([categoriesPromise, vendorsPromise, destinationsPromise].filter(Boolean)).then(response => {
            const categoriesResponse = response[0];
            const vendorsResponse = response[1];
            const destinationsResponse = response[2];
            if (categoriesResponse.status === 200) setCategories(categoriesResponse.data);
            if (vendorsResponse.status === 200) setVendors(vendorsResponse.data);
            if (destinationsResponse.status === 200) setDestinations(destinationsResponse.data);
        }).catch(e => {
            toggleToast('error', (e.messaggio || ''))
        }).finally(() => dispatch(toggleSpinner()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVendorsList = () => vendors.map((vendor) => vendor.name);

    // const getCategoryList = () => {
    //     // @ts-ignore
    //     return [...new Set(categories.map(c => c.type))]
    // };

    const productsOrderedPrice = (product: ISelectedProduct, price: string) => {
        // @ts-ignore
        return (product.quantity * parseFloat(product[price])).toFixed(2)
    };

    const formatProductsOrdered = () => selectedRows.filter(selectedRow => selectedRow.quantity > 0).map(product => {
        return {
            quantity: product.quantity,
            totalBeforeDiscount: productsOrderedPrice(product, PRICE_DESCRIPTION.price),
            totalBeforeTradeIn: productsOrderedPrice(product, PRICE_DESCRIPTION.discountPrice),
            totalAfterDiscount: productsOrderedPrice(product, PRICE_DESCRIPTION.discountPrice),
            tradeInDiscount: 0,
            productsId: product.id,
            serviceStart: product.serviceStart ? new Date(product.serviceStart) : null,
            serviceEnd: product.serviceEnd ? new Date(product.serviceEnd) : null,
            destinationsAddress: product.destinationsAddress,
            destinationsId: product.destinationsId === 0 ? null : product.destinationsId,
            note: product.note
        }
    });

    const handleSubmit = () => {
        if (selectedRows.find(row => checkServiceDateValuesIsNotNull(row))) {
            toggleToast('error', 'Inserire le date in Manutenzione');
            return
        }
        //https://keybiz.atlassian.net/browse/SOGFOR-1
        if (selectedRows.find(row => daysBetweenTwoDates(row.serviceStart, row.serviceEnd) < 0)){
            toggleToast('error', 'Data manutenzione non valida');
            return
        }
        if (orderName === '' || orderName === null) {
            toggleToast('error', 'Inserire nome ordine');
            return;
        }
        if (infoSpedizione?.firstNameReferent === '' || infoSpedizione?.lastNameReferent === '' || infoSpedizione?.address === '' /*|| (infoSpedizione?.necessaryLicencePlate && (infoSpedizione?.licencePlate === null || infoSpedizione?.licencePlate === ''))*/) {
            toggleToast('error', 'Inserire i dati di spedizione');
            return;
        }
        const username = localStorage.getItem('username');
        dispatch(toggleSpinner());
        const orderId = orderLoaded ? orderIdModify : generateSHA1();
        const simulatedOrder = orderLoaded ? idModify + ' - ' + orderName : orderName;
        const newOrder = {
            orderId: orderId,
            orderDate: new Date(),
            approvalDate1stStep: '',
            status1stStep: ONHOLD,
            approvalDate2ndStep: '',
            status2ndStep: null,
            approvalDate3rdStep: '',
            status3rdStep: null,
            operationPlan: '',
            totalBeforeDiscount: selectedRows.reduce((acc, el: ISelectedProduct) => acc + (el.quantity * parseFloat(el[PRICE_DESCRIPTION.price])), 0).toFixed(2),
            totalBeforeTradeIn: selectedRows.reduce((acc, el: ISelectedProduct) => acc + (el.quantity * parseFloat(el[PRICE_DESCRIPTION.discountPrice])), 0).toFixed(2),
            totalAfterDiscount: selectedRows.reduce((acc, el: ISelectedProduct) => acc + (el.quantity * parseFloat(el[PRICE_DESCRIPTION.discountPrice])), 0).toFixed(2),
            tradeInDiscount: 0,
            username,
            productsOrderedDTOList: formatProductsOrdered(),
            simulatedOrderName: simulatedOrder,
            cellNumber,
            "deliveries": {
                ...infoSpedizione,
                licencePlate: infoSpedizione.necessaryLicencePlate ? infoSpedizione.licencePlate : ''
            },
        };

        const mailData = {
            "userRole": [USP, UTBO, UTBOP],
            "notes": "",
            "status": orderLoaded ? STEP_MODIFIED : STEP_CREATED,
            "step": "0"
        };

        const call = orderLoaded ? modifyOrder({...newOrder, id: idOrdine}, 0) : createOrder(newOrder);
        call.then(() => {
            sendMail(orderId, mailData).then(() => {
            }).catch(e => toggleToast('error', ORDER_MAIL_ERROR + '. ' + (e.messaggio || '')))

            toggleToast('success', ORDER_SENT_SUCCESS);
            routeTo('lista-ordini');
        }).catch(e => toggleToast('error', ORDER_SENT_ERROR + '. ' + (e.messaggio || '')))
            .finally(() => dispatch(toggleSpinner()));
    };

    const sortTable = (param: string) => {
        setSortField(field => field.name !== param ? {name: param, value: 'desc'} : field.value === 'asc' ? {
            ...field,
            value: null
        } : {...field, value: 'asc'})
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = (event: any) => {
        const pageSize = parseInt(event.target.value, 10);
        setPagination({...pagination, page: 1, pageSize})
    };

    const toggleProduct = (product: IProduct) => {
        const rowDataUpdated = rowData.map(row => {
            if (row.id === product.id) {
                return {
                    ...row,
                    checked: true
                }
            }
            return row;
        });
        setRowData(rowDataUpdated);
    }

    const toggleSelectedProduct = (product: IProduct) => {
        setSelectedRows(rows => [...rows, {
            ...product as IProduct,
            checked: true,
            quantity: 1
        }])
        toggleProduct(product);
    };

    const handleChangeSede = (e: any) => {
        dispatchInfoSpedizione({type: 'SEDE', payload: e.target.value});
        if (e.target.value === 'Sede periferica') {
            dispatchInfoSpedizione({type: 'SEDE_PRINCIPALE', payload: false});
        } else {
            dispatchInfoSpedizione({type: 'SEDE_PRINCIPALE', payload: true});
        }
    };

    function checkBudgetControlOver() {
        findAllVendorsBuying().then(response => {
            let hash = {};
            let brandSelected: any[] = [];

            //nomi dei brand selezionati
            for (let i = 0; i < selectedRows.length; i++) {
                let section = selectedRows[i].brand;

                if (!hash[section]) {
                    brandSelected.push(section);
                    hash[section] = true;
                }
            }

            //return array con nome brand selezionato e relativo budget rimanente
            let remainedBudget = response.data.filter((itemX: any) => brandSelected.includes(itemX.vendorName)).map((el: any) => ({
                brandName: el.vendorName,
                remainedBudget: (el.budget - el.accumulated)
            }));

            //return array con nome brand selezionato e totale costo per prodotto selezionato
            let totalSelectedRows: any = selectedRows.reduce((prev, curr) => {
                let count = prev.get(curr.brand) || 0;
                // @ts-ignore
                prev.set(curr.brand, (curr.discountPrice * curr.quantity) + count);
                return prev;
            }, new Map());

            let reducedObjArr = [...totalSelectedRows].map(([brand, totalPrice]) => {
                return {brand, totalPrice}
            });

            let ispurchasable = remainedBudget.map(function (item: any, index: string | number) {
                return {
                    name: item.brandName,
                    // @ts-ignore
                    isPurchasable: reducedObjArr.find(el => el.brand === item.brandName).totalPrice <= item.remainedBudget
                };
            });

            let arrayOfNotPurchasable = [];
            for (let i = 0; i < ispurchasable.length; i++) {
                if (!ispurchasable[i].isPurchasable) {
                    arrayOfNotPurchasable.push(ispurchasable[i]);
                }
            }

            if (arrayOfNotPurchasable.length > 0) {
                setBrandsExceededBudget(arrayOfNotPurchasable);
                setOpenModalBudgetOver(true);
            } else {
                handleSubmit();
            }
        }).catch(() => handleSubmit());
    }

    return (
        <Fragment>
            <Paper elevation={0} component="div" className={classes.filterContainer}>
                <FormControl>
                    <TextField
                        id="nomeOrdine"
                        variant="outlined"
                        label="Nome Ordine"
                        value={orderName}
                        onChange={(e: any) => setOrderName(e.target.value)}
                    />
                </FormControl>
            </Paper>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <h2>Informazioni richiedente</h2>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <Box width={1 / 4}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label="Numero cellulare richiedente"
                                value={cellNumber}
                                onChange={e => setCellNumber(e.target.value)}/>
                        </FormControl>
                    </Box>
                </Paper>
            </div>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <h2>Informazioni per la spedizione</h2>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <Box width={1 / 4}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label="Nome referente"
                                value={infoSpedizione.firstNameReferent}
                                onChange={e => dispatchInfoSpedizione({type: 'NOME', payload: e.target.value})}/>
                        </FormControl>
                    </Box>
                    <Box width={1 / 4}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label="Cognome referente"
                                value={infoSpedizione?.lastNameReferent}
                                onChange={e => dispatchInfoSpedizione({type: 'COGNOME', payload: e.target.value})}/>
                        </FormControl>
                    </Box>
                    <Box width={1 / 4}>
                        <FormControl fullWidth>
                            <TextField
                                id="a"
                                variant="outlined"
                                label="Numero cellulare referente"
                                value={infoSpedizione.cellNumberReferent}
                                onChange={e => dispatchInfoSpedizione({
                                    type: 'cellNumberReferent',
                                    payload: e.target.value
                                })}/>
                        </FormControl>
                    </Box>
                    <Box width={1 / 4}>
                        <FormControl fullWidth>
                            <TextField
                                id="emailReferent"
                                type={'email'}
                                variant="outlined"
                                label="Email referente"
                                value={infoSpedizione.emailReferent}
                                onChange={e => dispatchInfoSpedizione({
                                    type: 'emailReferent',
                                    payload: e.target.value
                                })}/>
                        </FormControl>
                    </Box>
                </Paper>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <FormControl>
                        <TextField
                            id="sede-principale"
                            select
                            label="Sede per la spedizione"
                            value={infoSpedizione?.address}
                            onChange={e => handleChangeSede(e)}
                            helperText="Selezionare la sede desiderata"
                            variant="outlined"
                        >
                            {sediPrincipali.map((sede, i) => (
                                <MenuItem key={i} value={sede}>
                                    {sede}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Paper>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={infoSpedizione?.necessaryLicencePlate}
                                onChange={e => dispatchInfoSpedizione({
                                    type: 'TARGA_RICHIESTA',
                                    payload: e.target.checked
                                })}
                                color="primary"
                            />
                        }
                        label="Fornire targa veicolo"
                    />
                    {/*{infoSpedizione?.necessaryLicencePlate && <FormControl>
                        <TextField
                            id="licencePlate"
                            variant="outlined"
                            label="Targa"
                            value={infoSpedizione?.licencePlate}
                            onChange={e => dispatchInfoSpedizione({type: 'licencePlate', payload: e.target.value})}/>
                    </FormControl>}*/}
                </Paper>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <FormControl>
                        <h3>
                            Brand di riferimento dell'ordine
                        </h3>
                        <Autocomplete
                            id="brand-filter"
                            disabled={idOrdine}
                            options={getVendorsList()}
                            style={{width: 300}}
                            renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
                            value={brandFilter}
                            onChange={(e: any, newValue: string | null) => {
                                if (selectedRows.length > 0) {
                                    setSureChangeBrand({currentBrand: newValue, showModal: true})
                                } else {
                                    setBrandFilter(newValue);
                                    setPagination(pagination => ({...pagination, page: 0}))
                                }
                            }}
                        />
                    </FormControl>
                </Paper>
            </div>
            {brandFilter && (
                <>
                    <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                        <h2>Elenco prodotti</h2>
                        <Paper elevation={0} component="div" className={classes.filterContainer}>
                            <FormControl>
                                <TextField
                                    id="description-filter"
                                    variant="outlined"
                                    label="Descrizione"
                                    style={{width: 350}}
                                    value={descriptionFilter}
                                    onChange={(e: any) => {
                                        setDescriptionFilter(e.target.value);
                                        setPagination({...pagination, page: 0});
                                    }}/>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Categoria"
                                    id="demo-simple-select"
                                    value={categoryFilter}
                                    onChange={(e: any) => {
                                        setCategoryFilter(e.target.value);
                                        setPagination({...pagination, page: 0})
                                    }}
                                >
                                    <MenuItem value="">Nessuna</MenuItem>
                                    {React.Children.toArray(categories.map(c => <MenuItem value={c.id}>{c.type}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <TextField
                                    id="sku-filter"
                                    variant="outlined"
                                    label="SKU"
                                    value={skuFilter}
                                    onChange={(e: any) => {
                                        setSkuFilter(e.target.value);
                                        setPagination(pagination => ({...pagination, page: 0}))
                                    }}/>
                            </FormControl>
                            {/*<Button variant="contained" color="primary" onClick={filterResults}>Filtra</Button>*/}
                        </Paper>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {React.Children.toArray(columns.filter(el => !!el.headerName && !el.headerName.includes('Totale')).map((col: ColDef) => (
                                            col.field === 'check' ?
                                                <TableCell style={{minWidth: col.width}}>

                                                </TableCell> :
                                                <TableCell style={{minWidth: col.width, cursor: 'pointer'}}
                                                           onClick={() => sortTable(col?.field || '')}>
                                                    {col.headerName}
                                                    {
                                                        sortField.value &&
                                                        sortField.name === col.field &&
                                                        <i
                                                            className={`ml-1 text-secondary fas ${sortField.value === 'desc' ? 'fa-long-arrow-alt-down' : 'fa-long-arrow-alt-up'}`}/>
                                                    }
                                                </TableCell>
                                        )))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {React.Children.toArray(rowData.map((row: IProduct) => (
                                        <TableRow className={classes.root} key={row.id} style={row.checked ? {backgroundColor: '#E7E7E7'} : {backgroundColor: 'white'}}>
                                            {
                                                React.Children.toArray(columns.filter(el => !!el.headerName && !el.headerName.includes('Totale')).map((col) => (
                                                    <TableCell component="th" scope="row">
                                                        {
                                                            col.field === 'check' ?
                                                                <Button
                                                                    variant="contained" color="primary"
                                                                    onClick={() => toggleSelectedProduct(row)}
                                                                > AGGIUNGI
                                                                </Button> :
                                                                col.valueFormatter ? col.valueFormatter(row[col.field]) : row[col.field]
                                                        }
                                                    </TableCell>
                                                )))
                                            }
                                        </TableRow>
                                    )))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={pagination.rowCount || 20}
                                page={pagination.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                rowsPerPage={pagination.pageSize}
                                rowsPerPageOptions={ROWS_PER_PAGE}
                            />
                        </TableContainer>
                    </div>
                    <SelectedProducts
                        columns={columns}
                        selectedRows={selectedRows}
                        orderType={"ordine"}
                        quantityChangeHandler={(e: any, index: number) => quantityChangeHandler(e, selectedRows, setSelectedRows, index, rowData, setRowData)}
                        handleChangeServiceDate={(e, key, index) => handleChangeServiceDate(e, selectedRows, key, setSelectedRows, index)}
                        changeValidityPeriod={(e) => changeValidityPeriod(e, selectedRows, setSelectedRows)}
                        changeValidityUnit={(e) => changeValidityUnit(e, selectedRows, setSelectedRows)}
                        handleChangeDestination={(value, index) => handleChangeDestination(value, selectedRows, setSelectedRows, index)}
                        destinations={destinations}
                        handleChangeNote={(value, index) => handleChangeNote(value, index, selectedRows, setSelectedRows)}
                    />

                    <div style={{overflow: 'hidden'}}>
                        <Button
                            style={{float: 'right'}}
                            variant="contained" color="primary"
                            disabled={selectedRows?.length === 0}
                            onClick={checkBudgetControlOver}
                        >
                            {orderLoaded ? 'MODIFICA' : 'CREA'} ORDINE
                        </Button>
                    </div>
                </>
            )}
            {/* modal confirm change brand */}
            <Modal
                open={sureChangeBrand.showModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    Attenzione! Cambiando brand eliminerai tutti i prodotti selezionati. Vuoi continuare?
                    <div className="mt-2">
                        <Button
                            color="secondary"
                            onClick={() => {
                                setSureChangeBrand({...sureChangeBrand, showModal: false})
                            }}
                        >
                            Annulla
                        </Button>
                        <Button
                            style={{float: 'right'}} color="primary"
                            onClick={() => {
                                setBrandFilter(sureChangeBrand.currentBrand)
                                setSelectedRows([])
                                setSureChangeBrand({...sureChangeBrand, showModal: false})
                                setPagination(pagination => ({...pagination, page: 0}))
                            }}
                        >
                            Conferma
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* modal confirm change brand */}
            <Modal
                open={openModalBudgetOver}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    {
                        brandsExceededBudget && brandsExceededBudget.map((el: any, index: number | string) =>
                            <div key={index}> Il totale richiesto nell'ordine per {el.name} supera il budget residuo per il
                                vendor.
                            </div>)
                    }
                    <div className="mt-2">
                        <Button color="secondary" onClick={() => setOpenModalBudgetOver(false)}
                        > Annulla
                        </Button>
                        <Button
                            style={{float: 'right'}} color="primary"
                            onClick={handleSubmit}
                        > Conferma
                        </Button>

                    </div>
                </div>
            </Modal>
        </Fragment>

    );
};

export default NuovoOrdine;
