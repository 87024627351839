import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {uploadAcronymFile} from "../../api/orders";

const useStyle = makeStyles({
    importFileContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    subtitle: {
        width: 'fit-content',
        margin: 'auto'
    },
    paragraph: {
        textAlign: 'center',
        marginBottom: '0'
    },
    italic: {
        fontStyle: 'italic'
    }
})

export default function ImportAcronym() {
    const classes = useStyle();
    const [selectedFile, setSelectedFile] = useState<any>(null);

    function uploadFile() {
        const {files} = selectedFile;
        let body = new FormData();
        body.append('excelData', files[0]);

        uploadAcronymFile(body)
    }

    return (
        <div>
            <div className={classes.subtitle}>
                <p className={classes.paragraph}>La procedura valorizza in automatico la colonna ACRONIMO* nella vista <b>Elenco Ordini</b></p>
                <small className={classes.italic}>*se l’informazione non è presente il campo mantiene il blank.</small>
            </div>
            <label>Seleziona file da importare</label>
            <div className={classes.importFileContainer}>
                <input
                    style={{display: 'block', marginRight: '10px'}}
                    type='file'
                    accept='.xls, .xlsx'
                    onChange={(e) => setSelectedFile(e.target)}
                />
                {
                    selectedFile &&
                    <label className="m-0 btn btn-sm btn-outline-primary" onClick={() => uploadFile()}>
                        <i className="fas fa-cloud-upload-alt"/> Importa
                    </label>
                }
            </div>
        </div>
    )
}
