import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, makeStyles, Typography } from "@material-ui/core";
import React from "react"
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },

    media: {
        height: 140,
    },
});

const UnimplementedRoute = () => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Container className={classes.container}>
            <Card >
                <CardActionArea>
                    <CardMedia
                        image="https://dummyimage.com./300x200/17161a/9c0000.png&text=++BEWARE++"
                        title="Oops"
                        className={classes.media}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textPrimary" component="p" align="center">
                            You shouldn't have come here...
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary" onClick={() => history.push("/")}>
                        Run.
                    </Button>
                </CardActions>
            </Card>
        </Container>
    )
}

export default UnimplementedRoute;