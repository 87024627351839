import {Box} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import ErosioneContratto from "../../components/ErosioneContratto/ErosioneContratto"
import SpesaPerFornitore from "../../components/SpesaPerFornitore/SpesaPerFornitore"
import SpesaPerFornitoreChart from "../../components/SpesaPerFornitore/SpesaPerFornitoreChart"
import {IExpense} from "../../utils/model_types"
import {useStyle} from "./styles"
import {findAllVendorsBuying} from "../../api/vendors";
import {toggleToast, toggleSpinner} from "../../store/actionCreators/general";
import {AxiosResponse} from "axios";
import {useDispatch} from "react-redux";

const Monitoraggio = () => {

    const classes = useStyle();

    const [accumulated, setAccumulated] = useState<number>(0);
    const [budgets, setBudgets] = useState<number>(0);
    const [percentuale, setPercentuale] = useState<number>(0);
    const [spese, setSpese] = useState<IExpense[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleSpinner());
        findAllVendorsBuying().then((response: AxiosResponse) => {

            setSpese(response.data);
            const totBudgets = response.data.reduce((acc: number, el: IExpense) => acc + el.budget, 0.0);
            const totAccumulated = response.data.reduce((acc: number, el: IExpense) => acc + el.accumulated, 0.0);
            setBudgets(totBudgets);
            setAccumulated(totAccumulated);
            setPercentuale(totAccumulated / totBudgets);
            dispatch(toggleSpinner());

        }).catch((e: any) => {
            dispatch(toggleSpinner());
            toggleToast('error', e.messaggio)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const NoContent = (props: { text: string }) => (
        <div style={{margin: '5em auto'}}>{props.text}</div>
    );

    return (
        <Box className={classes.dashboardContainer}>
            {
                spese && spese.length > 0 ? (
                    <>
                        <div className={classes.main}>
                            <SpesaPerFornitoreChart spese={spese}/>
                        </div>

                        <div className={classes.leftSide}>
                            <SpesaPerFornitore spese={spese} typeExpense={"Canale"}/>

                            <SpesaPerFornitore spese={spese} typeExpense={"Categoria"}/>
                        </div>

                        <div className={classes.rightSide}>
                            <ErosioneContratto
                                budgets={budgets}
                                accumulated={accumulated}
                                percentuale={percentuale}
                            />
                        </div>
                    </>
                ) : <NoContent text="Nessun dato disponibile"/>
            }

        </Box>
    )
};

export default Monitoraggio;
