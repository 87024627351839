import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {IPagination, IParams, ISimulatedOrder, IVendors, sortI} from "../../utils/model_types";
import {deleteSimulatedOrder, getSimulatedOrdersList} from "../../api/simulatedOrders";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {
    ORDER_LIST_ERROR,
    PROGETTISTA,
    ROWS_PER_PAGE,
    updatePagination, US, USP,
    UTBO,
    UTBOP,
} from "../../store/actions/general";
import {getRoles} from "../../routing/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {useHistory} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListaOrdiniSimulatiRow from "../../components/ListaOrdiniSimulatiRow/ListaOrdiniSimulatiRow";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {findAllCategories} from "../../api/products";
import {findAllVendors} from "../../api/vendors";
import BasicDialog from "../../components/Dialogs/BasicDialog";

const useStyles = makeStyles({
    table: {
        minWidth: 700
    },
    addButton: {
        float: 'right',
        marginBottom: 15
    },
    uploadButton: {
        float: 'left',
        marginBottom: 15,
        '&:focus': {
            outline: 'none'
        }
    },
    deleteSimulatedOrder: {
        color: 'red',
    },
    importFileContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    tableContainer: {
        marginTop: 15
    },
    filterInput: {
        width: 420
    },
    formControl: {
        minWidth: 120,
    },
    filterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: '15px 0',
        backgroundColor: 'transparent',
        '& > div > div': {
            marginRight: 10
        }
    },
});

const Preventivi = () => {

    const classes = useStyles();
    const history = useHistory();

    const [orders, setOrders] = useState<ISimulatedOrder[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 0, pageSize: ROWS_PER_PAGE[0]});
    const [togglePagination, setTogglePagination] = useState(true);
    const [vendors, setVendors] = useState<IVendors[]>([]);
    const [categories, setCategories] = useState<any[]>([]);

    // Filter values
    const [brandFilter, setBrandFilter] = useState<string>('');
    const [categoryFilter, setCategoryFilter] = useState<string>('');
    const [skuFilter, setSkuFilter] = useState<string>("");
    const [nomeOrdineFilter, setNomeOrdineFilter] = useState<string>("");
    const [noteFilter, setNoteFilter] = useState<string>("");
    const [sortField, setSortField] = useState<sortI>({name: '', value: null});

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState(-1);

    const getAllSimulatedOrders = () => {
        dispatch(toggleSpinner());
        const params: IParams[] = [];
        brandFilter && params.push({
            key: 'brand',
            operator: 'equals',
            value: brandFilter
        })
        categoryFilter && params.push({
            key: 'category',
            operator: 'equals',
            value: categoryFilter
        })
        skuFilter && params.push({
            key: 'sku',
            operator: 'contains',
            value: skuFilter
        })
        nomeOrdineFilter && params.push({
            key: 'simulatedOrderName',
            operator: 'contains',
            value: nomeOrdineFilter
        })
        noteFilter && params.push({
            key: 'note',
            operator: 'contains',
            value: noteFilter
        })

        getSimulatedOrdersList(params, pagination, sortField).then(response => {
            const paginationHeaderLink = response.headers.link.split(',');
            const totalCount = response.headers["x-total-count"];
            setPagination(updatePagination(pagination, paginationHeaderLink, totalCount));
            setTogglePagination(true)
            setOrders(response.data);
            dispatch(toggleSpinner());
        }).catch(() => {
            dispatch(toggleSpinner())
            toggleToast('error', ORDER_LIST_ERROR)
        })
    }

    useEffect(() => {
        getAllSimulatedOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page, pagination.pageSize, brandFilter, categoryFilter, skuFilter, sortField, nomeOrdineFilter, noteFilter]);

    const handleChangePage = (event: any, newPage: number) => {
        setPagination({...pagination, page: newPage});
    };

    const handleChangeRowsPerPage = (event: any) => {
        const pageSize = parseInt(event.target.value, 10);
        setPagination({...pagination, page: 0, pageSize})
    };

    // const filterOrders = (e: any) => {
    //     const params = [{
    //         key: 'note',
    //         operator: 'contains',
    //         value: e.currentTarget.value
    //     }]
    //     e.currentTarget.value === '' ? getAllSimulatedOrders() :
    //         getSimulatedOrderBySimulatedOrderName(params).then(response => {
    //             if (response.status === 200) {
    //                 const paginationHeaderLink = response.headers.link.split(',');
    //                 const totalCount = response.headers["x-total-count"];
    //                 setPagination(updatePagination({
    //                     "page": 0,
    //                     "pageSize": 10
    //                 }, paginationHeaderLink, totalCount));
    //                 setTogglePagination(false);
    //                 setOrders(response.data);
    //             }
    //         });
    // }

    useEffect(() => {
        dispatch(toggleSpinner());
        const categoriesPromise = findAllCategories();
        const vendorsPromise = findAllVendors();
        Promise.all([categoriesPromise, vendorsPromise]).then(response => {
            const categoriesResponse = response[0];
            const vendorsResponse = response[1];
            if (categoriesResponse.status === 200) setCategories(categoriesResponse.data);
            if (vendorsResponse.status === 200) setVendors(vendorsResponse.data);
            dispatch(toggleSpinner());
        }).catch(e => {
            dispatch(toggleSpinner());
            toggleToast('error', (e.messaggio || ''));
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVendorsList = () => vendors.map((vendor) => vendor.name);

    const sortTable = (param: string) => {
        setSortField(field => field.name !== param ? {name: param, value: 'desc'} : field.value === 'asc' ? {
            ...field,
            value: null
        } : {...field, value: 'asc'})
    }

    function confermaCancellazioneOrdineSimulato() {
        setOpenModal(false);
        dispatch(toggleSpinner());
        deleteSimulatedOrder(selectedRowToDelete)
            .then(() => {
                toggleToast('success', 'Preventivo eliminato con successo');
                getAllSimulatedOrders();
            })
            .catch(err => toggleToast('error', err.messaggio || 'Errore nella cancellazione del preventivo'))
            .finally(() => dispatch(toggleSpinner()))
    }

    function eliminaOrdineSimulato(orderId: number) {
        setSelectedRowToDelete(orderId);
        setOpenModal(true);
    }

    return (
        <>
            <div style={{height: "auto", marginBottom: 25, width: '100%'}}>
                <Paper elevation={0} component="div" className={classes.filterContainer}>
                    <div>
                        <FormControl>
                            <Autocomplete
                                id="brand-filter"
                                options={getVendorsList()}
                                style={{width: 150}}
                                renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
                                value={brandFilter || null}
                                onChange={(e: any, newValue: string | null) => {
                                    setBrandFilter(newValue || '');
                                    setPagination({...pagination, page: 0});
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                label="Categoria"
                                id="demo-simple-select"
                                value={categoryFilter}
                                style={{width: 150}}
                                onChange={(e: any) => {
                                    setCategoryFilter(e.target.value);
                                    setPagination({...pagination, page: 0})
                                }}
                            >
                                <MenuItem value="">Nessuna</MenuItem>
                                {React.Children.toArray(categories.map(c => <MenuItem
                                    value={c.id}>{c.type}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="sku-filter"
                                variant="outlined"
                                label="SKU"
                                value={skuFilter}
                                onChange={(e: any) => {
                                    setSkuFilter(e.target.value);
                                    setPagination({...pagination, page: 0});
                                }}/>
                        </FormControl>
                        <FormControl>
                            <TextField id="nome-ordine-filter"
                                       variant="outlined"
                                       style={{width: 250}}
                                       label="Nome preventivo"
                                       value={nomeOrdineFilter}
                                       onChange={(e: any) => {
                                           setNomeOrdineFilter(e.target.value);
                                           setPagination({...pagination, page: 0});
                                       }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField id="note-filter"
                                       variant="outlined"
                                       label="Note"
                                       style={{width: 250}}
                                       value={noteFilter}
                                       onChange={(e: any) => {
                                           setNoteFilter(e.target.value);
                                           setPagination({...pagination, page: 0});
                                       }}
                            />
                        </FormControl>
                    </div>
                </Paper>
            </div>
            {[PROGETTISTA, US, USP, UTBO, UTBOP].join(', ').includes(auth) && (
                <>
                    <Button className={classes.addButton} variant="contained" color="primary"
                            onClick={() => history.push('/creazione-preventivo')}>
                        Nuovo preventivo
                    </Button>
                    <Button className={classes.uploadButton} variant="contained" color="primary"
                            onClick={() => history.push('/importa-preventivo')}>
                        Importa preventivo
                    </Button>
                </>
            )}
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => sortTable('simulatedOrderName')}>Nome Preventivo</TableCell>
                            <TableCell onClick={() => sortTable('orderDate')} align="center">Data Creazione</TableCell>
                            <TableCell align="center">Creato da</TableCell>
                            <TableCell align="center">Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders && React.Children.toArray(orders.map((el: ISimulatedOrder) => (
                            <ListaOrdiniSimulatiRow key={el.id} row={el} eliminaOrdineSimulato={eliminaOrdineSimulato}/>
                        )))}
                    </TableBody>
                </Table>
                {togglePagination && <TablePagination
                    component="div"
                    count={pagination.rowCount || 0}
                    page={pagination.page}
                    onChangePage={handleChangePage}
                    rowsPerPage={pagination.pageSize}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                />}
            </TableContainer>
            <BasicDialog open={openModal}
                         title="Elimina preventivo"
                         type="d"
                         selectedObject={""}
                         handleClose={() => setOpenModal(false)}
                         textModal="Sei sicuro di voler cancellare il preventivo?"
                         confirmOperation={confermaCancellazioneOrdineSimulato}/>
        </>
    )
};

export default Preventivi;
