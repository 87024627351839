import React, {useState} from "react"
import {
    Check,
    CheckCircle,
    Close,
    Edit,
    RadioButtonUnchecked,
    RemoveCircle,
    Schedule,
    Search
} from "@material-ui/icons";
import {
    Box,
    Chip,
    Collapse,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {IOrder, ISelectedProduct} from "../../utils/model_types";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {
    DETTAGLIO_ORDINE_PATH,
    ONHOLD,
    ORDER_PUT_ERROR,
    ORDER_SAVED_SUCCESS,
    REJECTED,
    SELECTED_PAGE,
    US,
    UTBOP
} from "../../store/actions/general";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {convertEuro, getRoles} from "../../routing/utils";
import {switchCloseOrder} from "../../api/orders";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    successButton: {
        backgroundColor: '#00a152'
    }
});

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const formatDate = (date: number) => date ? moment(date).format(dateFormat) : '-';

const StatusIcon = (props: { label: boolean, order: IOrder, step: number }) => {

    const getStatusNameFromStep = (step: number) => {
        const pos = step + (step === 1 ? 'st' : step === 2 ? 'nd' : step === 3 ? 'rd' : 'th');
        return `status${pos}Step`;
    };

    const getDeadlineColorClass = (date: any) => {
        const passed = moment(new Date()).diff(moment(date), "days", true);
        return passed < 3 ? 'text-success' : passed >= 3 && passed <= 4 ? 'text-warning' : 'text-danger'
    }

    const status = getStatusNameFromStep(props.step);

    switch (props.order[status]) {
        case "ACCEPTED":
            return props.label ?
                <Chip style={{color: '#00a152'}} size="small" icon={<CheckCircle/>} label="Accepted"/> :
                <CheckCircle style={{color: '#00a152'}}/>;
        case "REJECTED":
            return props.label ? <Chip color="secondary" size="small" icon={<RemoveCircle/>} label="Refused"/> :
                <RemoveCircle color="secondary"/>;
        case "ONHOLD": {
            if (props.step === 3 && props.order.lastPossibleDate3rdStep) {
                const dataScadenza = props.order.lastPossibleDate3rdStep;
                return moment(new Date()).diff(moment(dataScadenza), "days", true) < 0 ?
                    <div className={getDeadlineColorClass(dataScadenza)}>Scade
                        il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div> :
                    <div className="text-danger">Scaduta il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div>
            }
            if (props.step === 4 && props.order.lastPossibleDate4thStep) {
                const dataScadenza = props.order.lastPossibleDate4thStep;
                return moment(new Date()).diff(moment(dataScadenza), "days", true) < 0 ?
                    <div className={getDeadlineColorClass(dataScadenza)}>Scade
                        il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div> :
                    <div className="text-danger">Scaduta il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div>
            } else return props.label ? <Chip color="default" size="small" icon={<Schedule/>} label="Pending"/> :
                <Schedule/>;
        }
        default: {
            if (props.step === 3 && props.order.lastPossibleDate3rdStep && props.order.status2ndStep === 'ACCEPTED') {
                const dataScadenza = props.order.lastPossibleDate3rdStep;
                return moment(new Date()).diff(moment(dataScadenza), "days", true) < 0 ?
                    <div className={getDeadlineColorClass(dataScadenza)}>Scade
                        il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div> :
                    <div className="text-danger">Scaduta il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div>
            } else if (props.step === 4 && props.order.lastPossibleDate4thStep && props.order.status3rdStep === 'ACCEPTED') {
                const dataScadenza = props.order.lastPossibleDate4thStep;
                return moment(new Date()).diff(moment(dataScadenza), "days", true) < 0 ?
                    <div className={getDeadlineColorClass(dataScadenza)}>Scade
                        il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div> :
                    <div className="text-danger">Scaduta il {moment(dataScadenza).format('DD/MM/YYYY HH:mm:ss')}</div>
            } else return <span>-</span>;
        }

    }
};

const ListaOrdiniRow = (props: { row: IOrder, handlerSwitchOrder: () => void }) => {
    const {row, handlerSwitchOrder} = props;
    const [productsOrdered,] = useState<ISelectedProduct[]>([]);
    const classes = useRowStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const routeTo = (routeName: string) => {
        history.push(routeName)
    };

    const storeUser = useSelector((state: RootState) => state.auth.user);
    const auth = getRoles(storeUser);

    function goToDetailPage(rowId: number) {
        dispatch({type: SELECTED_PAGE, payload: {message: 'Dettaglio ordine'}});
        localStorage.setItem('selected-page', 'Dettaglio ordine');
        routeTo(`${DETTAGLIO_ORDINE_PATH}/${rowId}`);
    }

    const handleCloseOrder = (id: number, orderClosed: boolean) => {
        const newStatus = !orderClosed
        dispatch(toggleSpinner())
        switchCloseOrder(id, newStatus)
            .then(res => {
                if (res.status === 200) {
                    toggleToast('success', ORDER_SAVED_SUCCESS)
                }
            })
            .catch(e => toggleToast('error', ORDER_PUT_ERROR + '. ' + (e.messaggio || '')))
            .finally(() => {
                dispatch(toggleSpinner())
                handlerSwitchOrder()
            })
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align="center">
                    {row.simulatedOrderName}
                </TableCell>
                <TableCell align="center">
                    {row.channel?.channel}
                </TableCell>
                {auth?.includes(UTBOP) && (<TableCell align="center">{row.owner || ''}</TableCell>)}
                <TableCell align="center">{formatDate(row.orderDate)}</TableCell>
                <TableCell align="center">{row.acronym || '-'}</TableCell>
                <TableCell align="center">
                    {row.username}
                </TableCell>
                <TableCell align="center">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <StatusIcon order={row} step={1} label={false}/>
                        <span
                            className="d-flex w-100 justify-content-center">{row.approvalDate1stStep && moment(row.approvalDate1stStep).format(dateFormat)}</span>
                    </div>
                </TableCell>

                <TableCell align="center">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <StatusIcon order={row} step={2} label={false}/>
                        <span
                            className="d-flex w-100 justify-content-center">{row.approvalDate2ndStep && moment(row.approvalDate2ndStep).format(dateFormat)}</span>
                    </div>
                </TableCell>

                <TableCell align="center">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <StatusIcon order={row} step={3} label={false}/>
                        <span
                            className="d-flex w-100 justify-content-center">{row.approvalDate3rdStep && moment(row.approvalDate3rdStep).format(dateFormat)}</span>
                    </div>
                </TableCell>

                <TableCell align="center">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <StatusIcon order={row} step={4} label={false}/>
                        {
                            row.status4thStep === REJECTED ? (
                                <span className="d-flex w-100 justify-content-center">
                                    {row.rejectedPO4thStep && moment(row.rejectedPO4thStep).format(dateFormat)}
                                </span>
                            ) : (
                                <span
                                    className="d-flex w-100 justify-content-center">{row.approvalDate4thStep && moment(row.approvalDate4thStep).format(dateFormat)}</span>
                            )
                        }
                    </div>
                </TableCell>
                {auth?.includes(UTBOP) && (<TableCell align="center">
                    {
                        row.orderClosed ? (
                            <CheckCircle
                                style={{color: '#00a152', cursor: 'pointer'}}
                                onClick={_ => handleCloseOrder(row.id, row.orderClosed)}
                            />
                        ) : (
                            <RadioButtonUnchecked
                                style={{cursor: 'pointer'}}
                                onClick={_ => handleCloseOrder(row.id, row.orderClosed)}
                            />
                        )
                    }
                </TableCell>)}
                <TableCell align="center">{convertEuro(row.totalAfterDiscount)}</TableCell>
                <TableCell align="center">
                    {row.billed ?
                        <Check className={"text-success"}/> :
                        <Close className={"text-danger"}/>}
                </TableCell>
                <TableCell style={{
                    display: auth.includes(US) && (row.status1stStep === REJECTED || row.status1stStep === ONHOLD) ? "flex" : '',
                    justifyContent: auth.includes(US) && (row.status1stStep === REJECTED || row.status1stStep === ONHOLD) ? "center" : ''
                }}>
                    <IconButton color="primary" onClick={() => goToDetailPage(row.id)}><Search/></IconButton>
                    {auth.includes(US) && (row.status1stStep === REJECTED || row.status1stStep === ONHOLD) &&
                        <IconButton color="default" onClick={() => history.push({
                            pathname: '/modifica-ordine',
                            state: {orderId: row.id}
                        })}><Edit/></IconButton>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0, backgroundColor: '#eeeeee'}} colSpan={14}>
                    <Collapse timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Dettaglio
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Famiglia prodotto</TableCell>
                                        <TableCell>Categoria</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Price (€)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        productsOrdered &&
                                        productsOrdered.length > 0 &&
                                        productsOrdered.map(product => {
                                            return (
                                                <TableRow key={product.sku}>
                                                    <TableCell component="th" scope="row">
                                                        {product.sku}
                                                    </TableCell>
                                                    <TableCell>{product.family}</TableCell>
                                                    <TableCell>{product.category}</TableCell>
                                                    <TableCell>{product.brand}</TableCell>
                                                    <TableCell align="right">{product.quantity}</TableCell>
                                                    <TableCell align="right">
                                                        {Math.round(product.quantity * Number(product.price) * 100) / 100}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default ListaOrdiniRow;
