import React from 'react';
import Spinner from "../Spinner/Spinner";

function LoadingSpinner() {
    return (
        <div className="loading-container">
            <Spinner/>
            <p className="text-center w-100">Attendere...</p>
        </div>
    );
}

export default LoadingSpinner;
